import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import uploadIcon from "../../../Assests/images/setting-super-hfjadmin-icons.svg";
import Cookies from "js-cookie";
import axios from "axios";
import { Button, MenuItem, Select } from "@mui/material";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import itLocale from "i18n-iso-countries/langs/it.json";
import { useSnackbar } from "notistack";
import { CloseOutlined } from "@ant-design/icons";
import { ImageSize } from "../../ImageSize/index.tsx";

const AddCountry = (props) => {
  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState("No selected file");
  const { enqueueSnackbar } = useSnackbar();
  const [branchName, setBranchName] = useState("");
  const [train, settrian] = useState({
    name: "",
    code: "",
    branchName: "",
  });

  function handlelocation(e) {
    const newData = { ...train };
    newData["name"] = e.target.value;
    settrian(newData);
  }

  const [SelectedFile, setSelectedFile] = useState();

  function submit(e) {
    e.preventDefault();

    const formData = new FormData();

    formData.append("name", train["name"]);
    formData.append("branch_name", branchName);
    formData.append("image", SelectedFile);
    formData.append("user_id", `${JSON.parse(Cookies.get("_auth_state")).id}`);
    formData.append("token", Cookies.get()._auth);

    axios
      .post("https://dipndipapi.mass-fluence.com/api/add-country", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        props.refetch();
        enqueueSnackbar("Updated Successfully", { variant: "success" });
        props.onHide();
      })
      .catch(() => {
        enqueueSnackbar("Something went wrong, please try again", { variant: "error" });
      });
  }

  const [selectedCountry, setSelectedCountry] = useState("");

  const selectCountryHandler = (value) => setSelectedCountry(value);

  countries.registerLocale(enLocale);
  countries.registerLocale(itLocale);

  const countryObj = countries.getNames("en", { select: "official" });

  const countryArr = Object.entries(countryObj).map(([key, value]) => {
    return {
      label: value,
      value: key,
    };
  });

  return (
    <div>
      <Modal {...props} show={props.show} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header variant="danger">
          <Modal.Title style={{ color: "var(--color-text)", width: "100%" }} id="contained-modal-title-vcenter">
            <div className="flex items-center justify-between w-full">
              <p>Add country</p>
              <Button onClick={() => props.onHide()} color="error">
                <CloseOutlined style={{ fontSize: "20px" }} />
              </Button>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="" className="flex flex-col gap-4" onSubmit={(e) => submit(e)}>
            <label className="font-bold" style={{ color: "var(--color-text)" }}>
              Country name
            </label>
            <Select
              style={{
                width: "70%",
                borderRadius: "10px",
                borderBottom: "1px solid #C1C7CD",
                background: "var(--color-background)",
                color: "var(--color-text)",
                border: "none",
                height: "48px",
                padding: " 10px",
                marginTop: " 6px",
              }}
              value={selectedCountry}
              onChange={(e) => {
                selectCountryHandler(e.target.value);
                handlelocation(e);
              }}
              placeholder="Add Country"
            >
              {!!countryArr?.length &&
                countryArr.map(({ label, value }) => (
                  <MenuItem key={value} value={label} style={{ color: "var(--color-text)" }}>
                    {label}
                  </MenuItem>
                ))}
            </Select>
            <label className="font-bold" style={{ color: "var(--color-text)" }}>
              Branch name
            </label>

            <input
              id="Emailinput352"
              className="mt-1"
              type="text"
              onChange={(e) => setBranchName(e.target.value)}
              placeholder="Branch Name"
            />
            <label className="font-bold" style={{ color: "var(--color-text)", marginBottom: "10px" }}>
              Cover Image
            </label>
            <div onClick={() => document.querySelector(".ann-image-field").click()} id="image-ann-uploader">
              <input
                type="file"
                accept="image/*"
                className="ann-image-field"
                hidden
                onChange={({ target: { files, value } }) => {
                  files[0] && setFileName(files[0].name);
                  if (files) {
                    setImage(URL.createObjectURL(files[0]));
                  }
                  setSelectedFile(files[0]);
                }}
                value={train.image}
                id="image"
              />

              {image ? (
                <div className="flex flex-col items-center gap-4">
                  <img src={image} alt={fileName} width={160} height={160} />
                  <p className="text-redish text-xs">
                    *Please note that, the recommended image size is width: 157px, height: 250px
                  </p>
                </div>
              ) : (
                <ImageSize src={uploadIcon} alt={fileName} sizes="width: 157px, height: 250px" />
              )}
            </div>
            <button id="saveBtn353" variant="danger" type="submit">
              Save Changes
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddCountry;
