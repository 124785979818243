import React from "react";
import Setting from "../../../components/superPages/Setting/Setting.tsx";

const MainSetting = () => {
  return (
    <div id="mainContainerForSetting3434g">

      <Setting />
    </div>
  );
};

export default MainSetting;
