import React from "react";
import SubMessege from "../../../components/SubPages/Messege/messege";

const SubMainMessanger = () => {
  return (
    <div style={{ overflow: "hidden", height: "100vh" }}>
      <div id="messefewfew9845245" style={{ display: "flex" }}>

        <SubMessege />
      </div>
    </div>
  );
};

export default SubMainMessanger;
