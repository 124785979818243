import { TextField, Tooltip } from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import * as yup from "yup";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import itLocale from "i18n-iso-countries/langs/it.json";
import { useUserContext } from "../../../../context/userContext.tsx";
import { LoadingIndicator } from "../../../shared/LoadingIndicator/index.tsx";

const validationSchema = yup.object({
    country: yup.string().required("Please select a country"),
    cafe: yup.string().required("Cafe name is required"),
    lat: yup.string().required("Latitude is required"),
    lang: yup.string().required("Longitude is required"),
    address: yup.string().required("Address is required"),
});

export const AddBranch = ({ mapCountries }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { userId, userToken } = useUserContext();
    countries.registerLocale(enLocale);
    countries.registerLocale(itLocale);
    const formik = useFormik({
        initialValues: {
            country: "",
            cafe: "",
            lat: "",
            lang: "",
            address: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            axios
                .post(`https://dipndipapi.mass-fluence.com/api/add-branch?token=${userToken}&user_id=${userId}`, {
                    country_id: `${values.country}`,
                    cafe_name: `${values.cafe}`,
                    address: `${values.address}`,
                    latitude: `${values.lat}`,
                    longitude: `${values.lang}`,
                })
                .then((res) => {
                    if (res.status === 201) {
                        enqueueSnackbar(`${values.cafe} has been added successfully`, { variant: "success" });
                        formik.resetForm();
                    }
                })
                .catch(() => {
                    enqueueSnackbar("Something went wrong, please try again", { variant: "error" });
                });
        },
    });

    return (
        <form
            style={{
                color: "var(--color-text)",
            }} onSubmit={formik.handleSubmit}>
            <p className="text-2xl font-semibold my-4 text-redish">Add branch</p>
            <div id="maps-form" className="grid grid-cols-8 gap-8 my-4">
                <div className="flex flex-col col-span-8 gap-2 w-full">
                    <label className="font-bold text-xl" style={{ color: "var(--color-text)" }}>
                        Select country
                    </label>
                    <select

                        name="country"
                        className="p-4 rounded-xl w-full"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.country}
                        placeholder="Add Country"
                    >
                        <option disabled />

                        {mapCountries?.map((country: any) => {
                            return (
                                <option key={country.id} value={country.id} className="text-black">
                                    {country.city}
                                </option>
                            );
                        })}
                    </select>
                    <p className="text-sm text-redish">{formik.touched.country && formik.errors.country}</p>
                </div>
                <div className="flex flex-col gap-2 col-span-4">
                    <label className="font-bold" htmlFor="cafe">
                        Cafe Name
                    </label>
                    <TextField
                        sx={{ borderRadius: 10 }}
                        type="text"
                        name="cafe"
                        id="cafe"
                        value={formik.values.cafe}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.cafe && Boolean(formik.errors.cafe)}
                        helperText={formik.touched.cafe && formik.errors.cafe}
                    />
                </div>
                <div className="flex flex-col gap-2 col-span-4">
                    <label className="font-bold" htmlFor="address">
                        Address
                    </label>
                    <TextField
                        required
                        name="address"
                        id="address"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.address && Boolean(formik.errors.address)}
                        helperText={formik.touched.address && formik.errors.address}
                    />
                </div>
                <div className="flex flex-col gap-2 col-span-4">
                    <label className="font-bold" htmlFor="lat">
                        Latitude
                    </label>
                    <TextField
                        required
                        type="number"
                        id="lat"
                        name="lat"
                        value={formik.values.lat}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.lat && Boolean(formik.errors.lat)}
                        helperText={formik.touched.lat && formik.errors.lat}
                    />
                </div>
                <div className="flex flex-col gap-2 col-span-4">
                    <label className="font-bold" htmlFor="lang">
                        longitude
                    </label>
                    <TextField
                        required
                        name="lang"
                        type="number"
                        id="lang"
                        value={formik.values.lang}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.lang && Boolean(formik.errors.lang)}
                        helperText={formik.touched.lang && formik.errors.lang}
                    />
                </div>
            </div>
            <div className="flex items-center gap-4 mt-8">
                <Tooltip title="Please add a country first then add a branch">
                    <span>
                        <button type="submit" id="saveBtn353">
                            Save
                        </button>
                    </span>
                </Tooltip>
            </div>
        </form>
    );
};
