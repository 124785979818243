import { IconButton, TextField } from "@mui/material";
import axios from "axios";

import { useFormik } from "formik";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import itLocale from "i18n-iso-countries/langs/it.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useUserContext } from "../../../../context/userContext.tsx";
import { GetData, PostData } from "../../../../api/index.tsx";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { LoadingIndicator } from "../../../shared/LoadingIndicator/index.tsx";

const validationSchema = yup.object({
    name: yup.string().required(),
    lat: yup.string().required(),
    lang: yup.string().required(),
});

export const AddCountry = ({ countryArr, mapCountries }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { userId, userToken } = useUserContext();
    const queryClient = useQueryClient();
    const formik = useFormik({
        initialValues: {
            name: "",
            lat: "",
            lang: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            axios
                .post(`https://dipndipapi.mass-fluence.com/api/add-country`, {
                    name: `${values.name}`,
                    latitude: `${values.lat}`,
                    longitude: `${values.lang}`,
                    branch_name: "test",
                    token: `${userToken}`,
                    user_id: `${userId}`,
                })
                .then((res) => {
                    if (res.status === 201) {
                        enqueueSnackbar(`${values.name} has been added successfully`, { variant: "success" });
                        queryClient.invalidateQueries({ queryKey: ["homepage"] });
                        formik.resetForm();
                    }
                })
                .catch((err) => {
                    enqueueSnackbar(err.response.data.msg, { variant: "error" });
                });
        },
    });

    const isCountryExist = useMemo(() => {
        const countryName = formik.values.name ?? "";
        if (countryName && mapCountries) {
            return mapCountries.some((c) => c.city === countryName);
        }
        return false;
    }, [formik.values.name, mapCountries]);

    const CountryId = useMemo(() => {
        const countryName = formik.values.name ?? "";
        if (countryName && mapCountries) {
            const matchingCountry = mapCountries.find((c) => c.city === countryName);
            return matchingCountry ? matchingCountry.id : null;
        }
        return null;
    }, [formik.values.name, mapCountries]);

    const handleDeleteCountry = async () => {
        await axios
            .post(`	https://dipndipapi.mass-fluence.com/api/delete-country?token=${userToken}&user_id=${userId}`, {
                country_id: CountryId,
            })
            .then((res) => {
                if (res.status === 201) {
                    enqueueSnackbar(`${formik.values.name} deleted Successfully`, { variant: "success" });
                    queryClient.invalidateQueries({ queryKey: ["homepage"] });
                }
            })
            .catch(() => {
                enqueueSnackbar("Something went wrong, please try again", { variant: "error" });
            });
    };

    return (
        <form
            style={{
                color: "var(--color-text)",
            }}
            onSubmit={formik.handleSubmit}
        >
            <div className="flex gap-2 items-end w-full">
                <div className="flex flex-col gap-2 w-full">
                    <label className="font-bold text-2xl  !text-redish my-4" style={{ color: "var(--color-text)" }}>
                        Add country
                    </label>
                    <select
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="name"
                        className="p-4 rounded-xl w-full"
                        placeholder="Add Country"
                    >
                        <option disabled />

                        {!!countryArr?.length &&
                            countryArr.map(({ label, value }) => (
                                <option key={value} value={label} className="text-black">
                                    {label}
                                </option>
                            ))}
                    </select>
                </div>

                {isCountryExist ? (
                    <IconButton
                        color="error"
                        onClick={() => handleDeleteCountry()}
                        className="w-16 h-[70px]  !bg-white/70 !rounded-lg border"
                    >
                        <FontAwesomeIcon className="border p-2 rounded-xl" icon={faTrash} />
                    </IconButton>
                ) : (
                    <IconButton
                        onClick={() => formik.handleSubmit}
                        type="submit"
                        className="w-16 h-[70px]  !bg-white/70 !rounded-lg border"
                    >
                        <FontAwesomeIcon className="border p-2 rounded-xl" icon={faAdd} />
                    </IconButton>
                )}
            </div>
            <p className="text-redish">{formik.touched.name && formik.errors.name}</p>
            <div className="flex flex-col gap-2 col-span-4 my-4">
                <label className="font-bold" htmlFor="lat">
                    Latitude
                </label>
                <TextField
                    required
                    type="number"
                    id="lat"
                    name="lat"
                    value={formik.values.lat}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.lat && Boolean(formik.errors.lat)}
                    helperText={formik.touched.lat && formik.errors.lat}
                />
            </div>
            <div className="flex flex-col gap-2 col-span-4">
                <label className="font-bold" htmlFor="lang">
                    longitude
                </label>
                <TextField
                    required
                    name="lang"
                    type="number"
                    id="lang"
                    value={formik.values.lang}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.lang && Boolean(formik.errors.lang)}
                    helperText={formik.touched.lang && formik.errors.lang}
                />
            </div>
        </form>
    );
};
