import React, { useState } from "react";
import { LibraryCard } from "./Sections/LibraryCard/index.tsx";
import { useUserContext } from "../../../context/userContext.tsx";
import { useQuery } from "@tanstack/react-query";
import { GetData } from "../../../api/index.tsx";
import { LoadingIndicator } from "../../shared/LoadingIndicator/index.tsx";
import AddFolder from "./AddFolder.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
const Library = () => {
  const { userId, userToken, userRole } = useUserContext();
  const [isAddFolderOpen, setIsAddFolderOpen] = useState<boolean>(false);
  const { data: libraries, isLoading } = useQuery({
    queryKey: ["library"],
    queryFn: () => GetData("library", userToken as string, userId as string),
  });
  const { data: titles } = useQuery({
    queryKey: ["titles"],
    queryFn: () => GetData("titles", userToken as string, userId as string),
  });

  return (
    <div className="mb-8 mx-32">
      <div className="initial_title_Sec my-8">
        <div className="d-flex align-items-center">
          <h1 style={{ color: "var(--color-text)" }}>Library</h1>
        </div>

        <div className="d-flex gap-3">
          {userRole === "SuperAdmin" ? (
            <button id="add_file_initial" onClick={() => setIsAddFolderOpen(true)}>
              <FontAwesomeIcon icon={faAdd} className="w-4 h-4 font-bold" />
            </button>
          ) : null}
        </div>
      </div>
      <AddFolder show={isAddFolderOpen} onHide={() => setIsAddFolderOpen(false)} />
      <div className="flex gap-6 flex-wrap justify-center">
        {isLoading ? (
          <div className="w-screen h-screen">
            <LoadingIndicator />
          </div>
        ) : (
          titles?.data?.map((section, i) => {
            const width = i % 3 === 0 ? "w-[480px] 2xl:w-[550px]" : "w-[270px] 2xl:w-[380px]";
            return (
              <div key={section.id} className={width}>
                <LibraryCard sectionId={section.id} title={section.title} image={section.image} />
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default Library;
