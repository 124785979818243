import React, { useEffect, useState } from "react";
import RightDash from "../../../utils/Navbar/RightDash/rightDash";
import Topnav from "../../../utils/Navbar/topnav/topnav";
import { Button, Col, Row } from "react-bootstrap";
import "./Announcemnts.css";
import axios from "axios";

import img from "../../../Assests/images/icfgdsgsgg34on.svg";
import { Link, Route } from "react-router-dom";
import { Fade } from "react-reveal";
import CreatAnn from "./creatAnn";
import ShareAnn from "../../../pages/ShareAnnouncement/share";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import EditAnnouncements from "./EditAnnouncments";

const Announcemnts = () => {
  const [announcements, setAnnouncements] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [announcementInputs, setAnnouncementInputs] = useState({
    img: "",
    date: "",
    title: "",
    text: "",
    index: "",
    id: "",
  });
  const [staticItems, setStaticItems] = useState([]);
  const fetchAnnouncements = async () => {
    const res = await axios.get(
      `https://dipndipapi.mass-fluence.com/api/announcemnts?token=${Cookies.get()._auth}&user_id=${JSON.parse(Cookies.get()._auth_state).id
      }`
    );
    setAnnouncements(res.data.data);
    setStaticItems(res.data.data?.[0]);
  };

  const deleteAnnouncement = async (id) => {
    await axios
      .post(
        `https://dipndipapi.mass-fluence.com/api/delete-announcemnt?token=${Cookies.get()._auth}&user_id=${JSON.parse(Cookies.get()._auth_state).id
        }`,
        {
          announcemnt_id: id,
        }
      )
      .then(() => {
        enqueueSnackbar("Announcement deleted Successfully", { variant: "success" }), fetchAnnouncements();
      })
      .catch(() => enqueueSnackbar("Something went wrong, please try again", { variant: "error" }));
  };
  useEffect(() => {
    fetchAnnouncements();
  }, []);

  const { date, id, img, index, text, title } = announcementInputs;

  const [modalShow, setModalShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    setAnnouncementInputs({
      img: staticItems?.image,
      id: staticItems?.id,
      index: 0,
      text: staticItems?.text,
      title: staticItems?.title,
      date: staticItems?.date,
    });
  }, [staticItems]);
  return (
    <Col id="Announcemnts423453" className="d-flex justify-content-between align-items-start mb-5">
      <div style={{ width: "85px" }}></div>

      <div id="containerForAnnonu3434">
        <div id="announcemnts_title">
          <h1>Announcements</h1>
          <div>
            <p>Check whats happening in dipndip</p>
          </div>
        </div>

        <Row className="mt-2 container d-flex justify-content-between ">
          <Col id="first_half_Announcemnts" className="col-6">
            <Fade left id="cards_info434">
              <div>
                {announcements?.length >= 1 ? (
                  announcements.map((item, index) => {
                    return (
                      <div
                        onClick={() =>
                          setAnnouncementInputs({
                            img: item.image,
                            id: item.id,
                            index: index,
                            text: item.text,
                            title: item.title,
                            date: item.date,
                          })
                        }
                        className={` ${item.id === announcementInputs.id ? "bg-gray-500/20 my-2 rounded-lg" : "my-2"}`}
                        id="card_items3234"
                        key={item.id}
                      >
                        <div>
                          <img alt="ERR404" src={item.image} style={{ height: "150px", borderRadius: "20px" }} />
                        </div>

                        <div>
                          <p>{item.date}</p>
                          <h4>{item.title}</h4>
                          <h4>{item.description}</h4>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <h1 className="d-flex justify-content-center mt-5 text-redish">No Items</h1>
                )}
              </div>
            </Fade>
          </Col>

          <Col id="second_half323_Announcemnts" className="col-6">
            <Fade right id="dsaf323434f">
              <>
                <div id="card_detailes211_title">
                  <div>
                    <h1>{date}</h1>
                  </div>
                  <div>
                    <button onClick={() => setModalShow(true)}>Create</button>

                    <CreatAnn
                      show={modalShow}
                      refetch={() => fetchAnnouncements()}
                      onHide={() => setModalShow(false)}
                    />
                    <EditAnnouncements
                      show={isEdit}
                      title={announcementInputs.title}
                      id={announcementInputs.id}
                      image={announcementInputs.img}
                      description={announcementInputs.text}
                      refetch={() => fetchAnnouncements()}
                      onHide={() => setIsEdit(false)}
                    />
                  </div>
                </div>
                {announcements?.length ? (
                  <div id="item_detailes23232">
                    <img alt="ERR404" src={img} style={{ height: "358px", width: "658px", borderRadius: "20px" }} />
                    <h1>{title}</h1>
                    <p>{text}</p>
                    <div className="flex gap-8">
                      <button className="z-50" id="profileBtn434" onClick={() => deleteAnnouncement(id)}>
                        Delete Announcement
                      </button>
                      <button className="z-50" id="profileBtn434" onClick={() => setIsEdit(true)}>
                        Edit Announcement
                      </button>
                    </div>
                  </div>
                ) : null}
              </>
            </Fade>
          </Col>
        </Row>
      </div>

      <div style={{ width: "85px" }}></div>
      <div style={{ position: "fixed", right: "0" }}></div>
    </Col>
  );
};

export default Announcemnts;
