import React, { useEffect, useState } from "react";
import "./share.css";
import Topnav from "../../utils/Navbar/topnav/topnav";
import { Button, Col } from "react-bootstrap";
import RightDash from "../../utils/Navbar/RightDash/rightDash";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";

const ShareAnn22 = () => {
  const { name } = useParams();

  const [Static, SetStatic] = useState([]);

  const fetchAxios = async () => {
    const res = await axios.get(
      `https://dipndipapi.mass-fluence.com/api/announcemnts?token=${Cookies.get()._auth
      }&user_id=${JSON.parse(Cookies.get()._auth_state).id}`,
    );
    SetStatic(res.data.data[name]);
  };

  useEffect(() => {
    fetchAxios();
  }, []);

  useEffect(() => {
    SetImg(Static.image);
    SetData(Static.date);
    SetTilte(Static.title);
    SetText(Static.text);
  }, [Static]);

  const [Img, SetImg] = useState();
  const [Data, SetData] = useState();
  const [Title, SetTilte] = useState();
  const [Text, SetText] = useState();

  return (
    <>
      <Col
        id="Announcemnts423453"
        className="d-flex justify-content-between align-items-start mb-5"
      >
        <div style={{ width: "85px" }}></div>

        <div id="containerForAnnonu3434">


          <br></br>
          <div
            id="announcemnts_title"
            className="d-flex justify-content-between"
          >
            <div style={{ display: "block" }}>
              <h1>Announcemnts</h1>
              <p>Check whats happening in dipndip</p>
            </div>

            <div>
              <p className="me-4">{Data}</p>
            </div>
          </div>
          <br></br>
          <br></br>

          <div className="container" id="dsf352g">
            <img alt="ERR404" id="annonImage2322" src={Img} />

            <br></br>
            <h1 style={{ color: "var(--color-text)" }}>{Title}</h1>
            <p style={{ color: "var(--color-text)" }}>{Text}</p>
          </div>
        </div>

        <div style={{ width: "85px" }}></div>
        <div style={{ position: "fixed", right: "0" }}>

        </div>
      </Col>
    </>
  );
};

export default ShareAnn22;
