/* eslint-disable no-constant-condition */
import "./login.css";
import logoDipndip from "../../Assests/images/dipndip-logdo-r-onblack-rgb-1-fsdgfsdgsd-32-r.png";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import img from "../../Assests/images/ufufjf56.png";
import { useSignIn } from "react-auth-kit";
import { useFormik } from "formik";
import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function Login() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const signIn = useSignIn();
  const [showPassword, setShowPassword] = useState(false);
  const onSubmit = async (values) => {
    setError("");
    try {
      const response = await axios.post("https://dipndipapi.mass-fluence.com/api/login", {
        ...values,
        last_login: moment().format("ddd D MMM h:mm A"),
      });
      signIn({
        token: response.data.authorization.token,
        expiresIn: 3222222600,
        tokenType: "Bearer",
        authState: {
          email: values.email,
          password: values.password,
          route: response.data.user.occupation.replace(/\s/g, ""),
          id: response.data.user.id,
          name: response.data.user.name,
          occupation: response.data.user.occupation,
          image: response.data.user.image,
          locatin: response.data.user.location,
        },
      });
      if ((window.location.pathname = "/")) {
        setInterval(() => {
          navigate(`/${response.data.user.occupation.replace(/\s/g, "")}/${response.data.user.id}`);
        }, 1000);
      }
    } catch (err) {
      window.alert("Enter correct data");
    }
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit,
  });
  useEffect(() => {
    if (Cookies.get("_auth_state")) {
      navigate(`/${JSON.parse(Cookies.get()._auth_state).route}/${JSON.parse(Cookies.get()._auth_state).id}`);
    } else {
      navigate("/");
    }
  }, [Cookies.get("_auth_state")]);

  const [done, setDone] = useState(undefined);
  useEffect(() => {
    setTimeout(() => {
      setDone(true);
    }, 2000);
  }, []);

  return (
    <div>
      {!done ? (
        <div id="preloadPage2342">
          <img src={img} alt="ERR404" />
        </div>
      ) : (
        <div id="login_page">
          <img alt="err404" id="mainLogoLogin" src={logoDipndip} />
          <div className="secondHalp">
            <p>Login</p>
            <h6>Welcome to dipndip e-library</h6>
            <br></br>
            <br></br>

            <form onSubmit={formik.handleSubmit} id="form_for_login">
              <h1 style={{ color: "red" }}>{error}</h1>
              <label htmlFor="email2323">E-mail or phone number</label>
              <br />
              <input
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                type="email"
                placeholder="Enter your email"
              />{" "}
              <br />
              <br />
              <label htmlFor="password424">Password</label>
              <br />
              <div className="relative">
                <input
                  id="name"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  className="w-full p-[15px] rounded-md"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your password"
                />
                <div className="absolute right-4 top-4">
                  <FontAwesomeIcon
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    className="w-6 h-6 text-gray-500 cursor-pointer"
                    icon={showPassword ? faEyeSlash : faEye}
                  />
                </div>
              </div>
              <br />
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex ">
                  <label htmlFor="dwerw524" className="d-flex">
                    Remember me
                  </label>
                  <input id="dwerw524" className="ms-1" type="checkbox" />
                </div>
                <div>
                  <Link to="/ResetPass" style={{ textDecoration: "none" }} id="forget_pass">
                    Forgot Password?
                  </Link>
                </div>
              </div>
              <br />
              <br />
              <button id="fet4356546" type="submit">
                Login
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
