import logoDipndip from "../../Assests/images/dipndip-logdo-r-onblack-rgb-1-fsdgfsdgsd-32-r.png";

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";
import Cookies from "js-cookie";

const Reset = () => {
  const navigate = useNavigate();

  const any3332 = useRef();
  const [email, setemail] = useState();

  let addtrainURL = "https://dipndipapi.mass-fluence.com/api/send-email";

  function handle(e) {
    setemail(e.target.value);
  }

  function submit(e) {
    e.preventDefault();

    const formData = new FormData();

    formData.append("email", email);

    axios
      .post(addtrainURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        window.alert(res.data.msg);
        navigate(`/SecurityCode/${email}`);
      })
      .catch((ERR) => {
        window.alert("Email not found");
      });
  }

  //   app.post('/submitMessage', (request, response) => {
  //     message = request.body.message;
  //     console.log("Message: " + message);

  //     response.status(200).json({
  //         status: 200,
  //         ok: true,
  //         data: {
  //             msg: message
  //             // Any data for the response
  //         }
  //     })
  // })

  return (
    <div id="login_page">
      <img alt="err404" id="mainLogoLogin" src={logoDipndip} />
      <div className="secondHalp">
        <p>Reset Password</p>
        <h6>
          Do not be angry, my friend, we will help you to recover the password
        </h6>
        <br></br>
        <br></br>
        <form id="form_for_login">
          <label htmlFor="email2323">E-mail</label>
          <br />
          <input
            onChange={(e) => handle(e)}
            ref={any3332}
            type="email"
            id="email2323"
            placeholder="Enter your email"
          />{" "}
          <br />
          <br />
          <br />
          <br />
          <button
            onClick={(e) => submit(e)}
            // to="/SecurityCode"
            id="fet4356546"
          >
            Next
          </button>
        </form>
      </div>
    </div>
  );
};

export default Reset;
