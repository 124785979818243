import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import { IconButton, TextField, Typography } from "@mui/material";
import axios from "axios";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { useUserContext } from "../../../../context/userContext.tsx";
import { ClearOutlined, CloseCircleFilled, CloseOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";

const validationSchema = yup.object({
    cafe: yup.string().required(),
    lat: yup.string().required(),
    lang: yup.string().required(),
    address: yup.string().required(),
});

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 650,
    height: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
};

export const EditModal = ({ isOpen, branchInfo, handleClose }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { userId, userToken } = useUserContext();
    const queryClient = useQueryClient();

    const formik = useFormik({
        initialValues: {
            cafe: (branchInfo?.city as string) || "",
            lat: (branchInfo?.latitude as string) || "",
            lang: (branchInfo?.lang as string) || "",
            address: (branchInfo?.longitude as string) || "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            axios
                .post(`https://dipndipapi.mass-fluence.com/api/update-branch?token=${userToken}&user_id=${userId}`, {
                    cafe_name: `${values.cafe}`,
                    address: `${values.address}`,
                    latitude: `${values.lat}`,
                    longitude: `${values.lang}`,
                    branch_id: `${branchInfo.id}`,
                })
                .then((res) => {
                    if (res.status === 201) {
                        enqueueSnackbar(`${values.cafe} has been updated successfully`, { variant: "success" });
                        queryClient.invalidateQueries({ queryKey: ["branches"] });
                        formik.resetForm();
                        handleClose()
                    }
                })
                .catch(() => {
                    enqueueSnackbar("Something went wrong, please try again", { variant: "error" });
                });
        },
    });

    const updateFormValues = (newValues) => {
        formik.setValues(newValues);
    };

    useEffect(() => {
        if (branchInfo) {
            updateFormValues({
                cafe: branchInfo.city || "",
                lat: branchInfo.latitude || "",
                lang: branchInfo.longitude || "",
                address: branchInfo.address || "",
            });
        }
    }, [branchInfo]);
    return (
        <Modal sx={{ height: "750px" }} open={isOpen} onClose={handleClose}>
            <Box sx={style}>
                <div className="flex items-center w-full justify-between">
                    <Typography className="!text-2xl !font-semibold">Edit Branch</Typography>
                    <IconButton onClick={handleClose} color="error">
                        <CloseOutlined />
                    </IconButton>
                </div>{" "}
                <form onSubmit={formik.handleSubmit}>
                    <div id="maps-form" className="grid grid-cols-8 gap-8 my-4">
                        <div className="flex flex-col gap-2 col-span-4">
                            <label className="font-bold" htmlFor="cafe">
                                Cafe Name
                            </label>
                            <TextField
                                sx={{ borderRadius: 10 }}
                                type="text"
                                name="cafe"
                                id="cafe"
                                value={formik.values.cafe}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.cafe && Boolean(formik.errors.cafe)}
                                helperText={formik.touched.cafe && formik.errors.cafe}
                            />
                        </div>
                        <div className="flex flex-col gap-2 col-span-4">
                            <label className="font-bold" htmlFor="subjectvalue">
                                Address
                            </label>
                            <TextField
                                required
                                name="address"
                                id="address"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.address && Boolean(formik.errors.address)}
                                helperText={formik.touched.address && formik.errors.address}
                            />
                        </div>
                        <div className="flex flex-col gap-2 col-span-4">
                            <label className="font-bold" htmlFor="lat">
                                Latitude
                            </label>
                            <TextField
                                required
                                type="number"
                                id="lat"
                                name="lat"
                                value={formik.values.lat}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.lat && Boolean(formik.errors.lat)}
                                helperText={formik.touched.lat && formik.errors.lat}
                            />
                        </div>
                        <div className="flex flex-col gap-2 col-span-4">
                            <label className="font-bold" htmlFor="subjectvalue">
                                longitude
                            </label>
                            <TextField
                                required
                                name="lang"
                                type="number"
                                id="lang"
                                value={formik.values.lang}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.lang && Boolean(formik.errors.lang)}
                                helperText={formik.touched.lang && formik.errors.lang}
                            />
                        </div>
                    </div>
                    <div className="flex items-center gap-4 mt-8">
                        <button type="submit" id="saveBtn353">
                            Save
                        </button>
                    </div>
                </form>
            </Box>
        </Modal>
    );
};
