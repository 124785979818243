import React, { useEffect, useState } from "react";
import RightDash from "../../../utils/Navbar/RightDash/rightDash";
import { Card, Col, Dropdown, Row } from "react-bootstrap";
import Topnav from "../../../utils/Navbar/topnav/topnav";
import "./files.css";
import { Link, useParams } from "react-router-dom";
import threepoints from "../../../Assests/images/frame-170fsdf7478168.png";
import axios from "axios";
import Addtrain from "./AddFile.js";
import EditFile from "./editFile";
import Cookies from "js-cookie";
import fileDownload from "js-file-download";
import { ModalTitleWithClose } from "../../shared/ModalTitle.tsx";
import { useUserContext } from "../../../context/userContext.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";

const Files = () => {
  const [itemInfo, setItemInfo] = useState({});
  const [isDownloading, setIsDownloading] = useState(false);
  const [modalShow22, setModalShow22] = useState(false);
  const [modalShow33323, setModalShow33234f] = useState(false);
  const { name } = useParams();
  const { fileID } = useParams();
  const { userId, userToken } = useUserContext();
  const [ItemsDetails2343, setItemsDetails23] = useState([]);
  const fetchAxios = async () => {
    const res = await axios.get(
      `https://dipndipapi.mass-fluence.com/api/training-files?user_id=${JSON.parse(Cookies.get()._auth_state).id
      }&training_id=${fileID}`,
      {
        method: "HEAD",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
        credentials: "same-origin",
        crossdomain: true,
      }
    );
    setItemsDetails23(res.data.data);
  };

  useEffect(() => {
    fetchAxios();
  }, []);

  let addtrainURL = `https://dipndipapi.mass-fluence.com/api/delete-file?token=${userToken}&user_id=${JSON.parse(Cookies.get()._auth_state).id
    }`;

  function handelDelete(id) {
    axios
      .post(addtrainURL, {
        file_id: id,
      })
      .then((res) => {
        if (res.data.status === "success") {
          // setShow(true)
          this.resetForm();
        } else if (res.data.status === "fail") {
          alert("Error al enviar el mensaje");
        }
        setInterval(() => {
          window.location.reload();
        }, 400);
      })
      .catch((ERR) => {
        // console.log(ERR);
      });
  }

  return (
    <Col id="Library3424" className="d-flex justify-content-between align-items-start mb-5">
      <div style={{ width: "100px" }}></div>
      <div id="inialTrainingContainer34r">
        <div className="initial_title_Sec">
          <h1 className="text-2xl font-bold" style={{ color: "var(--color-text)" }}>
            {name}
          </h1>
          <div className="d-flex gap-3">
            <button id="add_file_initial" onClick={() => setModalShow22(true)}>
              <FontAwesomeIcon icon={faAdd} className="w-4 h-4 font-bold" />
            </button>{" "}
            <Addtrain show={modalShow22} onHide={() => setModalShow22(false)} training_id={fileID} />
          </div>
        </div>
        <Row id="cardContainer34r" className="m-auto mt-4 container">
          {ItemsDetails2343.length ? (
            ItemsDetails2343?.map((item, index) => {
              let text = item.file;
              let result = text.substr(42);
              return (
                <Col key={item.id} className="col-6">
                  <Card
                    className="mt-4 "
                    style={{
                      background: "var(--color-frame)",
                      borderRadius: "25px",
                      maxWidth: "350px",
                      padding: "5px 5px 10px",
                      height: "95%",
                    }}
                  >
                    <div onClick={() => window.location.href == `/Viewer/${result}/${item.file_type}/${item.name}`}>
                      <img
                        alt="ERR404"
                        style={{ borderRadius: "25px", height: "230px", width: "100%" }}
                        src={item.image}
                      />
                    </div>
                    <div id="threepoingdgdts43">
                      <Dropdown>
                        <Dropdown.Toggle>
                          <img alt="ERR404" src={threepoints} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              setModalShow33234f(true);
                              setItemInfo(item);
                            }}
                          >
                            Edit
                          </Dropdown.Item>
                          <EditFile
                            initialData={itemInfo}
                            idname={item?.id}
                            show={modalShow33323}
                            training_id={fileID}
                            onHide={() => setModalShow33234f(false)}
                          />

                          <Dropdown.Item
                            href={`https://dipndipapi.mass-fluence.com/api/download-file?user_id=${userId}&file_id=${item.id}`}
                            style={{ textDecoration: "none!important" }}
                          >
                            Download
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handelDelete(item.id)}>Delete</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div id="organizeCardDetail">
                      <div className="d-flex justify-content-between">
                        <h5 id="cardTit4343gdfgf4">{item?.name}</h5>
                      </div>
                      <a
                        className="w-fit"
                        href={`https://dipndipapi.mass-fluence.com/api/download-file?user_id=${userId}&file_id=${item.id}`}
                        id="card_btn_initial22"
                      >
                        Download
                      </a>
                    </div>
                  </Card>
                </Col>
              );
            })
          ) : (
            <p className="text-2xl text-redish font-semibold flex items-center justify-center w-full h-[80vh]">
              No files here to show
            </p>
          )}
        </Row>
      </div>
      <div style={{ width: "85px" }}></div>
      <div style={{ position: "fixed", right: "0" }}></div>
    </Col>
  );
};

export default Files;
