import React from "react";
import Analytics from "../../../components/superPages/Analytics/Analytics";

const MainAnalytics = () => {
  return (
    <div id="mainContainerForAnalytics">

      <Analytics />
    </div>
  );
};

export default MainAnalytics;
