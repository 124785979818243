import Box from "@mui/material/Box";
import React, { useEffect, useMemo, useState } from "react";
import { GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import axios from "axios";
import { faCheck, faEdit, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSnackbar } from "notistack";
import { useUserContext } from "../../../context/userContext.tsx";
import { Avatar, Button, IconButton, Input, InputAdornment, styled } from "@mui/material";
import { StyledDataGrid } from "./Customs/customs.ts";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

export const MembersTable = () => {
    const [usersData, setUsersData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const [userPassword, setUserPassword] = useState("");
    const [userName, setUserName] = useState("");
    const { enqueueSnackbar } = useSnackbar();
    const { userToken, userId } = useUserContext();
    const [avatarImages, setAvatarImages] = useState<{ [key: string]: string | null }>({});
    const [selectedFile, setSelectedFile] = useState<File>();
    const handleAvatarChange = (id: string, file: File) => {
        setAvatarImages((prevState) => ({
            ...prevState,
            [id]: URL.createObjectURL(file),
        }));
        setSelectedFile(file);
    };

    const fetchMembers = async () => {
        const res = await axios.get(`https://dipndipapi.mass-fluence.com/api/users?token=${userToken}&user_id=${userId}`);

        setUsersData(res.data.data);
    };
    const handleRowSelect = (id) => {
        if (rowSelectionModel.includes(id)) {
            setRowSelectionModel(rowSelectionModel.filter((selectedId) => selectedId !== id));
        } else {
            setRowSelectionModel([...rowSelectionModel, id]);
        }
    };
    const handleDelete = (id: string) => {
        axios
            .post("https://dipndipapi.mass-fluence.com/api/delete-user", {
                select_user_id: id,
                user_id: `${userId}`,
                token: userToken,
            })
            .then(() => {
                enqueueSnackbar("Updated Successfully", { variant: "success" });
                fetchMembers();
            })
            .catch(() => {
                enqueueSnackbar("Something went wrong, please try again", { variant: "error" });
            });
    };
    const handleSearch = (event) => {
        const searchQuery = event.target.value.toLowerCase();
        const filtered = usersData?.filter(
            (user: any) =>
                user.name.toLowerCase().includes(searchQuery) ||
                user.email.toLowerCase().includes(searchQuery) ||
                user.location.toLowerCase().includes(searchQuery) ||
                user.occupation.toLowerCase().includes(searchQuery)
        );
        if (!searchQuery) {
            setFilteredData(usersData);
        }
        setFilteredData(filtered ?? usersData);
    };

    const updateFilter = useMemo(() => {
        return setFilteredData(usersData);
    }, [usersData]);
    const handleUpdateStatus = (id: string, status) => {
        axios
            .post("https://dipndipapi.mass-fluence.com/api/update-user-status", {
                selected_user_id: id,
                status: status,
                name: userName,
                user_id: `${userId}`,
                token: userToken,
                password: userPassword,
            })
            .then(() => {
                enqueueSnackbar("Updated Successfully", { variant: "success" });
                fetchMembers();
            })
            .catch(() => {
                enqueueSnackbar("Something went wrong, please try again", { variant: "error" });
            });
    };

    const handleUpdatePassword = (id: string) => {
        axios
            .post("https://dipndipapi.mass-fluence.com/api/update-user", {
                selected_user_id: id,
                user_id: `${userId}`,
                token: userToken,
                password: userPassword,
            })
            .then(() => {
                fetchMembers();
            })
            .catch(() => {
                enqueueSnackbar("Something went wrong, please try again", { variant: "error" });
            });
    };

    const handleUpdateName = (id: string) => {
        axios
            .post("https://dipndipapi.mass-fluence.com/api/update-user", {
                selected_user_id: id,
                user_id: `${userId}`,
                token: userToken,
                name: userName,
            })
            .then(() => {
                fetchMembers();
            })
            .catch(() => {
                enqueueSnackbar("Something went wrong, please try again", { variant: "error" });
            });
    };
    const handlePasswordChange = (e: any) => {
        setUserPassword(e.target.value);
    };
    const handleNameChange = (e: any) => {
        setUserName(e.target.value);
    };

    const handleAvatarApi = (id, file, name) => {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("user_id", `${userId}`);
        formData.append("selected_user_id", `${id}`);
        formData.append("token", `${userToken}`);
        axios
            .post("https://dipndipapi.mass-fluence.com/api/update-user", formData)
            .then(() => {
                enqueueSnackbar(`Profile picture for ${name} has been updated successfully`, { variant: "success" });
                fetchMembers();
            })
            .catch(() => {
                enqueueSnackbar("Something went wrong, please try again", { variant: "error" });
            });
    };

    useEffect(() => {
        fetchMembers();
        if (!filteredData?.length) {
            setFilteredData(usersData);
        }
        if (userName) {
            setFilteredData(usersData);
        }
    }, [usersData?.length, userName]);
    const columns: GridColDef[] = [
        // { field: 'id', headerName: 'Sr. No', width: 90 },
        {
            field: "name",
            headerName: "Type",
            width: 300,
            disableColumnMenu: true,
            renderCell: (row: any) => {
                const isRowSelected = rowSelectionModel.includes(row.row.id);

                return (
                    <div className="flex flex-col  gap-2">
                        <div className="flex items-center gap-2 relative">
                            {isRowSelected ? (
                                <div className="absolute z-50 -top-2 left-0">
                                    {" "}
                                    <IconButton component="label">
                                        <FontAwesomeIcon
                                            icon={faEdit}
                                            style={{
                                                fontSize: "15px",
                                            }}
                                        />
                                        <VisuallyHiddenInput
                                            onChange={({ target: { files } }) => {
                                                if (files) {
                                                    handleAvatarChange(row.row.id, files[0]);
                                                    handleAvatarApi(row.row.id, files[0], row.row.name);
                                                }
                                            }}
                                            type="file"
                                        />
                                    </IconButton>{" "}
                                </div>
                            ) : null}

                            <Button
                                sx={{
                                    borderRadius: "100%",
                                }}
                                component="label"
                                variant="text"
                            >
                                <Avatar src={avatarImages[row.row.id] ?? row.row.image} />
                            </Button>
                            {isRowSelected ? (
                                <Input
                                    disabled={!isRowSelected}
                                    defaultValue={row.row.name}
                                    onChange={handleNameChange}
                                    type="text"
                                    className="p-2.5"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                disabled={!isRowSelected}
                                                onClick={() => {
                                                    handleUpdateName(row.row.id);
                                                    handleRowSelect(row.row.id);
                                                    enqueueSnackbar(`Name updated Successfully for ${row.row.name}`, { variant: "success" });
                                                }}
                                            >
                                                {isRowSelected ? <FontAwesomeIcon icon={faCheck} /> : null}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            ) : (
                                <div>
                                    <p
                                        style={{
                                            color: "var(--color-text)",
                                        }}
                                        className="font-[500] text-[#455468] text-base"
                                    >
                                        {row.row.name}
                                    </p>
                                    <p
                                        style={{
                                            color: "var(--color-text)",
                                        }}
                                        className="text-xs text-[#5E718D]"
                                    >
                                        {row.row.location}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                );
            },
        },
        {
            field: " ",
            headerName: "Password",
            width: 250,
            disableColumnMenu: true,
            renderCell: (row: any) => {
                const isRowSelected = rowSelectionModel.includes(row.row.id);

                return (
                    <div className="flex flex-col  gap-2">
                        <Input
                            disabled={!isRowSelected}
                            defaultValue="123456789123456789123456789"
                            id="standard-adornment-password"
                            onChange={handlePasswordChange}
                            type="password"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        disabled={!isRowSelected}
                                        aria-label="toggle password visibility"
                                        onClick={() => {
                                            handleUpdatePassword(row.row.id);
                                            handleRowSelect(row.row.id);
                                            enqueueSnackbar(`Password updated Successfully for ${row.row.name}`, { variant: "success" });
                                        }}
                                    >
                                        {isRowSelected ? <FontAwesomeIcon icon={faCheck} /> : null}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </div>
                );
            },
        },
        // {
        //     field: 'email',
        //     headerName: 'Email',
        //     width: 290,
        //     disableColumnMenu: true
        // },
        // {
        //     field: 'location',
        //     headerName: 'Location',
        //     width: 110,
        //     disableColumnMenu: true
        // },
        {
            field: "last_seen",
            headerName: "Status",
            width: 160,
            disableColumnMenu: true,
            renderCell: (row: any) => {
                const isActive = row.row.last_seen === 0;
                return (
                    <div
                        className={`${isActive ? "bg-[#1DB4691F]" : "bg-[#F0F3F9]"} px-2 py-1 rounded-md ${isActive ? "text-[#11A75C]" : "text-[#5E718D]"
                            } flex items-center gap-2`}
                    >
                        <div className={`w-2 h-2 ${isActive ? "bg-[#11A75C] " : "bg-[#5E718D]"} rounded-full`} />
                        <p className="text-sm">{!isActive ? "Offline" : "Active"}</p>
                    </div>
                );
            },
        },

        {
            field: "occupation",
            headerName: "Role",
            width: 160,

            disableColumnMenu: true,
            renderCell: (row: any) => {
                return (
                    <p
                        style={{
                            color: "var(--color-text)",
                        }}
                        className="font-[500] text-[#455468] text-sm"
                    >
                        {row.value}
                    </p>
                );
            },
        },
        {
            field: "_",
            headerName: "Control",
            width: 160,
            disableColumnMenu: true,
            renderCell: (row: any) => (
                <div className="d-flex gap-3 ">
                    {row.row.status === 0 ? (
                        <button type="button" id="Enabled" onClick={() => handleUpdateStatus(row.row.id, 1)}>
                            Enabled
                        </button>
                    ) : (
                        <button type="button" id="Disable" onClick={() => handleUpdateStatus(row.row.id, 0)}>
                            Disable
                        </button>
                    )}

                    <button type="button" className="!bg-[#FFF5F4]" id="Delete" onClick={() => handleDelete(row.row.id)}>
                        Delete
                    </button>
                </div>
            ),
        },
    ];

    return (
        <Box sx={{ height: 790, width: "100%" }}>
            <div className="flex items-center justify-between gap-2 my-4">
                <div className="flex items-center gap-4">
                    <h1
                        style={{
                            color: "var(--color-text) !important",
                        }}
                        id="Teammember"
                        className=" text-xl"
                    >
                        Team member
                    </h1>
                    <p className="bg-[#F0F3F9] text-[#5E718D] text-sm p-2 rounded-md">{usersData.length} Member</p>
                </div>

                <div id="search_container" className="relative">
                    <input
                        onChange={handleSearch}
                        className="p-2 rounded-lg pl-2 w-full"
                        type="text"
                        id="member-search"
                        placeholder="Search"
                    />
                </div>
            </div>
            <StyledDataGrid
                checkboxSelection
                localeText={{ noRowsLabel: "Oh, seems like no data here" }}
                disableRowSelectionOnClick
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                rowHeight={80}
                loading={!usersData}
                rows={filteredData ?? usersData}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 15,
                        },
                    },
                }}
                pageSizeOptions={[15]}
            />
        </Box>
    );
};
