import React from "react";
import Around from "../../../components/AdminPages/around/around";

const AdminMAinAround = () => {
  return (
    <div style={{ display: "flex" }} id="mainAnnounmentsContainer34">
      <Around />
    </div>
  );
};

export default AdminMAinAround;
