import React from "react";
import { Text, Image, View } from "@react-pdf/renderer";
import { avatar } from "../../avatar.ts";

export const UsersOnline = ({ onlineUsers, offlineUsers, styles }) => {
    return (
        <View style={styles.card}>
            <Text style={styles.header}>Users Online</Text>
            {onlineUsers?.map((user, i) => {
                return (
                    <View key={i} style={styles.justRow}>
                        <View style={styles.row}>
                            <Image style={styles.image} src={avatar} />
                            <View style={styles.item}>
                                <Text style={styles.child}> {user.name}</Text>
                                <Text style={styles.subtitle}> {user.occupation}</Text>
                            </View>
                        </View>
                        <View style={styles.rowEnd}>
                            <Text style={styles.onlineIcon} />
                            <Text style={styles.online}>{"Online"} </Text>
                        </View>
                    </View>
                );
            })}
            {offlineUsers?.map((user, i) => {
                return (
                    <View key={i} style={styles.justRow}>
                        <View style={styles.row}>
                            <Image style={styles.image} src={avatar} />
                            <View style={styles.item}>
                                <Text style={styles.child}> {user.name}</Text>
                                <Text style={styles.subtitle}> {user.occupation}</Text>
                            </View>
                        </View>
                        <View style={styles.rowEnd}>
                            <Text style={styles.offlineIcon} />
                            <Text style={styles.offline}>{"Offline"} </Text>
                        </View>
                    </View>
                );
            })}
        </View>
    );
};
