import React, { useEffect, useState } from "react";
import "./nontification.css";
import RightDash from "../../../utils/Navbar/RightDash/rightDash";
import { Col, Row } from "react-bootstrap";
import Topnav from "../../../utils/Navbar/topnav/topnav";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

const Notification = () => {
  const [ItemsDetails2343, setItemsDetails23] = useState([]);

  const fetchAxios = async () => {
    const res = await axios.get(
      `https://dipndipapi.mass-fluence.com/api/notifications?token=${Cookies.get()._auth
      }&user_id=${JSON.parse(Cookies.get()._auth_state).id}`,
    );

    setItemsDetails23(res.data.data);
  };

  useEffect(() => {
    fetchAxios();
  }, []);

  return (
    <Col
      id="manuals423453"
      className="d-flex justify-content-between align-items-start mb-5"
    >
      <div style={{ width: "85px" }}></div>

      <div id="ContainerForprofile2323">


        <div id="top_titNoti">
          <div>
            <h1 id="ProfileTit354">Notifications</h1>
            <p id="noti_text323">
              You have {ItemsDetails2343.length} notifications to go through
            </p>
          </div>

          {/* <div>
    <button>
    Mark all as read
    </button>
    </div> */}
        </div>

        <br></br>
        <br></br>

        <div className="container">
          {ItemsDetails2343.length >= 1 ? (
            ItemsDetails2343.map((item) => {
              return (
                <div id="notification_item32">
                  <div>
                    <h6>{item.title}</h6>
                    <p>{item.notification}</p>
                  </div>

                </div>
              );
            })
          ) : (
            <h1 className="d-flex justify-content-center mt-5 text-redish">
              No Notifications
            </h1>
          )}
        </div>
      </div>

      <div style={{ width: "85px" }}></div>
      <div style={{ position: "fixed", right: "0" }}>

      </div>
    </Col>
  );
};

export default Notification;
