import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Backdrop, Fade, IconButton, Stack } from "@mui/material";
import uploadIcon from "../../../Assests/images/setting-super-hfjadmin-icons.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import axios from "axios";
import { useUserContext } from "../../../context/userContext.tsx";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: "560px !important",
    bgcolor: "background.paper",
    borderRadius: "20px",
    p: 4,
};

export default function SlidersUpdateModal({ open, onClose, item }) {
    const [image, setImage] = useState(item.image);
    const [fileName, setFileName] = useState("No selected file");
    const { userId, userToken } = useUserContext();
    const [isUpdating, setIsUpdating] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const [train, settrian] = useState({
        name: item ? item.text : "",
        image: "",
    });
    function handle(e) {
        const newData = { ...train };
        newData[e.target.id] = e.target.value;
        settrian(newData);
    }
    const [SelectedFile, setSelectedFile] = useState(item ? item.image : null);

    function submit(e) {
        e.preventDefault();
        setIsUpdating(true);

        const formData = new FormData();
        formData.append("image", SelectedFile);
        formData.append("text", train["name"]);
        formData.append("token", `${userToken}`);
        formData.append("section_id", `${item.id}`);
        formData.append("user_id", `${userId}`);
        axios
            .post("https://dipndipapi.mass-fluence.com/api/update-homepage", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((res) => {
                if (res.status === 201) {
                    enqueueSnackbar("Updated Successfully", { variant: "success" });
                }
                onClose();
                setIsUpdating(false);
            })
            .catch(() => {
                enqueueSnackbar("Something went wrong please try again", { variant: "error" });
                setIsUpdating(false);
            });
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={onClose}
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="h5">Edit Slide</Typography>
                            <IconButton onClick={() => onClose()}>
                                <FontAwesomeIcon icon={faClose} />
                            </IconButton>
                        </Stack>
                        <form id="" onSubmit={(e) => submit(e)}>
                            <Stack gap={4} direction="column" alignItems="start" className="w-full">
                                <div className="w-full mt-4">
                                    <label style={{ color: "var(--color-text)" }}>Name</label>
                                    <input
                                        className="w-full"
                                        onChange={(e) => handle(e)}
                                        value={train.name}
                                        type="text"
                                        id="name"
                                        placeholder="Name..."
                                    />
                                </div>

                                <div
                                    onClick={() => document.querySelector(".ann-image-field").click()}
                                    id="image-ann-uploader"
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        position: "relative",
                                    }}
                                >
                                    <input
                                        type="file"
                                        accept="image/*"
                                        className="ann-image-field hidden "
                                        style={{
                                            color: "var(--color-text)",
                                            position: "absolute",
                                            bottom: "2px",
                                            marginLeft: "170px",
                                        }}
                                        onChange={({ target: { files, value } }) => {
                                            files?.[0] && setFileName(files[0].name);
                                            if (files) {
                                                setImage(URL.createObjectURL(files[0]));
                                            }
                                            setSelectedFile(files?.[0]);
                                        }}
                                        value={train.image}
                                        id="image"
                                    />

                                    {image ? (
                                        <img src={image} alt={fileName} width={160} height={160} />
                                    ) : (
                                        <div className="flex flex-col items-center justify-center gap-4 text-center">
                                            <img src={uploadIcon} alt={fileName} width={60} height={60} />
                                            <p style={{ color: "var(--color-text)" }}>
                                                Drag and drop photo or{" "}
                                                <strong>
                                                    browse computer [ PNG , JPEG ]<br></br>
                                                    Max size (40Mb) - Min size (1Mb)
                                                </strong>
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <LoadingButton
                                    loading={isUpdating}
                                    color="error"
                                    variant="contained"
                                    className="!rounded-xl !bg-redish !capitalize py-3 px-4 my-2"
                                    type="submit"
                                >
                                    Save Changes
                                </LoadingButton>
                            </Stack>
                        </form>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
