import React, { useEffect, useRef, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import img1 from "../../../Assests/images/homehdhd-vector.svg";
import activeIcon from "../../../Assests/images/ellipfsdfdsse-760.svg";
import dropdownIcon from "../../../Assests/images/vecfsd32345tor.svg";
import sendIcon from "../../../Assests/images/frame-67810-sgsdg-25-gs.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Editetraining from "./editetraining";
import { useUserContext } from "../../../context/userContext.tsx";
import { RoomsArea } from "./RoomsArea.tsx";

const AdminMessege = () => {
  const [modalShow33, setModalShow33] = React.useState(false);
  const { userId } = useUserContext();

  const messagesEndRef2 = useRef(null);
  useEffect(() => {
    if (messagesEndRef2 && messagesEndRef2.current) {
      const element = messagesEndRef2.current;
      element.scroll({
        top: element.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }
  }, []);

  const any3332 = useRef();
  const [message, setmessage] = useState();

  let addtrainURL = "https://dipndipapi.mass-fluence.com/api/send-message";

  function handle(e) {
    setmessage(e.target.value);
  }

  function submit(e) {
    fetchAxios2();
    e.preventDefault();

    const formData = new FormData();

    formData.append("message", message);
    formData.append("room_id", roomProperty.RoomId);
    formData.append("user_id", `${userId}`);

    axios
      .post(addtrainURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        any3332.current.value = "";
      });
  }

  const [roomProperty, setroomProperty] = useState({
    RoomId: "",
    personName: "",
    personImage: "",
    personlocation: "",
    personoccupation: "",
    personstatus: "",
    message: "",
  });

  const [rooms, setRooms] = useState([]);

  const fetchAxios = async () => {
    const res = await axios.get(`https://dipndipapi.mass-fluence.com/api/rooms?user_id=${userId}`);

    setRooms(res.data.data.rooms);
  };

  useEffect(() => {
    fetchAxios();
  }, []);

  const [ItemsDetails23431, setItemsDetails231] = useState([]);
  const [ItemsDetails234312, setItemsDetails2312] = useState([]);

  const fetchAxios2 = async () => {
    const res = await axios.get(
      `https://dipndipapi.mass-fluence.com/api/room-messages?room_id=${roomProperty.RoomId}&user_id=${userId}`
    );

    setItemsDetails231(res.data.data.messages);
  };

  useEffect(() => {
    setItemsDetails2312(any3332);
  }, []),
    useEffect(() => {
      fetchAxios2();
    }, [roomProperty.RoomId]);

  // Delete message
  let deleterainURL = "https://dipndipapi.mass-fluence.com/api/delete-message";

  function handelDelete(id) {
    fetchAxios2();
    axios
      .post(deleterainURL, {
        message_id: id,
        room_id: roomProperty.RoomId,
        user_id: `${userId}`,
      })
      .then((res) => {
        if (res.status === 201) {
          fetchAxios2();
        }
      });
  }

  const [messageStatus, setMessageStatus] = useState(null);

  const fetchMessagesStatus = async () => {
    const res = await axios.get(`https://dipndipapi.mass-fluence.com/api/message-status?user_id=${userId}`);

    setMessageStatus(Boolean(res.data.data.status));
  };

  useEffect(() => {
    fetchMessagesStatus();
  }, []);
  return (
    <Col id="message3424" className="d-flex justify-content-between items-center justify-center mr-auto px-16">
      <div style={{ width: "85px" }}></div>

      <div id="ContainerForMesseer34343">
        {messageStatus ? (
          <Row className="mt-4 container d-flex justify-content-between">
            <RoomsArea rooms={rooms} setroomProperty={setroomProperty} />
            <Col id="second_half323_messege" className="col-lg-8 col-7">
              {roomProperty.personImage ? (
                <div id="personpackedetail">
                  <div id="img_editing">
                    {roomProperty.personImage !== "null" ? (
                      <img
                        src={roomProperty.personImage}
                        alt="ERR404"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon icon={faCircleUser} style={{ color: "#798396", fontSize: "50px" }} />
                    )}
                    {/* <img src={activeIcon} alt="ERR404" /> */}
                  </div>

                  <div id="nameStateAdmin">
                    <div>
                      <h6>{roomProperty.personName}</h6>
                      <div className="d-flex">
                        <strong>
                          {roomProperty.personstatus == 1 ? <strong>Active</strong> : <strong>OFF</strong>}{" "}
                        </strong>
                        <p>
                          {roomProperty.personlocation} {roomProperty.personoccupation}
                        </p>
                      </div>
                    </div>
                    {/* <div>
                <Link><img alt="ERR404" src={personAccountDet} /></Link>
                <Link><img alt="ERR404" src={callIcon} /></Link>
                <Link><img alt="ERR404" src={videoCallIcon} /></Link>
                </div> */}
                  </div>
                </div>
              ) : null}

              <br></br>

              <div id="messegeContent">
                <div id="messegeContainerText" ref={messagesEndRef2}>
                  {ItemsDetails23431.length >= 1 ? (
                    ItemsDetails23431.map((item, index) => {
                      return (
                        <div key={item.id}>
                          {item.user_sender.id === userId ? (
                            <div id="friendMessege" className="mb-4">
                              <div id="img_editing22">
                                {item.user_sender.image ? (
                                  <img src={item.user_sender.image} alt="ERR404" className="h-8 w-8 rounded-full" />
                                ) : (
                                  <FontAwesomeIcon icon={faCircleUser} style={{ color: "#798396", fontSize: "50px" }} />
                                )}
                                <img src={activeIcon} alt="ERR404" className="!absolute !left-4 !top-7" />
                              </div>

                              <div id="nameStateAdmin22">
                                <div>
                                  <div id="timeDropDown">
                                    <p>{item.date}</p>
                                    <Dropdown>
                                      <Dropdown.Toggle>
                                        <img src={dropdownIcon} alt="ERR404" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() => {
                                            fetchAxios2();
                                            handelDelete(item.id);
                                          }}
                                        >
                                          Delete
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() => {
                                            fetchAxios2();
                                            setModalShow33(true);
                                          }}
                                        >
                                          Edit
                                        </Dropdown.Item>

                                        <Editetraining
                                          show={modalShow33}
                                          onHide={() => setModalShow33(false)}
                                          message_id={item.id}
                                          room_id={roomProperty.RoomId}
                                        />

                                        {/* <Dropdown.Item>Reply</Dropdown.Item> */}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div id="messegeText">
                                    <p>{item.message}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div id="myMessege" className=" mt-2">
                              <div id="img_editing22">
                                {roomProperty.personImage !== null ? (
                                  <img src={roomProperty.personImage} alt="ERR404" className="h-8 w-8 rounded-full" />
                                ) : (
                                  <FontAwesomeIcon icon={faCircleUser} style={{ color: "#798396", fontSize: "50px" }} />
                                )}
                                <img src={activeIcon} alt="ERR404" className="!absolute !left-4 !top-7" />
                              </div>

                              <div id="nameStateAdmin22">
                                <div>
                                  <div id="timeDropDown" className="flex !items-center">
                                    <p>{item.date}</p>
                                    <Dropdown>
                                      <Dropdown.Toggle>
                                        <img src={dropdownIcon} alt="ERR404" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() => {
                                            fetchAxios2();
                                            handelDelete(item.id);
                                          }}
                                        >
                                          Delete
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div id="messegeText">
                                    <p>{item.message}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <h1 className="d-flex justify-content-center mt-5 pt-5 text-redish">No Message Yet</h1>
                  )}
                </div>

                {roomProperty.personImage ? (
                  <div id="sendInput">
                    <input
                      type="text"
                      placeholder="Type something..."
                      style={{ color: "var(--color-text)" }}
                      onChange={(e) => handle(e)}
                      ref={any3332}
                    // value={train.name}
                    />
                    <div id="sendingicons">
                      {/* <Link><img alt="ERR$04" src={emojiIcon}/></Link>
<Link><img alt="ERR$04" src={recordIcon}/></Link>
<Link><img alt="ERR$04" src={fileIcon}/></Link> */}

                      <Link
                        onClick={(e) => {
                          fetchAxios2();
                          submit(e);
                        }}
                      >
                        <img alt="ERR$04" src={sendIcon} />
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
        ) : (
          <div className="flex flex-col  gap-8 items-center justify-center h-screen">
            <p className="text-4xl text-redish">Messages has been disabled</p>
          </div>
        )}
      </div>
    </Col>
  );
};

export default AdminMessege;
