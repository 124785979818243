import React, { useEffect, useRef, useState } from "react";
import "./leftDash.css";
import HomeIcon from "../../../Assests/images/ufufjf56.png";
import Home from "../../../Assests/images/vfffasdfector.png";
import Library from "../../../Assests/images/framenff-67964.png";
import notification from "../../../Assests/images/vectorgdgdgd.png";
import Profile from "../../../Assests/images/vegd4t3ctor.png";
import message from "../../../Assests/images/vecsgf35tor.png";
import Analytics from "../../../Assests/images/home-icon-ionicogdsgns-filled-bar-chart.svg";
import settings from "../../../Assests/images/vectorgd432ghdfg.png";
import Home2 from "../../../Assests/images/vectodrhfgh-4545.png";
import Library2 from "../../../Assests/images/groupvgsgt-43.png";
import notification2 from "../../../Assests/images/vectorgfnhtru-45-y.png";
import Profile2 from "../../../Assests/images/vectordgdghdhgds-32.png";
import message2 from "../../../Assests/images/vectorjgfj-57657.png";
import Analytics2 from "../../../Assests/images/icon-ionicons-fjhffilled-bar-chart.svg";
import settings2 from "../../../Assests/images/vectorsfgsgsdgs-43.png";

import logOut from "../../../Assests/images/frame-67880-fhfhfghfg.png";
import { Link, useNavigate } from "react-router-dom";
import { useSignOut } from "react-auth-kit";
import { useUserContext } from "../../../context/userContext.tsx";
import { ProtectedByRole, Role } from "../../ProtectedConfig/index.tsx";

const menuItems = (pathname, userId, userRole) => [
  {
    text: "Home",
    icon: Home,
    roles: ["SuperAdmin", "Subuser", "Admin"],
    icon2: Home2,
    link: `/${userRole}/${userId}`,
    key: 1,
    state: pathname === `/${userRole}/${userId}` ? "activeState" : "",
  },
  {
    text: "Library",
    icon: Library,
    icon2: Library2,
    roles: ["SuperAdmin", "Subuser", "Admin"],
    link: `/${userRole}/Library/${userId}`,
    key: 2,
    state: pathname === `/${userRole}/Library/${userId}` ? "activeState" : "",
  },
  {
    text: "Notification",
    icon: notification,
    roles: ["SuperAdmin", "Subuser", "Admin"],
    icon2: notification2,
    link: `/${userRole}/MainNotification/${userId}`,
    key: 3,
    state: pathname === `/${userRole}/MainNotification/${userId}` ? "activeState" : "",
  },
  {
    text: "Profile",
    icon: Profile,
    roles: ["SuperAdmin"],
    icon2: Profile2,
    link: `/${userRole}/MainProfile/${userId}`,
    key: 4,
    state: pathname === `/${userRole}/MainProfile/${userId}` ? "activeState" : "",
  },
  {
    text: "Message",
    icon: message,
    icon2: message2,
    roles: ["SuperAdmin", "Subuser", "Admin"],
    link: `/${userRole}/MainMessanger/${userId}`,
    key: 5,
    state: pathname === `/${userRole}/MainMessanger/${userId}` ? "activeState" : "",
  },
  {
    text: "Analytics",
    icon: Analytics,
    roles: ["SuperAdmin"],
    icon2: Analytics2,
    link: `/${userRole}/MainAnalytics/${userId}`,
    key: 6,
    state: pathname === `/${userRole}/MainAnalytics/${userId}` ? "activeState" : "",
  },
  {
    text: "Settings",
    icon: settings,
    roles: ["SuperAdmin"],
    icon2: settings2,
    link: `/${userRole}/MainSetting/${userId}`,
    key: 7,
    state: pathname === `/${userRole}/MainSetting/${userId}` ? "activeState" : "",
  },
];

export const Sidebar = () => {
  const [isDark, setIsDark] = useState(localStorage.getItem("active") === "true");
  const singOut = useSignOut();
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const { userId, userRole } = useUserContext();
  const [isExpanded, setExpendState] = useState(false);
  const mode = useRef<any>();
  const [state, setstate] = useState<any>("");
  useEffect(() => {
    setstate(mode.current);
    setIsDark(localStorage?.getItem("active") === "true" || false);
  }, []);
  const toggleDarkMode = () => {
    setIsDark((prevIsDark) => {
      const newIsDark = !prevIsDark;
      localStorage.setItem("active", newIsDark.toString());

      if (newIsDark) {
        document.body.classList.add("dark");
        mode.current.classList.add("active");
      } else {
        document.body.classList.remove("dark");
        mode.current.classList.remove("active");
      }

      return newIsDark;
    });
  };
  useEffect(() => {
    if (isDark) {
      document.body.classList.add("dark");
      mode.current.classList.add("active");
    } else {
      document.body.classList.remove("dark");
      mode.current.classList.remove("active");
    }
  }, [isDark]);
  return (
    <div
      style={{ zIndex: "99" }}
      onMouseEnter={() => setExpendState(!isExpanded)}
      onMouseLeave={() => setExpendState(false)}
      className={isExpanded ? "side-nav-container" : "side-nav-container side-nav-container-NX"}
    >
      <Link to={`/SuperAdmin/${userId}`} className="flex items-center justify-center w-full h-16 mt-8">
        <img src={HomeIcon} alt="" className="w-16 h-16" />
      </Link>
      <div className="flex flex-col w-full h-[75vh] justify-center px-2">
        {menuItems(pathname, userId, userRole).map((item) => (
          <ProtectedByRole
            key={item.text}
            condition={{
              allowedRoles: item.roles as Role[],
            }}
          >
            <div key={item.key} id="mainContainer112" className={state}>
              <Link to={item.link} className={isExpanded ? "menu-item" : "menu-item menu-item-NX"}>
                <img id="mainLogo111" className="menu-item-icon" src={item.icon} alt="" />
                <img id="subLogo22" className="menu-item-icon" src={item.icon2} alt="" />
                {
                  <p
                    className={`${isExpanded ? "block duration-300 ease-in-out " : "hidden duration-300 ease-in-out "
                      } `}
                  >
                    {item.text}
                  </p>
                }
              </Link>
            </div>
          </ProtectedByRole>
        ))}
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "100px",
          left: "50%",
          transform: " translate(-50%, 0%)",
        }}
        onClick={toggleDarkMode}
        id="dark_light"
        ref={mode}
      />
      <a
        href="/"
        onClick={() => {
          singOut();
          navigate("/");
        }}
        style={{
          position: "absolute",
          bottom: "10px",
          left: "50%",
          transform: " translate(-50%, 0%)",
        }}
      >
        <img src={logOut} alt="ERR404" />
      </a>
    </div>
  );
};
