import React from "react";
import SubAnnouncemnts from "../../../components/SubPages/Announcemnts/Announcemnts";

const SubMainAnnouncemnts = () => {
  return (
    <div style={{ display: "flex" }} id="mainAnnounmentsContainer34">

      <SubAnnouncemnts />
    </div>
  );
};

export default SubMainAnnouncemnts;
