import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import img1 from "../../../Assests/images/homehdhd-vector.svg";

import React from "react";
import { Col } from "react-bootstrap";
import { useUserContext } from "../../../context/userContext.tsx";

export const RoomsArea = ({ rooms, setroomProperty }) => {
    const { userId } = useUserContext();
    return (
        <Col id="first_half_messege" className="col-lg-4 col-5">
            <div id="search_container_messege">
                <label id="main_serch_messege_label" htmlFor="main_serch_messege">
                    <img src={img1} alt="ERR404" />
                </label>
                <input type="text" id="main_serch_messege" placeholder="Search for admin" />
            </div>

            <div className="flex items-center gap-4">
                <button id="messege_chan_btn">Admin Chat</button>
            </div>
            {rooms.map((item, index) => {
                const isOne = item.user_one.id === userId;
                return (
                    <div
                        id="messegeperson"
                        className="items-center "
                        key={item.id}
                        onClick={() => {
                            setroomProperty({
                                RoomId: `${item.id}`,
                                personName: `${isOne ? item.user_two.name : item.user_one.name}`,
                                personImage: `${isOne ? item.user_two.image : item.user_one.image}`,
                                personoccupation: `${isOne ? item.user_two.occupation : item.user_one.occupation}`,
                                personlocation: `${isOne ? item.user_two.location : item.user_one.location}`,
                                personstatus: `${isOne ? item.user_two.status : item.user_one.status}`,
                                personmessages: `${isOne ? item.user_two.status : item.user_one.status}`,
                            });
                        }}
                    >
                        {!item.user_two.image || !item.user_one.image ? (
                            <FontAwesomeIcon icon={faCircleUser} style={{ color: "#798396", fontSize: "50px" }} />
                        ) : (
                            <img
                                src={isOne ? item.user_two.image : item.user_one.image}
                                alt="ERR404"
                                style={{
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "50%",
                                }}
                            />
                        )}

                        <div id="messegeDetaileDateName">
                            <div>
                                <h6>{isOne ? item.user_two.name : item.user_one.name}</h6>
                                <p>{isOne ? item.user_two.lastMessege : item.user_one.lastMessege}</p>
                            </div>
                            <div>
                                <p>{isOne ? item.user_two.last_seen : item.user_one.last_seen}</p>
                            </div>
                        </div>
                    </div>
                );
            })}
        </Col>
    );
};
