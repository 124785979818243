import React, { useEffect, useState } from "react";
import RightDash from "../../../utils/Navbar/RightDash/rightDash";
import { Button, Card, Col, Dropdown, Row } from "react-bootstrap";
import Topnav from "../../../utils/Navbar/topnav/topnav";
import img1 from "../../../Assests/images/card-imagehdh5-rectangle-6439.png";
import img2 from "../../../Assests/images/card-image-red3hctangle-6439.png";
import img3 from "../../../Assests/images/card-image-refr43gctangle-6439.png";
import img4 from "../../../Assests/images/card-imagf43gt34e-rectangle-6439.png";
import img5 from "../../../Assests/images/card-image-avatarsdgfdg-group-1-horizontal.png";
import { Link, useParams } from "react-router-dom";

import threepoints from "../../../Assests/images/frame-170fsdf7478168.png";
import axios from "axios";
import Addtrain from "./addtrain";
import EditFile from "./editFile";
import FileSaver from "file-saver";
import Cookies from "js-cookie";
import Viewer from "./viewer";
import fileDownload from "js-file-download";
import { useUserContext } from "../../../context/userContext.tsx";

const Files = () => {
  const { userId } = useUserContext();

  const { name } = useParams();
  const { fileID } = useParams();

  const [ItemsDetails2343, setItemsDetails23] = useState([]);

  const fetchAxios = async () => {
    const res = await axios.get(
      `https://dipndipapi.mass-fluence.com/api/training-files?token=${Cookies.get()._auth}&user_id=${JSON.parse(Cookies.get()._auth_state).id
      }&training_id=${fileID}`
    );

    setItemsDetails23(res.data.data);
  };

  useEffect(() => {
    fetchAxios();
  }, []);

  // Delete train file
  const [deletetrain, setdeletetrain] = useState({
    training_id: "",
  });

  let addtrainURL = `https://dipndipapi.mass-fluence.com/api/delete-file?token=${Cookies.get()._auth}&user_id=${JSON.parse(Cookies.get()._auth_state).id
    }`;
  function handle(e) {
    const newData = { ...deletetrain };
    newData[e.target.id] = e.target.value;
    setdeletetrain(newData);
  }

  function handelDelete(id) {
    axios
      .post(addtrainURL, {
        file_id: id,
      })
      .then((res) => {
        if (res.data.status === "success") {
          // setShow(true)
          this.resetForm();
        } else if (res.data.status === "fail") {
          alert("Error al enviar el mensaje");
        }
        setInterval(() => {
          window.location.reload();
        }, 400);
      })
      .catch((ERR) => {
        // console.log(ERR);
      });
  }

  return (
    <Col id="Library3424" className="d-flex justify-content-between align-items-start mb-5">
      <div style={{ width: "100px" }}></div>

      <div id="inialTrainingContainer34r">
        <div className="initial_title_Sec">
          <h1 style={{ color: "var(--color-text)" }} className="font-bold text-2xl">
            {name}
          </h1>
        </div>

        <Row id="cardContainer34r" className="m-auto mt-4 container">
          {ItemsDetails2343.map((item, index) => {
            let text = item.file;
            let result = text.substr(42);

            const downloadFileURL = () => {
              fileDownload(item.file, `${result}`);
            };

            return (
              <Col key={item.id} className="col-6">
                <Card
                  className="mt-4 "
                  style={{
                    background: "var(--color-frame)",
                    borderRadius: "25px",
                    padding: "5px 5px 10px",
                    height: "95%",
                  }}
                >
                  <div onClick={() => window.location.href == `/SubViewer/${result}/${item.file_type}/${item.name}`}>
                    <img
                      alt="ERR404"
                      style={{
                        borderRadius: "25px",
                        height: "230px",
                        width: "100%",
                      }}
                      src={item.image}
                    />
                  </div>

                  <div id="organizeCardDetail">
                    <div className="d-flex justify-content-between">
                      <h5 id="cardTit4343gdfgf4">{item.name}</h5>
                    </div>
                    {/* 
    <div 
     style={{textDecoration:"none"}}> */}

                    <a
                      className="w-fit"
                      href={`https://dipndipapi.mass-fluence.com/api/download-file?user_id=${userId}&file_id=${item.id}`}
                      id="card_btn_initial22"
                    >
                      Download
                    </a>

                    {/* </div> */}
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>

      <div style={{ width: "85px" }}></div>
      <div style={{ position: "fixed", right: "0" }}></div>
    </Col>
  );
};

export default Files;
