import axios from "axios";
import logoDipndip from "../../Assests/images/dipndip-logdo-r-onblack-rgb-1-fsdgfsdgsd-32-r.png";

import React, { useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

const NewPassword = () => {
  const { email } = useParams();
  const { code } = useParams();

  const navigate = useNavigate();

  const any3332 = useRef();
  const any333244 = useRef();
  const [pass, setpass] = useState();
  const [conpass, setconpass] = useState();

  function handle1(e) {
    setpass(e.target.value);
  }
  function handle2(e) {
    setconpass(e.target.value);
  }

  let addtrainURL = "https://dipndipapi.mass-fluence.com/api/reset-password";

  function submit(e) {
    e.preventDefault();

    const formData = new FormData();

    formData.append("email", email);
    formData.append("code", code);
    formData.append("new_password", pass);
    formData.append("confrim_password", conpass);

    axios
      .post(addtrainURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        window.alert(res.data.msg);
        navigate(`/`);
      })
      .catch((ERR) => {
        window.alert("password not match");
      });
  }

  return (
    <div id="pass_page">
      <img alt="err404" id="mainLogoLogin" src={logoDipndip} />
      <div className="secondHalp">
        <p>Reset Password</p>
        <h6>Now enter the new password</h6>
        <br></br>
        <br></br>
        <form id="form_for_login">
          <input
            onChange={(e) => handle1(e)}
            ref={any3332}
            type="password"
            placeholder="New Password "
          />
          <input
            onChange={(e) => handle2(e)}
            ref={any333244}
            type="password"
            className="mt-3"
            placeholder="Confirm New Password"
          />

          <br />
          <br />
          <button onClick={(e) => submit(e)} id="fet4356546">
            finish
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewPassword;
