import { faArrowLeft, faArrowRight, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import axios from "axios";
import Cookies from "js-cookie";

const SwiperContainer = () => {
  const [open, setOpen] = React.useState(false);
  const [SlideImage, setSlideImage] = useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [branches, setBranches] = useState([]);
  const fetchAxios = async () => {
    const res = await axios.get(
      `https://dipndipapi.mass-fluence.com/api/home?token=${Cookies.get()._auth}&user_id=${JSON.parse(Cookies.get()._auth_state).id
      }`
    );

    setBranches(res.data.data);
  };
  useEffect(() => {
    fetchAxios();
  }, []);
  const isDisabled = SlideImage?.length <= 1;
  return (
    <div className="relative min-h-[280px] px-8">
      <Modal className="bg-black/70 blu" open={open}>
        <div>
          {SlideImage?.length ? (
            <Carousel
              nextIcon={
                <button className={`${isDisabled ? "bg-gray-500" : "bg-[#CA1E15]"}  rounded-lg p-2 px-4`}>Next</button>
              }
              prevIcon={
                <button className={`${isDisabled ? "bg-gray-500" : "bg-[#CA1E15]"}  rounded-lg p-2 px-4`}>Prev</button>
              }
              indicators={false}
              className="countries-floating-slider"
              style={{
                ".carousel-control-next": {
                  backgroundColor: "red",
                },
              }}
            >
              {SlideImage.map((item) => {
                return (
                  <Carousel.Item key={item.id} style={{ marginTop: "5%", height: "", width: "80%", marginLeft: "20%" }}>
                    <img
                      className="mt-4 w-[80%] flex ite justify-center lg:max-h-[400px] xl:max-h-[550px] 2xl:max-h-[750px] rounded-lg object-contain"
                      alt="ERR404"
                      src={item.image}
                    />
                  </Carousel.Item>
                );
              })}
            </Carousel>
          ) : (
            <h1 className="flex items-center justify-center  text-9xl h-full mt-5 text-white animate-pulse">
              No images to show
            </h1>
          )}
          <IconButton id="CloseMarrk" className="!bg-[#CA1E15] h-16 w-16">
            <FontAwesomeIcon className="z-50" onClick={handleClose} icon={faXmark} />
          </IconButton>
        </div>
      </Modal>
      <div
        style={{
          width: "100% !important",
          overflow: "hidden",
        }}
        className="container-box"
      >
        <div
          style={{
            width: "100% !important",
            overflow: "hidden",
          }}
          className="small-container"
        >
          <Swiper
            style={{
              width: "100% !important",
              overflow: "hidden",
            }}
            modules={[Navigation, Pagination]}
            slidesPerView={3}
            navigation={{ nextEl: "#arrow-right", prevEl: "#arrow-left" }}
            autoHeight={true}
          >
            <div
              style={{
                width: "100% !important",
              }}
              className=""
            >
              {branches?.length >= 1 ? (
                branches.map((item) => {
                  return (
                    <SwiperSlide
                      style={{
                        width: "100% !important",
                      }}
                      key={item.id}
                    >
                      <div
                        onClick={() => {
                          handleOpen();
                          setSlideImage(item.images);
                        }}
                        className="swipCardContainer"
                      >
                        <img alt="ERR404" src={item.image} />
                        <div className="country_name">{item.name}</div>
                        <div className="country_branch_name">{item.branch_name}</div>
                      </div>
                    </SwiperSlide>
                  );
                })
              ) : (
                <h1 className="flex items-center justify-center w-full h-full min-h-[280px] text-redish">No Items</h1>
              )}
            </div>
          </Swiper>
        </div>
      </div>

      <button id="arrow-left" className="absolute m-auto left-0 top-[50%]">
        <FontAwesomeIcon icon={faArrowLeft} style={{ color: "var(--color-text)" }} />
      </button>
      <button id="arrow-right" className="absolute right-0 top-[50%]">
        <FontAwesomeIcon icon={faArrowRight} style={{ color: "var(--color-text)" }} />
      </button>
    </div>
  );
};

export default SwiperContainer;
