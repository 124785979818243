import React from "react";
import Notification from "../../../components/superPages/notification/nontification";

const MainNotification = () => {
  return (
    <div>
      <div id="sfdf343rerer">

        <Notification />
      </div>
    </div>
  );
};

export default MainNotification;
