import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EditeImgIcon from "../../../Assests/images/home-icohfhfgn-heroicons-solid-pencil-alt.svg";
import { useUserContext } from "../../../context/userContext.tsx";
import "./Home.css";
import UpdateAnnouncementsCover from "./UpdateAnnouncementsCover.js";

export const AnnouncementsSection = ({ data, refetch }) => {
    const [openUpdateAnnouncements, setOpenUpdateAnnouncements] = useState(null);
    const handleOpenUpdate = (Announcements) => setOpenUpdateAnnouncements(Announcements);
    const handleCloseEdit = () => {
        setOpenUpdateAnnouncements(null);
        refetch();
    };

    const { userRole, userId } = useUserContext();
    return (
        <div className="relative rounded-[20px] w-[578px] h-[269px]">
            {data ? (
                <>
                    <Link to={`/${userRole}/MainAnnouncemnts/${userId}`}>
                        <img className="rounded-[20px] w-[578px] max-h-[269px]" alt={data?.text} src={data?.image} />
                        <div className="rounded-[20px] bg-black/10 h-full w-[578px]  absolute top-0 flex items-end pb-8 px-8 text-[32px] font-bold text-white">
                            {data?.text}
                        </div>
                    </Link>
                    <UpdateAnnouncementsCover
                        announcements={data}
                        onClose={handleCloseEdit}
                        open={openUpdateAnnouncements === data}
                    />
                </>
            ) : (
                <h1 className="d-flex justify-content-center mt-5 text-redish">No Items</h1>
            )}

            {userRole === "SuperAdmin" ? (
                <button onClick={() => handleOpenUpdate(data)} className="absolute right-4 z-50 top-4">
                    <img alt="ERR404" src={EditeImgIcon} />
                </button>
            ) : null}
        </div>
    );
};
