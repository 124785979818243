import React from "react";
import "./Analytics.css";
import { Col, Row } from "react-bootstrap";
import { ActiveUsers } from "./ActiveUsers/index.tsx";
import { useQuery } from "@tanstack/react-query";
import { useUserContext } from "../../../context/userContext.tsx";
import { GetData } from "../../../api/index.tsx";
import { UsersAnalytics } from "./AdminAnalytics/index.tsx";
import PagesViews from "./PagesViews/index.tsx";
import { DeviceLocations } from "./DeviceLocations/index.tsx";
import { Activity } from "./Activity/index.tsx";
import { LoadingIndicator } from "../../shared/LoadingIndicator/index.tsx";

import { LoadingButton } from "@mui/lab";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MyDocument from "../../Reports/MyDocument.tsx";
import moment from "moment";

const Analytics = () => {
  const { userToken, userId } = useUserContext();
  const { isLoading, data: analytics } = useQuery({
    queryKey: ["analytics"],
    queryFn: () => GetData("analytics", userToken, userId),
    staleTime: 1000 * 60 * 5,
  });

  return !isLoading ? (
    <Col id="manuals423453" className="d-flex justify-content-between align-items-start mb-5">
      <div id="ContainerForAnalytics2323" className="mx-auto mt-6">
        <div className="flex items-center justify-end w-full">
          <PDFDownloadLink
            document={<MyDocument analytics={analytics} />}
            fileName={`dipndip-reports-${moment(new Date).format('MM/DD/YYYY')}.pdf`}
          >
            {({ loading }) => {
              return (
                <LoadingButton
                  color="error"
                  loading={loading}
                  variant="contained"
                  className={`!capitalize  `}
                  endIcon={<FontAwesomeIcon icon={faDownload} />}
                >
                  {loading ? "Loading reports file..." : "Download Report"}
                </LoadingButton>
              );
            }}
          </PDFDownloadLink>
        </div>
        <div className="container  pb-6 mx-auto">
          <Row>
            <div className="col-lg-4 col-6  mt-4 ">
              <div id="userOnlinePerson" className="page page-1" style={{ overflowY: "auto" }}>
                <h1>User Online</h1>
                {analytics?.data?.ActiveUsers?.map((item) => {
                  return <ActiveUsers key={item.id} item={item} />;
                })}
                {analytics?.data?.OfflineUsers?.map((item) => {
                  return <ActiveUsers key={item.id} item={item} offline />;
                })}
              </div>
            </div>
            <div className="col-lg-4 col-6 mt-4">
              <UsersAnalytics item={analytics?.data?.UsersNumbers} />
            </div>
            <div className="col-lg-4 col-6 mt-4">
              <PagesViews data={analytics?.data?.PagesViews} />
            </div>
            <div className="col-lg-4 col-6 mt-4">
              <div id="userOnlinePerson" className="page page-2" style={{ overflowY: "auto" }}>
                <h1>Device Location</h1>
                {analytics?.data?.DeviceAndLocation?.map((item, i) => {
                  return <DeviceLocations key={i} item={item} />;
                })}
              </div>
            </div>
            <div className="col-lg-8 col-6 mt-4">
              <div id="userOnlinePerson" className="page page-3" style={{ overflowY: "auto" }}>
                <h1 id="ActivityTit34">Activity</h1>
                {analytics?.data?.Activity?.map((item, i) => {
                  return <Activity key={i} item={item} />;
                })}
              </div>
            </div>
          </Row>
        </div>
      </div>
    </Col>
  ) : (
    <LoadingIndicator />
  );
};

export default Analytics;
