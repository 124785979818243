import React, { useEffect, useState } from "react";
import "./Home.css";
import MarkerCluster from "../../Map/mainMap.js";
import { Fade } from "react-reveal";

import { GoToLibraryButton } from "./GoToLibraryButton.tsx";
import SwiperContainer from "./swiper.js";
import { TextSection } from "./TextSection.tsx";
import { Layout } from "./Layout.tsx";
import { AnnouncementsSection } from "./AnnouncementsSection.tsx";
import { HomePageFeatureSlider } from "./HomePageFetureSlider.js";
import axios from "axios";
import { useUserContext } from "../../../context/userContext.tsx";

const Home = () => {
  const [featureSlider, setFeatureSlider] = useState([]);
  const [mapCountries, setMapCountries] = useState([]);
  const [announcements, setAnnouncements] = useState({});
  const [branchesNumber, setBranchesNumber] = useState({});
  const { userId, userToken } = useUserContext();
  const fetchHomePageData = async () => {
    const res = await axios.get(
      `https://dipndipapi.mass-fluence.com/api/homepage?token=${userToken}&user_id=${userId}`
    );
    setFeatureSlider(res?.data?.data?.sliders?.reverse());
    setAnnouncements(res.data.data.Announcements);
    setMapCountries(res.data.data.map);
    setBranchesNumber(res.data.data.branches_number);
  };
  useEffect(() => {
    fetchHomePageData();
  }, []);
  return (
    <Layout>
      <div className="flex flex-col gap-3">
        <Fade left>
          <GoToLibraryButton user="SuperAdmin" />
        </Fade>
        <div className="flex items-start gap-3 ">
          <div className="flex flex-col items-center gap-3">
            <Fade right>
              <HomePageFeatureSlider items={featureSlider} refetch={fetchHomePageData} />
            </Fade>
            <Fade left>
              <MarkerCluster countries={mapCountries} branchesNumber={branchesNumber} />
            </Fade>
          </div>
          <div className="flex flex-col gap-y-[18px]">
            <Fade left>
              <SwiperContainer />
            </Fade>
            <Fade right>
              <AnnouncementsSection data={announcements} refetch={fetchHomePageData} />
            </Fade>
            <Fade>
              <TextSection />
            </Fade>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
