import React from "react";
import Library from "../../../components/superPages/Library/library.tsx";

const LibraryPage = () => {
  return (
    <div id="">
      <Library />
    </div>
  );
};

export default LibraryPage;
