import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import uploadIcon from "../../../Assests/images/setting-super-hfjadmin-icons.svg";
import Cookies from "js-cookie";
import axios from "axios";
import { ModalTitleWithClose } from "../../shared/ModalTitle.tsx";
import { ImageSize } from "../../ImageSize/index.tsx";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

const AddFolder = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [image, setimage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setfileName] = useState("No selected file");

  const queryClient = useQueryClient();
  const [folder, setFolder] = useState({
    name: "",
    folder_id: "",
  });

  let addfolderURL = "https://dipndipapi.mass-fluence.com/api/add-section-library";

  function handle(e) {
    const newData = { ...folder };
    newData[e.target.id] = e.target.value;
    setFolder(newData);
  }

  const [SelectedFile, setSelectedFile] = useState();

  function submit(e) {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append("image", SelectedFile);
    formData.append("title", folder["name"]);
    formData.append("user_id", `${JSON.parse(Cookies.get("_auth_state")).id}`);
    formData.append("token", Cookies.get()._auth);

    axios
      .post(addfolderURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 201) {
          enqueueSnackbar(`${folder["name"]} has been added successfully`, { variant: "success" });
          queryClient.invalidateQueries({ queryKey: ["library"] });
          queryClient.invalidateQueries({ queryKey: ["titles"] });
          setIsLoading(false);
          props.onHide();
        }
      })
      .catch((ERR) => {
        enqueueSnackbar(`Please fill all folder details`, { variant: "error" });
        setIsLoading(false);
      });
  }

  return (
    <div>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header variant="danger">
          <Modal.Title style={{ color: "var(--color-text)" }} id="contained-modal-title-vcenter">
            <ModalTitleWithClose onClose={props.onHide} titleText="Add Folder" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <br></br>
          <form id="" onSubmit={(e) => submit(e)}>
            <label style={{ color: "var(--color-text)" }}>Name</label>
            <br></br>
            <input
              style={{ color: "var(--color-text)" }}
              onChange={(e) => handle(e)}
              value={folder.name}
              type="text"
              id="name"
              placeholder="Name..."
            />

            <br></br>
            <br></br>

            <div onClick={() => document.querySelector(".ann-image-field").click()} id="image-ann-uploader">
              <input
                type="file"
                accept="image/*"
                className="ann-image-field"
                hidden
                onChange={({ target: { files, value } }) => {
                  files[0] && setfileName(files[0].name);
                  if (files) {
                    setimage(URL.createObjectURL(files[0]));
                  }
                  setSelectedFile(files[0]);
                }}
                value={folder.image}
                id="image"
              />

              {image ? (
                <div className="flex flex-col items-center gap-4">
                  <img src={image} alt={fileName} width={160} height={160} />
                  <p className="text-redish text-xs">
                    *Please note that, the recommended image size is width: 270px, height: 358px
                  </p>
                </div>
              ) : (
                <ImageSize src={uploadIcon} alt={fileName} sizes="width: 270px, height: 358px" />
              )}
            </div>

            <br></br>
            <LoadingButton
              loading={isLoading}
              color="error"
              variant="contained"
              className="!rounded-xl !bg-redish !capitalize py-3 px-4 my-2"
              type="submit"
            >
              Save Changes
            </LoadingButton>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddFolder;
