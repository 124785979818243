import { useState, useEffect } from "react";

const useTimeConverter = (minutes) => {
  const [timeData, setTimeData] = useState({
    hours: 0,
    days: 0,
  });

  useEffect(() => {
    const convertMinutesToHoursAndDays = (inputMinutes) => {
      const hours = Math.floor(inputMinutes / 60);
      const days = Math.floor(hours / 24);

      setTimeData({
        hours: days > 0 ? hours % 24 : hours,
        days: days,
      });
    };

    convertMinutesToHoursAndDays(minutes);
  }, [minutes]);

  return timeData;
};

export default useTimeConverter;
