import React, { useContext, useEffect, useMemo, useState } from "react";
import "./topnav.css";
import img1 from "../../../Assests/images/homehdhd-vector.svg";
import img3 from "../../../Assests/images/homhdhdfhe-vector.svg";
import { Dropdown } from "react-bootstrap";
import Cookies from "js-cookie";
import {
  faArrowRight,
  faChevronCircleDown,
  faChevronCircleRight,
  faCircleUser,
  faExternalLink,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSignOut } from "react-auth-kit";
import { Link, useNavigate } from "react-router-dom";
import { useUserContext } from "../../../context/userContext.tsx";
import { Personscontext } from "../../../context/context.js";
import { GetData } from "../../../api/index.tsx";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { LoadingIndicator } from "../../../components/shared/LoadingIndicator/index.tsx";

const Topnav = () => {
  const singOut = useSignOut();
  const navigate = useNavigate();
  const { userRole, userId, userToken } = useUserContext();

  const { ItemsDetails2343 } = useContext(Personscontext);
  const updatedUserImage = useMemo(() => {
    return ItemsDetails2343?.[0]?.user_two.image;
  }, [ItemsDetails2343]);
  const [usersName, setUsersName] = useState([]);
  const [usersImage, setUsersImage] = useState([]);
  const [openMenu, setOpenMenu] = useState(false);
  const [section, setSection] = useState("training");
  const [top, setTop] = useState(2);

  useEffect(() => {
    setUsersName(JSON.parse(Cookies.get("_auth_state")).name);
    setUsersImage(ItemsDetails2343?.[0]?.user_one.image);
  }, [updatedUserImage]);
  const { isLoading, data: trainings } = useQuery({
    queryKey: ["training", section],
    staleTime: Infinity,
    queryFn: () => GetData("trainings", userToken, userId, `section=${section}`),
  });
  const [childIMap, setChildIMap] = useState([]);
  const onHideMenu = () => {
    setOpenMenu(false);
    setChildIMap([]);
  };
  const searchItems = [
    {
      name: "Training development",
      id: 1,
      link: `/${userRole}/TrainingDev/${userId}/`,
      children: trainings?.data,
      section: "training",
    },
    {
      name: "Ordering forms",
      id: 2,
      link: `/${userRole}/MainCheckLists/${userId}/`,
      section: "operation",
      children: trainings?.data,
    },
    {
      name: "Manuals",
      id: 3,
      link: `/${userRole}/MainManuals/${userId}/`,
      section: "manuals",
      children: trainings?.data,
    },
    {
      name: "Cost management",
      id: 4,
      link: `/${userRole}/MainCostManagement/${userId}`,
      section: "costmanagement",
      children: trainings?.data,
    },
  ];

  return (
    <div id="topNav" className="!w-[50%]">
      <div id="search_container" className="relative px-2">
        <label htmlFor="main_serch">
          <img src={img1} alt="ERR404" />
        </label>
        <input
          onBlur={() => setTimeout(() => onHideMenu(), 400)}
          onFocus={() => {
            setOpenMenu(true);
          }}
          type="text"
          id="main_serch"
          placeholder="Search"
        />
        {openMenu ? (
          <div className="absolute w-full flex flex-col gap-4 duration-300 ease-in-out top-[60px] z-[99999999999999] left-0 border-2 bg-white p-4 rounded-3xl">
            {searchItems.map((item, i) => (
              <div key={item.id} className="flex items-center gap-2 justify-between  p-2 rounded-2xl">
                <Link
                  onClick={onHideMenu}
                  onMouseEnter={() => {
                    setChildIMap(item.children);
                    setSection(item.section);
                    setTop(i + 3 * 2);
                  }}
                  className="text-lg font-bold flex gap-2 items-center hover:opacity-50 ease-in-out duration-300"
                  to={item.link}
                >
                  <p className=" min-w-[200px]">{item.name}</p>
                  <FontAwesomeIcon icon={faExternalLink} className=" text-xs" style={{ color: "var(--color-text)" }} />
                </Link>
                <FontAwesomeIcon className="cursor-pointer" icon={faChevronCircleRight} />
              </div>
            ))}
          </div>
        ) : null}
        {openMenu ? (
          <div
            style={{
              top: `${top}rem`,
            }}
            className={`absolute  w-full   ${childIMap?.length > 0 ? "flex" : "hidden"
              } flex-col gap-4 duration-300 ease-in-out  z-50 -right-full border-2 bg-gray-50 p-4 rounded-3xl`}
          >
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              childIMap?.map((item, i) => (
                <div key={item.id} className="flex items-center justify-between flex-col  rounded-2xl">
                  <Link
                    onClick={onHideMenu}
                    target="blank"
                    className="text-lg font-bold flex items-center gap-2 w-full justify-between"
                    to={`${userRole}/Files/${item.name}/${item.id}/${userId}`}
                  >
                    <p>{item.name}</p>
                    <FontAwesomeIcon
                      icon={faExternalLink}
                      className=" text-xs"
                      style={{ color: "var(--color- text)" }}
                    />
                  </Link>
                </div>
              ))
            )}
          </div>
        ) : null}
      </div>

      <div id="secondnaveSec" className="px-4">
        <div>
          <h1>Hello, {usersName}</h1>
          <p>{userRole}</p>
        </div>
        <div className="flex items-center mx-3">
          {usersImage ? (
            <img
              style={{ width: "50px", height: "50px", borderRadius: "50%", border: "1px solid #CA1E15" }}
              src={usersImage}
              alt="ERR404"
            />
          ) : (
            <FontAwesomeIcon icon={faCircleUser} style={{ color: "#798396", fontSize: "45px" }} />
          )}

          <Dropdown placement="right-start" className="h-[20px]">
            <Dropdown.Toggle>
              <img src={img3} alt="ERR404" />
            </Dropdown.Toggle>

            <Dropdown.Menu pl>
              <Dropdown.Item
                onClick={() =>
                  navigate(
                    `/${JSON.parse(Cookies.get()._auth_state).occupation.replace(/\s/g, "")}/MainProfile/${JSON.parse(Cookies.get()._auth_state).id
                    }`
                  )
                }
              >
                Account
              </Dropdown.Item>
              <Dropdown.Item
                href="/"
                onClick={() => {
                  singOut();
                  navigate("/");
                }}
              >
                Log Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Topnav;
