import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import uploadIcon from "../../../Assests/images/setting-super-hfjadmin-icons.svg";
import Cookies from "js-cookie";
import axios from "axios";
import { ModalTitleWithClose } from "../../shared/ModalTitle.tsx";
import { useSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../context/userContext.tsx";

const EditTit = (props) => {
  // add train post
  const [train, settrian] = useState({
    name: props.section,
  });
  const navigate = useNavigate();
  const { userId, userRole, userToken } = useUserContext();

  let addtrainURL = "https://dipndipapi.mass-fluence.com/api/titles";
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  function handle(e) {
    const newData = { ...train };
    newData[e.target.id] = e.target.value;
    settrian(newData);
  }

  function submit(e) {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();

    formData.append("title", train["name"]);
    formData.append("title_id", props.id);
    formData.append("token", Cookies.get()._auth);
    formData.append("user_id", `${JSON.parse(Cookies.get("_auth_state")).id}`);

    axios
      .post(addtrainURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 201) {
          enqueueSnackbar(`${train.name} has been update to  successfully`, { variant: "success" });
          setIsLoading(false);
          queryClient.invalidateQueries({ queryKey: ["titles"] });
          props.onHide();
          navigate(`/${userRole}/library/${userId}`);
        }
      })
      .catch((ERR) => {
        enqueueSnackbar(`something went wrong please try again later`, { variant: "error" });
        setIsLoading(false);
      });
  }

  return (
    <div>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header variant="danger">
          <Modal.Title style={{ color: "var(--color-text)" }} id="contained-modal-title-vcenter">
            <ModalTitleWithClose onClose={props.onHide} titleText="Update Title" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <br></br>
          <form id="" onSubmit={(e) => submit(e)}>
            <label style={{ color: "var(--color-text)" }}>New Name</label>
            <br></br>
            <input onChange={(e) => handle(e)} value={train.name} type="text" id="name" placeholder="Name..." />

            <br></br>
            <br></br>

            <br></br>
            <LoadingButton
              loading={isLoading}
              color="error"
              variant="contained"
              className={`  !rounded-xl ${isLoading ? "!bg-gray-400" : "!bg-redish"} !capitalize py-3 px-4 my-2 `}
              type="submit"
            >
              Save Changes
            </LoadingButton>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditTit;
