import React from "react";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useTimeConverter from "../../../../utils/useTimeConverter.ts";

export const Activity = ({ item }) => {
    const { hours, days } = useTimeConverter(item.spent);

    return (
        <div id="personItem132" className="mt-4 flex items-center gap-2 " key={item.id}>
            {item.image == null ? (
                <FontAwesomeIcon icon={faCircleUser} style={{ color: "#798396", fontSize: "50px" }} />
            ) : (
                <img
                    src={item.image}
                    alt="ERR404"
                    style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                    }}
                />
            )}
            <div id="personDetails234" className="items-center">
                <div id="personnameStyling43">
                    <h6>{item.name}</h6>
                    <p>{item.last_login}</p>
                </div>

                <div className="flex items-center justify-center gap-2 text-sm bg-[#F3F3F3F2] p-3 rounded-md">
                    <h5>All time spent</h5>
                    <div>
                        {days ? <p>{`${days} days`}</p> : hours ? <p>{` ${hours} hours`}</p> : <p>{` ${item.spent} minutes`}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
};
