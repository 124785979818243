import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Cookies from "js-cookie";
import axios from "axios";

const Editetraining = (props) => {
  const [train, settrian] = useState({
    training_id: "",
    name: "",
  });

  let addtrainURL = "https://dipndipapi.mass-fluence.com/api/update-message";

  function handle(e) {
    const newData = { ...train };
    newData[e.target.id] = e.target.value;
    settrian(newData);
    // console.log(newData)
  }

  function submit(e) {
    e.preventDefault();

    const formData = new FormData();

    formData.append("new_message", train["name"]);
    formData.append("message_id", props.message_id);
    formData.append("room_id", props.room_id);
    formData.append("user_id", `${JSON.parse(Cookies.get("_auth_state")).id}`);

    axios
      .post(addtrainURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        // console.log(res);
      })
      .catch((ERR) => {
        // console.log(ERR);
      });
    // setInterval(()=>{
    //   window.location.reload()
    // },600)
  }

  return (
    <div>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header variant="danger">
          <Modal.Title style={{ color: "var(--color-text)" }} id="contained-modal-title-vcenter">
            Update Message
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <br></br>
          <form id="" onSubmit={(e) => submit(e)}>
            <label style={{ color: "var(--color-text)" }}>New message</label>
            <br></br>
            <input onChange={(e) => handle(e)} value={train.name} type="text" id="name" placeholder="Message..." />

            <br></br>
            <br></br>

            <button id="profileBtn434" variant="danger" type="submit" onClick={props.onHide}>
              Save Changes
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Editetraining;
