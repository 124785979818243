import { Navigate, Outlet, useLocation } from "react-router-dom";
import React from "react";
import Cookies from "js-cookie";
import { Sidebar } from "./utils/Navbar/SuperLeftDash/Sidebar.tsx";
import Topnav from "./utils/Navbar/topnav/topnav.js";
import RightDash from "./utils/Navbar/RightDash/rightDash.js";

const PrivateRoutes = () => {
  const location = useLocation();

  const isDownloadReportPage = location.pathname.includes("MainReports");
  return JSON.parse(Cookies.get()._auth_state).route === "SuperAdmin" ? (
    <div className="relative">
      {isDownloadReportPage ? null : <Topnav />}
      <div className="fixed top-0 z-50">{isDownloadReportPage ? null : <Sidebar />}</div>
      <div className="fixed top-0 right-0 z-50">{isDownloadReportPage ? null : <RightDash />}</div>
      <div>
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to={`${JSON.parse(Cookies.get()._auth_state).route}/${JSON.parse(Cookies.get()._auth_state).id}`} />
  );
};

export default PrivateRoutes;
