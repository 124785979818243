import React from "react";
import Notification from "../../../components/SubPages/notification/nontification";

const SubMainNotification = () => {
  return (
    <div>
      <div style={{ display: "flex", height: "100%" }}>

        <Notification />
      </div>
    </div>
  );
};

export default SubMainNotification;
