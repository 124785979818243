import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import Cookies from "js-cookie";

const Personscontext = createContext();

const PoepleContext = ({ children }) => {
  const [ItemsDetails2343, setItemsDetails23] = useState([]);
  const fetchAxios = async () => {
    if (Cookies.get()._auth_state !== undefined) {
      const res = await axios.get(
        `https://dipndipapi.mass-fluence.com/api/rooms?user_id=${JSON.parse(Cookies.get()._auth_state).id}`
      );
      setItemsDetails23(res.data.data.rooms);
    }
  };

  useEffect(() => {
    fetchAxios();
  }, []);

  return (
    <div>
      <Personscontext.Provider value={{ ItemsDetails2343 }}>{children}</Personscontext.Provider>
    </div>
  );
};

export { PoepleContext, Personscontext };
