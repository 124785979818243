import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactDOM from "react-dom/client";
import "./index.css";
import MainApp from "./mainApp";

import { AuthProvider } from "react-auth-kit";
import { BrowserRouter } from "react-router-dom";
import { PoepleContext } from "./context/context";
import { MaterialDesignContent, SnackbarProvider } from 'notistack'
import { styled } from "@mui/material";
import { UserProvider } from "./context/userContext.tsx";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: '#EAF7EC',
    color: 'black',

  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#CA1E15',
    color: 'white'
  },
  ".notistack-SnackbarContainer": {
    zIndex: '999999999999999',
    borderRadius: "8px"
  }
  , "&.notistack-MuiContent": {
    borderRadius: '8px',
    fontSize: "14px"
  }
}));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <PoepleContext>
    <AuthProvider
      authType={"cookie"}
      authName={"_auth"}
      cookieDomain={window.location.hostname}
      cookieSecure={true}
    >
      <UserProvider>
        <BrowserRouter>
          <SnackbarProvider
            action
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }} Components={{
              success: StyledMaterialDesignContent,
              error: StyledMaterialDesignContent,

            }}>

            <MainApp />
          </SnackbarProvider>
        </BrowserRouter>
      </UserProvider>
    </AuthProvider>

  </PoepleContext>,
);
