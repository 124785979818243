import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import uploadIcon from "../../../Assests/images/setting-super-hfjadmin-icons.svg";
import Cookies from "js-cookie";
import axios from "axios";
import { useSnackbar } from "notistack";
import { ImageSize } from "../../ImageSize/index.tsx";
import { ModalTitleWithClose } from "../../shared/ModalTitle.tsx";
import { setImage } from "@syncfusion/ej2-spreadsheet";

const EditAnnouncements = (props) => {
  console.log({ props });
  const [image, setImage] = useState(null);
  const [fileName, setfileName] = useState("No selected file");
  const { enqueueSnackbar } = useSnackbar();

  //  Create ann
  const [train, settrian] = useState({
    titleInputAnn: props.title,
    DesInputAnn: props.description,
  });

  let addtrainURL = "https://dipndipapi.mass-fluence.com/api/update-announcemnt";

  function handle(e) {
    const newData = { ...train };
    newData[e.target.id] = e.target.value;
    settrian(newData);
  }

  const [SelectedFile, setSelectedFile] = useState();

  function submit(e) {
    e.preventDefault();

    const formData = new FormData();

    formData.append("image", SelectedFile ?? null);
    formData.append("title", train["titleInputAnn"]);
    formData.append("text", train["DesInputAnn"]);
    formData.append("user_id", `${JSON.parse(Cookies.get("_auth_state")).id}`);
    formData.append("token", Cookies.get()._auth);
    formData.append("announcemnt_id", props.id);

    axios
      .post(addtrainURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        enqueueSnackbar("Announcement added Successfully", { variant: "success" }), props.onHide();
        props.refetch();
        settrian({
          titleInputAnn: "",
          DesInputAnn: "",
        });
        setImage(null);
      })
      .catch(() => enqueueSnackbar("Something went wrong, please try again", { variant: "error" }));
  }

  useEffect(() => {
    settrian({
      DesInputAnn: props.description,
      titleInputAnn: props.title,
    });
    setImage(props.image);
  }, [props.id]);

  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header variant="danger">
          <ModalTitleWithClose onClose={props.onHide} titleText="Edit Announcements" />
        </Modal.Header>
        <Modal.Body>
          <form id="fedsf346tgd" onSubmit={(e) => submit(e)}>
            <div onClick={() => document.querySelector(".ann-image-field").click()} id="image-ann-uploader">
              <input
                type="file"
                accept="image/*"
                className="ann-image-field"
                hidden
                onChange={({ target: { files } }) => {
                  files[0] && setfileName(files[0].name);
                  if (files) {
                    setImage(URL.createObjectURL(files[0]));
                  }
                  setSelectedFile(files[0]);
                }}
                value={train.image22}
                id="image22"
              />

              {image ? (
                <div className="flex flex-col items-center gap-4">
                  <img src={image} alt={fileName} width={160} height={160} />
                  <p className="text-redish text-xs">
                    *Please note that, the recommended image size is width: 568px, height: 358px
                  </p>
                </div>
              ) : (
                <ImageSize src={uploadIcon} alt={fileName} sizes="width: 568px, height: 358px" />
              )}
            </div>

            <br></br>

            <label>Title</label>
            <br></br>
            <input
              type="text"
              className="!w-full"
              style={{ color: "var(--color-text)" }}
              onChange={(e) => handle(e)}
              value={train.titleInputAnn}
              id="titleInputAnn"
              placeholder="Type Title"
            />
            <br></br>
            <br></br>

            <label>Description</label>
            <br></br>
            <textarea
              onChange={(e) => handle(e)}
              value={train.DesInputAnn}
              id="DesInputAnn"
              type="text"
              className="!w-full"
              style={{ color: "var(--color-text)" }}
              placeholder="Type Description"
            />

            <br></br>
            <br></br>
            <button id="profileBtn434" type="submit">
              Save Changes
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditAnnouncements;
