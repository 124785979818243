import React, { useEffect, useRef } from "react";
import { useState } from "react";

import countries from "i18n-iso-countries";

import enLocale from "i18n-iso-countries/langs/en.json";
import itLocale from "i18n-iso-countries/langs/it.json";
import { Col, Row } from "react-bootstrap";
import UseFileSelection from "./uploadImg/imageUploud";
import axios from "axios";
import Cookies from "js-cookie";
import AddCountry from "./addcountry";
import ChangeCover from "./changeCover";
import AddSlideImages from "./addSlideimages";
import { useSnackbar } from "notistack";
import { useUserContext } from "../../../context/userContext.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDeleteLeft, faTrash } from "@fortawesome/free-solid-svg-icons";

const Countriesmanagement = () => {
  const { enqueueSnackbar } = useSnackbar();

  countries.registerLocale(enLocale);
  countries.registerLocale(itLocale);
  const [countriesSelect, setCountriesSelect] = useState([]);
  const fetchAxios = async () => {
    const res = await axios.get(
      `https://dipndipapi.mass-fluence.com/api/countries?token=${Cookies.get()._auth}&user_id=${JSON.parse(Cookies.get()._auth_state).id
      }`
    );

    setCountriesSelect(res.data.data.countries);
  };
  useEffect(() => {
    fetchAxios();
  }, []);

  const [ItemsDetails234322, setItemsDetails2322] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [countryId, setCountryId] = useState();
  const [countryCovered, setCountryCover] = useState();
  const [branchName, setBranchName] = useState();
  const sortedCountries = countriesSelect.slice().sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
  const { userId, userToken } = useUserContext();
  const handleDeleteCountry = async () => {
    await axios
      .post(
        `	https://dipndipapi.mass-fluence.com/api/delete-country?token=${Cookies.get()._auth}&user_id=${JSON.parse(Cookies.get()._auth_state).id
        }`,
        { country_id: countryId }
      )
      .then(() => {
        enqueueSnackbar("Deleted Successfully", { variant: "success" });
        countryCovered(null);
        fetchAxios();
      })
      .catch(() => {
        enqueueSnackbar("Something went wrong, please try again", { variant: "error" });
      });
  };

  const handleCountryChange = (event) => {
    const selectedOption = event.target.value;
    const [selectedCountryId, selectedCountryImage, selectedBranchName] = selectedOption.split("##");
    setCountryId(selectedCountryId);
    setCountryCover(selectedCountryImage);
    setBranchName(selectedBranchName);
  };
  const fetchAxios22 = async () => {
    const res = await axios.get(
      `https://dipndipapi.mass-fluence.com/api/country-images?country_id=${countryId}&token=${Cookies.get()._auth
      }&user_id=${JSON.parse(Cookies.get()._auth_state).id}`
    );

    setItemsDetails2322(res.data.data.images);
  };
  useEffect(() => {
    fetchAxios22();
  }, [countryId]);

  const handleUpdateBranchName = async () => {
    axios
      .post(`https://dipndipapi.mass-fluence.com/api/update-country`, {
        country_id: countryId,
        branch_name: branchName,
        user_id: userId,
        token: userToken,
      })
      .then(() => {
        enqueueSnackbar("Updated Successfully", { variant: "success" });
        fetchAxios();
      })
      .catch(() => {
        enqueueSnackbar("Something went wrong, please try again", { variant: "error" });
      });
  };

  let addtrainURL = "https://dipndipapi.mass-fluence.com/api/delete-images-country";

  function handelDelete(Image_id) {
    axios
      .post(addtrainURL, {
        country_id: countryId,
        image_id: Image_id,
        user_id: `${JSON.parse(Cookies.get("_auth_state")).id}`,
        token: Cookies.get()._auth,
      })
      .then(() => {
        enqueueSnackbar("Updated Successfully", { variant: "success" });
        fetchAxios22();
      })
      .catch(() => {
        enqueueSnackbar("Something went wrong, please try again", { variant: "error" });
      });
  }

  const [modalShow22, setModalShow22] = React.useState(false);
  const [modalShow33, setModalShow33] = React.useState(false);
  const [modalShow44, setModalShow44] = React.useState(false);

  return (
    <div>
      <form id="formContainer4243">
        <div id="AddCountry32">
          <div className="flex items-center gap-4">
            <button id="add_file_initial" type="button" onClick={() => setModalShow22(true)}>
              Add Country
            </button>

            {countryCovered ? (
              <button onClick={() => handleDeleteCountry()} id="add_file_initial" type="button">
                Delete Country
              </button>
            ) : null}
          </div>
          <AddCountry show={modalShow22} onHide={() => setModalShow22(false)} refetch={fetchAxios} />
        </div>
        <br></br>
        <h4 style={{ color: "var(--color-text)" }}>Add Country</h4>
        <br></br>
        <div id="lastInput434">
          <select
            style={{
              width: "100%",
              borderRadius: "10px",
              borderBottom: "1px solid #C1C7CD",
              background: "var(--color-background)",
              color: "var(--color-text)",
              border: "none",
              padding: "15px",
              height: "60px",
              marginTop: " 6px",
            }}
            onChange={handleCountryChange}
            placeholder="Country"
            name="fwdfgdsgfdsg"
          >
            <option defaultValue>Country</option>
            {countriesSelect.length >= 1 ? (
              sortedCountries.map((country) => {
                return (
                  <option
                    key={country.id}
                    value={`${country.id}##${country.image}##${country.branch_name}`}
                    name={country.image}
                  >
                    <p>{country.name} - </p>
                    <p>{country.branch_name}</p>
                  </option>
                );
              })
            ) : (
              <h1 className="d-flex justify-content-center mt-5 text-redish">No Items</h1>
            )}
          </select>
        </div>
        {branchName || isEditing ? (
          <div>
            <p className="text-2xl my-8">Update branch name</p>
            <div id="lastInput434" className="flex items-center gap-4">
              <input
                style={{
                  border: "1px solid gray",
                }}
                className="mt-2 "
                placeholder="Please write a new branch name"
                id="Emailinput352"
                onChange={(e) => {
                  setBranchName(e.target.value);
                  setIsEditing(true);
                }}
                value={branchName}
                type="text"
              />
              <button
                className="text-white font-bold w-[150px]"
                id="changeCoverImage"
                type="button"
                onClick={() => handleUpdateBranchName()}
              >
                Update
              </button>
            </div>
          </div>
        ) : null}
        <div>
          <h1 style={{ color: "var(--color-text)", fontSize: "26px", margin: "20px 0px" }}>Add Photos</h1>

          <div>
            <h5 style={{ color: "var(--color-text)", marginBottom: "10px" }}>Cover Photo</h5>

            {countryCovered?.length && countryCovered !== "null" ? (
              <img
                alt="image broken"
                style={{
                  width: "150px",
                  objectFit: "cover",
                  height: "250px",
                  borderRadius: "20px",
                  marginBottom: "10px",
                }}
                src={countryCovered}
              />
            ) : (
              <h1 className="d-flex justify-content-center mt-5 text-redish">No Cover</h1>
            )}

            <button
              className="text-white font-bold"
              id="changeCoverImage"
              type="button"
              onClick={() => setModalShow33(true)}
            >
              Change image
            </button>
            <ChangeCover
              refetch={() => {
                fetchAxios22();
              }}
              show={modalShow33}
              onHide={() => setModalShow33(false)}
              countryIDD={countryId}
            />
          </div>
          <br></br>
          <div className="">
            <h5 style={{ color: "var(--color-text)", marginBottom: "10px" }}>Slide Photos</h5>
            <div className="small-container">
              <div className="flex items-center gap-2 max-w-[1500px] overflow-x-auto py-4">
                {ItemsDetails234322.length >= 1 ? (
                  ItemsDetails234322.map((item) => {
                    console.log({ item });
                    return (
                      <div className="relative">
                        <div className="absolute -right-2 -top-2 cursor-pointer bg-redish rounded-full w-8 h-8 flex items-center justify-center hover:opacity-80 duration-200">
                          <FontAwesomeIcon
                            onClick={() => handelDelete(item.id)}
                            icon={faTrash}
                            className="w-4 h-6 text-white"
                          />
                        </div>
                        <img
                          key={item.image}
                          alt="ERR404"
                          className="w-[250px] h-[150px] rounded-lg"
                          src={item.image}
                          id="slidImage3242f"
                        />
                      </div>
                    );
                  })
                ) : (
                  <h1 className="d-flex justify-content-center mt-5 text-redish">No Photos</h1>
                )}
              </div>
            </div>
          </div>
        </div>

        <br></br>
        <div className="d-flex gap-3">
          <button
            className="text-white font-bold"
            id="changeCoverImage"
            type="button"
            onClick={() => setModalShow44(true)}
          >
            Add Photo
          </button>
          <AddSlideImages
            refetch={() => fetchAxios22()}
            show={modalShow44}
            onHide={() => setModalShow44(false)}
            countryIDD={countryId}
          />
        </div>
      </form>
    </div>
  );
};

export default Countriesmanagement;
