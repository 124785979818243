import React from "react";
import { Text, Image, View } from "@react-pdf/renderer";
import QuickChart from "quickchart-js";

export const PagesView = ({ views, styles }) => {
    const qc = new QuickChart();

    qc.setConfig({
        type: "doughnut",
        data: {
            datasets: [
                {
                    data: [
                        views?.PagesViews.Management_Training?.num_views || 0,
                        views?.PagesViews.Operations_Forms?.num_views || 0,
                        views?.PagesViews.Initial_Training?.num_views || 0,
                    ],
                    backgroundColor: ["#CA1E15", "#F0281F", "#F1B0AD"],
                    cutout: "90%",
                },
            ],
        },
        options: {
            cutoutPercentage: 70,
            plugins: {
                datalabels: {
                    display: false,
                },
            },
            title: {
                display: false,
            },
        },
    });
    qc.setWidth(200).setHeight(200).setBackgroundColor("white");

    return (
        <View style={styles.chartCard}>
            <Text style={styles.header}>Pages View</Text>
            <View
                style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "100%",
                    alignItems: "center",
                    width: "200px",
                }}
            >
                <View
                    style={{
                        marginTop: "5%",
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "30px",
                        height: "85%",
                    }}
                >
                    <View>
                        <Text style={[styles.pagesNumber, { marginLeft: '30px' }]}>{views?.PagesViews?.pages_views}</Text>
                        <Text style={[styles.chartSubTitle, { color: "#797B7A", marginLeft: "15px" }]}>Since Last Month</Text>
                    </View>
                    <View>
                        <View style={styles.row}>
                            <Text style={styles.usersStatusOne} />
                            <Text style={styles.chartSubTitle}>
                                {views?.PagesViews.Operations_Forms?.percentage}% Operations Forms
                            </Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.usersStatusTwo} />
                            <Text style={styles.chartSubTitle}>
                                {views?.PagesViews.Management_Training?.percentage}% Management Training
                            </Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.usersStatusThree} />
                            <Text style={styles.chartSubTitle}>
                                {views?.PagesViews.Initial_Training?.percentage}% Initial Training
                            </Text>
                        </View>
                    </View>
                </View>
                <View>
                    <Image
                        style={{
                            width: 150,
                            height: 150,
                            borderRadius: "50%",
                        }}
                        src={`${qc.getUrl()}`}
                    />
                </View>
            </View>
        </View>
    );
};
