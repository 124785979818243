import { Button } from "@mui/material"
import { CloseOutlined } from '@ant-design/icons';
import React, { FC } from "react"

type ModalTitleWithClose = {
    onClose: () => void;
    titleText?: string
}
export const ModalTitleWithClose: FC<ModalTitleWithClose> = ({ onClose, titleText }) => {
    return (
        <div
            style={{
                color: "var(--color-text)",
            }} className="flex items-center justify-between !w-full !text-2xl">
            <p>
                {titleText}
            </p>
            <Button onClick={onClose} color="error">
                <CloseOutlined style={{ fontSize: '20px' }} />
            </Button>
        </div>
    )
}