import React, { FC } from "react";

type ImageSizeProps = {
    src: string;
    alt: string;
    sizes?: string;
};

export const ImageSize: FC<ImageSizeProps> = ({ src, alt, sizes }) => {
    return (
        <div className="flex flex-col items-center justify-center gap-2 text-center">
            <img src={src} alt={alt} width={60} height={60} />
            <p className="flex flex-col items-center justify-center" style={{ color: "var(--color-text)" }}>
                Drag and drop photo or{" "}
                <strong>
                    browse computer [ PNG , JPEG ]<br></br>
                    Max size (40Mb) - Min size (1Mb)
                </strong>
            </p>
            <p className="text-redish text-xs">*Please note that, the recommended image size is {sizes}</p>
        </div>
    );
};
