import React from "react";
import Around from "./../../../components/SubPages/around/around";

const SubMAinAround = () => {
  return (
    <div style={{ display: "flex" }} id="mainAnnounmentsContainer34">

      <Around />
    </div>
  );
};

export default SubMAinAround;
