import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import uploadIcon from "../../../Assests/images/setting-super-hfjadmin-icons.svg";
import Cookies from "js-cookie";
import axios from "axios";
import { ImageSize } from "../../ImageSize/index.tsx";
import { ModalTitleWithClose } from "../../shared/ModalTitle.tsx";
import { useSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import { LoadingButton } from "@mui/lab";

const AddTrainingModal = (props) => {
  const [image, setimage] = useState(null);
  const [fileName, setfileName] = useState("No selected file");
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  // add train post
  const [train, settrian] = useState({
    name: "",
  });

  let addtrainURL = "https://dipndipapi.mass-fluence.com/api/add-training";

  function handle(e) {
    const newData = { ...train };
    newData[e.target.id] = e.target.value;
    settrian(newData);
  }

  const [SelectedFile, setSelectedFile] = useState();

  function submit(e) {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();

    formData.append("image", SelectedFile);
    formData.append("name", train["name"]);
    formData.append("section", props.section);
    formData.append("user_id", `${JSON.parse(Cookies.get("_auth_state")).id}`);

    axios
      .post(addtrainURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 201) {
          setIsLoading(false);
          enqueueSnackbar(`${train.name} has been added successfully`, { variant: "success" });
          settrian("");
          setimage("");
          setfileName("");
          queryClient.invalidateQueries({ queryKey: ["trainings"] });
          props.onHide();
        }
      })
      .catch((ERR) => {
        setIsLoading(false);
        enqueueSnackbar(`something went wrong please try again later`, { variant: "error" });
      });
  }

  return (
    <div>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header variant="danger">
          <Modal.Title style={{ color: "var(--color-text)" }} id="contained-modal-title-vcenter">
            <ModalTitleWithClose onClose={props.onHide} titleText="Add folder" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <br></br>
          <form id="" onSubmit={(e) => submit(e)}>
            <label style={{ color: "var(--color-text)" }}>Name</label>
            <br></br>
            <input onChange={(e) => handle(e)} value={train.name} type="text" id="name" placeholder="Name..." />

            <br></br>
            <br></br>

            <div
              onClick={() => document.querySelector(".ann-image-field").click()}
              id="image-ann-uploader"
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <input
                cl
                type="file"
                accept="image/*"
                className="ann-image-field hidden"
                style={{
                  color: "var(--color-text)",
                  position: "absolute",
                  bottom: "2px",
                  marginLeft: "170px",
                }}
                onChange={({ target: { files, value } }) => {
                  files[0] && setfileName(files[0].name);
                  if (files) {
                    setimage(URL.createObjectURL(files[0]));
                  }
                  setSelectedFile(files[0]);
                }}
                value={train.image}
                id="image"
              />

              {image ? (
                <div className="flex flex-col items-center gap-4">
                  <img src={image} alt={fileName} width={160} height={160} />
                  <p className="text-redish text-xs">
                    *Please note that, the recommended image size is width: 588px, height: 495px
                  </p>
                </div>
              ) : (
                <ImageSize src={uploadIcon} alt={fileName} sizes="width: 588px, height: 495px" />
              )}
            </div>

            <br></br>
            <LoadingButton
              loading={isLoading}
              color="error"
              variant="contained"
              className={`  !rounded-xl ${isLoading ? "!bg-gray-400" : "!bg-redish"} !capitalize py-3 px-4 my-2 `}
              type="submit"
            >
              Save Changes
            </LoadingButton>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddTrainingModal;
