import React from "react";
import { Text, Image, View } from "@react-pdf/renderer";
import QuickChart from "quickchart-js";

export const AnalyticsHub = ({ hub, styles }) => {
    const qc = new QuickChart();

    qc.setConfig({
        type: "doughnut",
        data: {
            datasets: [
                {
                    data: [hub?.UsersNumbers?.supUsers?.num, hub?.UsersNumbers?.admins?.num],
                    backgroundColor: ["#797B7A", "#050505"],
                    cutout: "90%",
                },
            ],
        },
        options: {
            cutoutPercentage: 70,
            borderWidth: 4,
            plugins: {
                datalabels: {
                    display: false,
                },
            },
            title: {
                display: false,
            },
        },
    });
    qc.setWidth(200).setHeight(200).setBackgroundColor("white");
    return (
        <View style={styles.chartCard}>
            <Text style={styles.header}>Admins & Subusers Analytics Hub</Text>
            <View
                style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "100%",
                    alignItems: "center",
                    width: "200px",
                }}
            >
                <View
                    style={{
                        marginTop: "5%",
                        display: "flex",
                        justifyContent: "space-between",
                        height: "85%",
                    }}
                >
                    <View></View>

                    <View>
                        <View style={styles.row}>
                            <Text style={styles.admins} />
                            <Text style={styles.chartSubTitle}>{hub?.UsersNumbers?.supUsers?.percentage}% Subusers</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.subusers} />
                            <Text style={styles.chartSubTitle}>{hub?.UsersNumbers?.admins?.percentage}% Admins</Text>
                        </View>
                    </View>
                </View>
                <View>
                    <View>
                        <Image
                            style={{
                                width: 200,
                                height: 200,
                                borderRadius: "50%",
                            }}
                            src={`${qc.getUrl()}`}
                        />
                    </View>
                </View>
            </View>
        </View>
    );
};
