import Box from "@mui/material/Box";
import React, { useMemo, useState } from "react";
import { GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useUserContext } from "../../../../context/userContext.tsx";
import { StyledDataGrid } from "../Customs/customs.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { GetData } from "../../../../api/index.tsx";
import { EditModal } from "./EditModal.tsx";
import { LoadingIndicator } from "../../../shared/LoadingIndicator/index.tsx";

export const BranchesTable = () => {
    const queryClient = useQueryClient();

    const [filteredData, setFilteredData] = useState([]);
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const { enqueueSnackbar } = useSnackbar();
    const { userToken, userId } = useUserContext();
    const [openEditModal, setOpenEditModal] = useState({
        isOpen: false,
        branchInfo: {},
    });

    const [countryId, setCountryId] = useState<string | number>(57);
    const { isLoading: isCountriesLoading, data: branches } = useQuery({
        queryKey: ["branches", countryId],
        queryFn: () => GetData("branches", userToken as string, userId as string, `country_id=${countryId}`),
    });

    const { data: countries, isLoading } = useQuery({
        queryKey: ["countries"],
        queryFn: () => GetData("homepage", userToken as string, userId as string),
    });

    const handleBranchDelete = (id: string, name) => {
        axios
            .post("https://dipndipapi.mass-fluence.com/api/delete-branch", {
                branch_id: id,
                user_id: `${userId}`,
                token: userToken,
            })
            .then(() => {
                enqueueSnackbar(`${name} branch has been deleted successfully`, { variant: "success" });
                queryClient.invalidateQueries({ queryKey: ["branches"] });
            })
            .catch(() => {
                enqueueSnackbar("Something went wrong, please try again", { variant: "error" });
            });
    };

    const handleChangeCountry = (e) => {
        setCountryId(e.target.value);
        queryClient.invalidateQueries({ queryKey: ["branches"] });
    };

    const fetchedBranches = useMemo(() => {
        return branches?.data.branches;
    }, [branches]);

    const handleSearch = (event) => {
        const searchQuery = event.target.value.toLowerCase();
        const filtered = fetchedBranches?.filter((branch: any) => branch.city.toLowerCase().includes(searchQuery));
        if (!searchQuery) {
            setFilteredData(fetchedBranches);
        }
        setFilteredData(filtered ?? fetchedBranches);
    };

    const columns: GridColDef[] = [
        {
            field: "city",
            headerName: "Branch name",
            width: 450,
            disableColumnMenu: true,
            renderCell: (row: any) => {
                return (
                    <div className="flex flex-col  gap-2">
                        <p
                            style={{
                                color: "var(--color-text)",
                            }} className="font-[500] text-[#455468] text-base">{row.row.city}</p>
                    </div>
                );
            },
        },
        {
            field: " ",
            headerName: "Credentials",
            width: 650,
            disableColumnMenu: true,
            renderCell: (row: any) => {
                return (
                    <div

                        className="flex flex-col  gap-2"
                    >
                        <p
                            style={{
                                color: "var(--color-text)",
                            }} className="font-[500]  text-[#455468] text-sm">
                            latitude: <strong>{row.row.latitude}</strong>
                        </p>
                        <p
                            style={{
                                color: "var(--color-text)",
                            }} className="font-[500]  text-[#455468] text-sm">
                            Longitude: <strong>{row.row.longitude}</strong>
                        </p>
                    </div>
                );
            },
        },
        {
            field: "_",
            headerName: "Control",
            width: 160,
            disableColumnMenu: true,
            renderCell: (row: any) => (
                <div className="d-flex gap-3 ">
                    <button
                        disabled={isLoading}
                        type="button"
                        onClick={() =>
                            setOpenEditModal({
                                isOpen: true,
                                branchInfo: row.row,
                            })
                        }
                    >
                        Edit
                    </button>
                    <button
                        disabled={isLoading}
                        type="button"
                        className="!bg-[#FFF5F4]"
                        id="Delete"
                        onClick={() => handleBranchDelete(row.row.id, row.row.city)}
                    >
                        Delete
                    </button>
                </div>
            ),
        },
    ];

    return (
        <Box sx={{ height: 790, width: "100%", color: "var(--color-text)" }}>
            {isCountriesLoading ? (
                <LoadingIndicator />
            ) : (
                <>
                    <EditModal
                        branchInfo={openEditModal.branchInfo}
                        isOpen={openEditModal.isOpen}
                        handleClose={() => setOpenEditModal({ isOpen: false, branchInfo: {} })}
                    />
                    <div className="flex flex-col gap-2 col-span-4 my-4">
                        <label className="font-bold" htmlFor="country">
                            Please select a country
                        </label>
                        <select
                            className="text-black"
                            style={{
                                width: "100%",
                                borderRadius: "10px",
                                borderBottom: "1px solid #C1C7CD",
                                background: "#f2f4f8",
                                border: "none",
                                padding: "15px 25px",
                                height: "60px",
                                marginTop: " 6px",
                            }}
                            placeholder="Country"
                            value={countryId}
                            onChange={(e) => {
                                handleChangeCountry(e);
                            }}
                        >
                            {countries?.data?.map.map((country: any) => {
                                return (
                                    <option key={country.id} value={country.id}>
                                        {country.city}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="flex items-center justify-between gap-2 my-4">
                        <div className="flex items-center gap-4">
                            <h1
                                style={{
                                    color: "var(--color-text)",
                                }} id="Teammember" className="text-[#455468] text-xl">
                                Branches
                            </h1>
                            <p className="bg-[#F0F3F9] text-[#5E718D] text-sm p-2 rounded-md">{fetchedBranches?.length} Branches</p>
                        </div>

                        <div id="search_container" className="relative">
                            <input
                                onChange={handleSearch}
                                className="p-2 rounded-lg pl-2 w-full"
                                type="text"
                                id="branch-search"
                                placeholder="Search"
                            />
                        </div>
                    </div>

                    <StyledDataGrid
                        key={countryId}
                        checkboxSelection
                        localeText={{ noRowsLabel: "Oh, seems like no data here" }}
                        disableRowSelectionOnClick
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setRowSelectionModel(newRowSelectionModel);
                        }}
                        rowSelectionModel={rowSelectionModel}
                        rowHeight={80}
                        getRowId={(row) => row?.id}
                        loading={isLoading}
                        rows={filteredData.length ? filteredData : fetchedBranches ?? []}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 15,
                                },
                            },
                        }}
                        pageSizeOptions={[15]}
                    />
                </>
            )}
        </Box>
    );
};
