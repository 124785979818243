import React, { useEffect, useState } from "react";
import RightDash from "../../../utils/Navbar/RightDash/rightDash";
import Topnav from "../../../utils/Navbar/topnav/topnav";
import { Accordion, Col, Row } from "react-bootstrap";

import img1 from "../../../Assests/images/icfgdsgsgg34on.svg";
import img2 from "../../../Assests/images/hfghfgh47hfg.png";
import img3 from "../../../Assests/images/rectanglhfghfghe-6447.png";
import img4 from "../../../Assests/images/rectangfghfg45le-6448.png";
import img5 from "../../../Assests/images/rectanglhfghfghe-6447.png";
import img6 from "../../../Assests/images/rectgdfgdf45angle-6446.png";
import img7 from "../../../Assests/images/rectanglkjhkjg8e-6445.png";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
import Cookies from "js-cookie";
import axios from "axios";

const Around = () => {
  const [ItemsDetails2343323d, setItemsDetails23] = useState([]);
  const fetchAxios = async () => {
    const res = await axios.get(
      `https://dipndipapi.mass-fluence.com/api/countries?token=${Cookies.get()._auth
      }&user_id=${JSON.parse(Cookies.get()._auth_state).id}`,
    );

    setItemsDetails23(res.data.data.countries);
  };

  useEffect(() => {
    fetchAxios();
  }, []);

  const [modalShow22, setModalShow22] = React.useState(false);
  return (
    <Col
      id="Library3424"
      className="d-flex justify-content-between align-items-start mb-5"
    >
      <div style={{ width: "85px" }}></div>

      <div id="libraryContainer453ef4">


        <div className="initial_title_Sec">
          <h1 style={{ color: "var(--color-text)" }}>
            dipndip around the world
          </h1>

          <div className="d-flex gap-3">
          </div>
        </div>

        <div className="mt-4 container m-auto ">
          <Row>
            {ItemsDetails2343323d.length >= 1 ? (
              ItemsDetails2343323d.map((item) => {
                return (
                  <Col id="testtintret3" className="col-3 g-3" key={item.id}>
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>{item.name}</Accordion.Header>
                        <Accordion.Body>{item.name}</Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                );
              })
            ) : (
              <h1 className="d-flex justify-content-center mt-5 text-redish">
                No Items
              </h1>
            )}
          </Row>
        </div>
      </div>

      <div style={{ width: "85px" }}></div>
      <div style={{ position: "fixed", right: "0" }}>

      </div>
    </Col>
  );
};

export default Around;
