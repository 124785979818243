import React, { useState } from "react";
import { useUserContext } from "../../../../../context/userContext.tsx";
import "../../library.css";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useSnackbar } from "notistack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import EditLibraryCard from "../../Editlibrary.js";

type LibraryCardProps = {
    title: string;
    image: string;
    sectionId: string;
    isFolder?: boolean;
    folderId?: string;
};
export const LibraryCard = ({ title, image, sectionId, isFolder, folderId }: LibraryCardProps) => {
    const { userId, userRole, userToken } = useUserContext();
    const [isEditOpen, setIsEditOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const updateApi = "https://dipndipapi.mass-fluence.com/api/update-section-library";
    const handleDeleteFolder = () => {
        axios
            .post("https://dipndipapi.mass-fluence.com/api/delete-section-library", {
                section_id: sectionId,
                user_id: `${userId}`,
                token: userToken,
            })
            .then((res) => {
                if (res.status === 201) {
                    enqueueSnackbar(`${title} has been deleted successfully`, { variant: "success" });
                    queryClient.invalidateQueries({ queryKey: ["titles"] });
                }
            })
            .catch((ERR) => {
                //console.log(ERR);
            });
    };

    return (
        <div style={{ position: "relative" }}>
            <EditLibraryCard
                show={isEditOpen}
                onHide={() => setIsEditOpen(false)}
                info={{ title, image, sectionId, api: updateApi }}
            />
            <Link
                to={`${isFolder
                    ? `/${userRole}/Library/${title}/folder/${sectionId}/files/${folderId}`
                    : `/${userRole}/Library/${title}/folder/${sectionId}`
                    } `}
                className="w-fit"
            >
                <div className="container_for_tex4321">
                    <img className="object-cover" alt="ERR404" src={image} />
                    <div className="into_text_5235gf">
                        <p>{title}</p>
                    </div>
                </div>
            </Link>
            {(userRole as string) === "SuperAdmin" ? (
                <div className="absolute top-4 right-4">
                    <Dropdown>
                        <Dropdown.Toggle className="!bg-none after:hidden border-none">
                            <FontAwesomeIcon icon={faEdit} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setIsEditOpen(true)}>
                                <FontAwesomeIcon className="px-2" icon={faEdit} />
                                Edit
                            </Dropdown.Item>
                            <Dropdown.Item className="text-redish" onClick={() => handleDeleteFolder()}>
                                <FontAwesomeIcon className="px-2" icon={faTrash} /> Delete
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            ) : null}
        </div>
    );
};
