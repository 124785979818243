import React from "react";
import Announcemnts from "../../../components/superPages/Announcemnts/Announcemnts";

const MainAnnouncemnts = () => {
  return (
    <div style={{ display: "flex" }} id="mainAnnounmentsContainer34">

      <Announcemnts />
    </div>
  );
};

export default MainAnnouncemnts;
