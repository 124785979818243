import React from "react";
import "../pageviws.css";
import img1 from "../../../../Assests/images/ellipsgdfgfd453e-776.svg";
import img2 from "../../../../Assests/images/ellipdsafse-777.svg";
import QuickChart from "quickchart-js";

export const UsersAnalytics = ({ item }) => {
  const qc = new QuickChart();

  qc.setConfig({
    type: "doughnut",
    data: {
      datasets: [
        {
          data: [item?.supUsers?.num, item?.admins?.num],
          backgroundColor: ["#797B7A", "#050505"],
        },
      ],
    },
    options: {
      cutoutPercentage: 70,
      plugins: {
        datalabels: {
          display: false,
        },
      },
      title: {
        display: false,
      },
    },
  });
  qc.setWidth(200).setHeight(200).setBackgroundColor("white");
  return (
    <div className="PagesViewsTemplate page page-4">
      <div className="control-section">
        <div className="d-flex ">
          <div id="chartDetails23">
            <h2>Admins & subUsers Analytics Hub</h2>
            <br />
            <div className="mt-4 pt-5">
              <div id="aboutChart">
                <img alt="ERR404" src={img1} />
                <span>{item?.admins?.percentage}% Admins</span>
              </div>

              <div id="aboutChart">
                <img alt="ERR404" src={img2} />
                <span>{item?.supUsers?.percentage}% subUsers</span>
              </div>
            </div>
          </div>
          <img className="w-[180px] h-[180px]" src={`${qc.getUrl()}`} />

        </div>
      </div>
    </div>
  );
};
