import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import uploadIcon from "../../../Assests/images/setting-super-hfjadmin-icons.svg";
import Cookies from "js-cookie";
import axios from "axios";
import { ModalTitleWithClose } from "../../shared/ModalTitle.tsx";
import { useSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import { LoadingButton } from "@mui/lab";

const EditLibraryCard = (props) => {
  const [image, setimage] = useState(props.info.image);
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [fileName, setfileName] = useState("No selected file");
  const [train, settrian] = useState({
    training_id: props.info.training_id,
    name: props.info.title,
  });
  let addtrainURL = props.info.api;
  function handle(e) {
    const newData = { ...train };
    newData[e.target.id] = e.target.value;
    settrian(newData);
  }
  const [SelectedFile, setSelectedFile] = useState(props.info.image);
  function submit(e) {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("image", SelectedFile);
    formData.append(props.isFolder ? "name" : "title", train["name"]);
    formData.append(props.isFolder ? "training_id" : "section_id", props.info.sectionId);
    formData.append("user_id", `${JSON.parse(Cookies.get("_auth_state")).id}`);
    formData.append("token", Cookies.get()._auth);
    axios
      .post(addtrainURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 201) {
          enqueueSnackbar(`${train.name} has been update to  successfully`, { variant: "success" });
          queryClient.invalidateQueries({ queryKey: ["library"] });
          queryClient.invalidateQueries({ queryKey: ["titles"] });
          queryClient.invalidateQueries({ queryKey: ["trainings"] });

          setIsLoading(false);
          props.onHide();
        }
      })
      .catch(() => {
        enqueueSnackbar(`something went wrong please try again later`, { variant: "error" });
        setIsLoading(false);
        props.onHide();
      });
  }

  return (
    <div>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header variant="danger">
          <ModalTitleWithClose onClose={props.onHide} titleText="Update card" />
        </Modal.Header>
        <Modal.Body>
          <br></br>
          <form id="" onSubmit={(e) => submit(e)}>
            <label style={{ color: "var(--color-text)" }}>New Name</label>
            <br></br>
            <input onChange={(e) => handle(e)} value={train.name} type="text" id="name" placeholder="Name..." />

            <br></br>
            <br></br>

            <div onClick={() => document.querySelector(".ann-image-field").click()} id="image-ann-uploader">
              <input
                type="file"
                accept="image/*"
                className="ann-image-field"
                hidden
                onChange={({ target: { files, value } }) => {
                  files[0] && setfileName(files[0].name);
                  if (files) {
                    setimage(URL.createObjectURL(files[0]));
                  }
                  setSelectedFile(files[0]);
                }}
                value={train.image}
                id="image"
              />

              {image ? (
                <div className="flex flex-col items-center gap-4">
                  <img src={image} alt={fileName} width={160} height={160} />
                  <p className="text-redish text-xs">
                    *Please note that, the recommended image size is width: 390px, height: 230px
                  </p>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center gap-4">
                  <img src={uploadIcon} alt={fileName} width={60} height={60} />
                  <p style={{ color: "var(--color-text)" }}>
                    Drag and drop photo or <strong>browse computer</strong>
                  </p>
                  <p className="text-redish text-xs">
                    *Please note that, the recommended image size is width: 390px, height: 230px
                  </p>
                </div>
              )}
            </div>

            <br></br>
            <LoadingButton
              loading={isLoading}
              color="error"
              variant="contained"
              className={`  !rounded-xl ${isLoading ? "!bg-gray-400" : "!bg-redish"} !capitalize py-3 px-4 my-2 `}
              type="submit"
            >
              Save Changes
            </LoadingButton>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditLibraryCard;
