import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const ActiveUsers = ({ item, offline }) => {
    return (
        <div id="personItem132" className="mt-4 flex items-center gap-2 " key={item.id}>
            {item.image == null ? (
                <FontAwesomeIcon icon={faCircleUser} style={{ color: "#798396", fontSize: "50px" }} />
            ) : (
                <img
                    src={item.image}
                    alt="ERR404"
                    style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                    }}
                />
            )}
            <div id="personDetails234">
                <div id="personnameStyling43">
                    <h6>{item.name}</h6>
                    <p>{item.occupation}</p>
                </div>

                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                    }}
                >
                    <div id="please-help" className={` w-2 h-2 rounded-full  ${offline ? "bg-gray-500" : "bg-green-500"}`} />
                    <p className="text-sm " style={{ color: offline ? "gray" : "#53E04E" }}>
                        {" "}
                        {offline ? "Offline" : "Online"}
                    </p>
                </div>
            </div>
        </div>
    );
};
