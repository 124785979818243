import React, { useState } from "react";
import DocViewer from "@cyntler/react-doc-viewer";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Document, Page } from "@react-pdf/renderer";

const myHeader = (state) => {
  if (!state.currentDocument || state.config?.header?.disableFileName) {
    return null;
  }

  return (
    <>
      <div className="bg-[#CA1E15] text-white p-4">File name: {state.currentDocument.fileName.slice(0, -4) || ""}</div>
    </>
  );
};

const Viewer = () => {
  const { file, name, type } = useParams();

  const docs = [
    {
      uri: `https://dipndipapi.mass-fluence.com/files/${file}`,
      fileType: type === "ppsx" ? "pptx" : type,
      fileName: `${name}.${type}`,
    },
  ];
  const navigate = useNavigate();
  const [pages, setPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    console.log("object:", numPages, pages);
    setPages(numPages);
    setPageNumber(1);
  }
  return (
    <div>
      {type === "jpg" ? (
        <div
          className="flex flex-col items-center justify-center mx-auto"
          style={{ height: "100vh", width: "80%", padding: "20px" }}
        >
          <div className="my-6 flex items-center gap-1 justify-start w-full">
            <ArrowLeftOutlined
              onClick={() => navigate(-1)}
              style={{
                color: "#CA1E15",
              }}
            />
            <h1
              onClick={() => navigate(-1)}
              className="text-2xl ml-2 text-redish font-semibold hover:text-redish/60 duration-300 ease-in-out hover:animate-pulse cursor-pointer"
            >
              Back to files
            </h1>
          </div>
          <div className="bg-[#CA1E15] text-white p-4 w-[500px]">File name: {name}</div>
          <img src={`https://dipndipapi.mass-fluence.com/files/${file}`} width={500} height={500} />
        </div>
      ) : type === "pdf" ? (
        <div
          className="flex flex-col items-center justify-center mx-auto"
          style={{ height: "100vh", width: "80%", padding: "20px" }}
        >
          <div className="my-6 flex items-center gap-1 justify-start w-full">
            <ArrowLeftOutlined
              onClick={() => navigate(-1)}
              style={{
                color: "#CA1E15",
              }}
            />
            <h1
              onClick={() => navigate(-1)}
              className="text-2xl ml-2 text-redish font-semibold hover:text-redish/60 duration-300 ease-in-out hover:animate-pulse cursor-pointer"
            >
              Back to files
            </h1>
          </div>
          <div className="bg-[#CA1E15] text-white p-4 w-full">File name: {name}</div>
          <iframe
            className="w-full h-full"
            src={`https://docs.google.com/viewer?url=https://dipndipapi.mass-fluence.com/files/${file}&embedded=true`}
            frameborder="0"
          ></iframe>{" "}
        </div>
      ) : (
        <div
          className="flex flex-col items-center justify-center mx-auto"
          style={{ height: "100vh", width: "80%", padding: "20px" }}
        >
          <div className="my-6 flex items-center gap-1 justify-start w-full">
            <ArrowLeftOutlined
              onClick={() => navigate(-1)}
              style={{
                color: "#CA1E15",
              }}
            />
            <h1
              onClick={() => navigate(-1)}
              className="text-2xl ml-2 text-redish font-semibold hover:text-redish/60 duration-300 ease-in-out hover:animate-pulse cursor-pointer"
            >
              Back to files
            </h1>
          </div>
          <DocViewer
            style={{
              width: "20%",
            }}
            config={{
              header: {
                overrideComponent: myHeader,
              },
            }}
            className="my-doc-viewer-style"
            documents={docs}
            theme={{
              primary: "#CA1E15",
              secondary: "#ffffff",
              tertiary: "#CA1E1599",
              text_primary: "#ffffff",
              text_secondary: "#CA1E15",
              text_tertiary: "#00000099",
              disableThemeScrollbar: false,
              textPrimary: "#FFFFFF",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Viewer;
