import React, { useEffect, useState } from "react";
import { Card, Col, Dropdown, Row } from "react-bootstrap";
import "../../../trianing/files.css";
import { Link, useParams } from "react-router-dom";
import threepoints from "../../../../../Assests/images/frame-170fsdf7478168.png";
import axios from "axios";
import Cookies from "js-cookie";
import EditFile from "../../../trianing/editFile.js";
import Addtrain from "../../../trianing/AddFile.js";
import { useUserContext } from "../../../../../context/userContext.tsx";
import { GetData } from "../../../../../api/index.tsx";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { LoadingIndicator } from "../../../../shared/LoadingIndicator/index.tsx";
import AddFile from "../../../trianing/AddFile.js";
import { useSnackbar } from "notistack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumbs, Link as HypeLink } from "@mui/material";

const FilesPage = () => {
    const { trainingId, name } = useParams();
    const [fileInfo, setFileInfo] = useState({});
    const [isAddFileOpen, setIsAddFileOpen] = useState(false);
    const [isEditFileOpen, setIsEditFileOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const { userId, userToken, userRole } = useUserContext();
    const { data: files, isLoading } = useQuery({
        queryKey: ["training-files", trainingId],
        queryFn: () => GetData("training-files", userToken as string, userId as string, `training_id=${trainingId}`),
    });

    const handleFileDelete = (id: string, name) => {
        axios
            .post(`https://dipndipapi.mass-fluence.com/api/delete-file?token=${userToken}&user_id=${userId}`, {
                file_id: id,
            })
            .then((res) => {
                if (res.status === 201) {
                    enqueueSnackbar(`${name} has been deleted successfully`, { variant: "success" });
                    queryClient.invalidateQueries({ queryKey: ["training-files"] });
                } else {
                    enqueueSnackbar(`something went wrong, Please try again later`, { variant: "error" });
                }
            });
    };

    return (
        <Col id="Library3424" className="d-flex justify-content-between align-items-start mb-5">
            <div style={{ width: "100px" }}></div>
            <div id="inialTrainingContainer34r">
                <div className="initial_title_Sec">
                    <Breadcrumbs maxItems={2} aria-label="breadcrumb">
                        <HypeLink underline="hover" color="inherit" href="/">
                            Home
                        </HypeLink>
                        <HypeLink underline="hover" color="inherit" href={`/${userRole}/Library/${userId}`}>
                            Library
                        </HypeLink>
                        <div className="flex items-center">
                            <h1 className="initial_title_Sec" style={{ color: "var(--color-text)" }}>
                                {name}
                            </h1>
                        </div>
                    </Breadcrumbs>
                    <div className="d-flex gap-3">
                        <button id="add_file_initial" onClick={() => setIsAddFileOpen(true)}>
                            <FontAwesomeIcon icon={faAdd} className="w-4 h-4 font-bold" />
                        </button>{" "}
                        <AddFile show={isAddFileOpen} onHide={() => setIsAddFileOpen(false)} training_id={trainingId} />
                    </div>
                </div>
                <Row id="" className="flex flex-wrap gap-4 justify-center my-8 items-start">
                    {isLoading ? (
                        <div className="w-screen h-screen">
                            <LoadingIndicator />
                        </div>
                    ) : files?.data.length ? (
                        files?.data?.map((item, index) => {
                            const text = item?.file;
                            const result = text?.substr(42);
                            return (
                                <div key={item.id} className="w-[400px]">
                                    <Card
                                        style={{
                                            background: "var(--color-frame)",
                                            borderRadius: "25px",
                                            padding: "5px 5px 10px",
                                        }}
                                    >
                                        <a href={`/Viewer/${result}/${item.file_type}/${item.name}`}>
                                            <img
                                                alt="ERR404"
                                                style={{ borderRadius: "25px", height: "230px", width: "100%" }}
                                                src={item.image}
                                            />
                                        </a>
                                        <div id="threepoingdgdts43">
                                            <Dropdown>
                                                <Dropdown.Toggle>
                                                    <img alt="ERR404" src={threepoints} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            setIsEditFileOpen(true);
                                                            setFileInfo(item);
                                                        }}
                                                    >
                                                        Edit
                                                    </Dropdown.Item>
                                                    <EditFile
                                                        initialData={fileInfo}
                                                        idname={item?.id}
                                                        show={isEditFileOpen}
                                                        training_id={trainingId}
                                                        onHide={() => setIsEditFileOpen(false)}
                                                    />

                                                    <Dropdown.Item
                                                        href={`https://dipndipapi.mass-fluence.com/api/download-file?user_id=${userId}&file_id=${item.id}`}
                                                        style={{ textDecoration: "none!important" }}
                                                    >
                                                        Download
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleFileDelete(item.id, item.name)}>Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div id="organizeCardDetail">
                                            <div className="d-flex justify-content-between">
                                                <h5 id="cardTit4343gdfgf4">{item?.name}</h5>
                                            </div>
                                            <a
                                                className="w-fit"
                                                href={`https://dipndipapi.mass-fluence.com/api/download-file?user_id=${userId}&file_id=${item.id}`}
                                                id="card_btn_initial22"
                                            >
                                                Download
                                            </a>
                                        </div>
                                    </Card>
                                </div>
                            );
                        })
                    ) : (
                        <p className="text-2xl text-redish font-semibold flex items-center justify-center w-full h-[80vh]">
                            No files here to show
                        </p>
                    )}
                </Row>
            </div>
            <div style={{ width: "85px" }}></div>
            <div style={{ position: "fixed", right: "0" }}></div>
        </Col>
    );
};

export default FilesPage;
