import axios from "axios";

export async function GetData(apiName: string, token: string, userId: string, queries?: string) {
    const response = await axios.get(
        `https://dipndipapi.mass-fluence.com/api/${apiName}?token=${token}&&user_id=${userId}&${queries ?? ""}`
    );
    return response.data;
}

export async function PostData(apiName: string, token: string, userId: string, body) {
    const response = await axios.post(
        `https://dipndipapi.mass-fluence.com/api/${apiName}?token=${token}&user_id=${userId}`,
        { ...body }
    );
    return response.data;
}
