import React from "react";
import Profile from "../../../components/superPages/Profile/Profile";

const MainProfile = () => {
  return (
    <div id="mainContainerForProfile34310">
      <Profile />
    </div>
  );
};

export default MainProfile;
