import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import sendIcon from "../../../Assests/images/frame-67810-sgsdg-25-gs.png";
import activeIcon from "../../../Assests/images/ellipfsdfdsse-760.svg";
import { Dropdown, Modal } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import dropdownIcon from "../../../Assests/images/vecfsd32345tor.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import Editetraining from "./editetraining";
import { IconButton } from "@mui/material";
import { CloseCircleFilled } from "@ant-design/icons";

const MessengerBox = (props) => {
  const [modalShow33, setModalShow33] = React.useState(false);

  const messagesEndRef2 = useRef(null);
  useEffect(() => {
    if (messagesEndRef2 && messagesEndRef2.current) {
      const element = messagesEndRef2.current;
      element.scroll({
        top: element.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }
  });

  const any3332 = useRef();
  const [message, setmessage] = useState();
  const [messageStatus, setMessageStatus] = useState(null);

  const fetchMessagesStatus = async () => {
    const res = await axios.get(
      `https://dipndipapi.mass-fluence.com/api/message-status?user_id=${JSON.parse(Cookies.get()._auth_state).id}`
    );

    setMessageStatus(Boolean(res.data.data.status));
  };

  useEffect(() => {
    fetchMessagesStatus();
  }, []);

  function handle(e) {
    setmessage(e.target.value);
  }

  function submit(e) {
    fetchAxios2();
    e.preventDefault();

    const formData = new FormData();
    formData.append("message", message);
    formData.append("room_id", props.RoomId);
    formData.append("user_id", `${JSON.parse(Cookies.get("_auth_state")).id}`);
    axios
      .post("https://dipndipapi.mass-fluence.com/api/send-message", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        any3332.current.value = "";
      });
  }

  const fetchAxios = async () => {
    await axios.get(
      `https://dipndipapi.mass-fluence.com/api/rooms?user_id=${JSON.parse(Cookies.get()._auth_state).id}`
    );
  };

  useEffect(() => {
    fetchAxios();
  }, []);

  const [ItemsDetails23431, setItemsDetails231] = useState([]);

  const fetchAxios2 = async () => {
    const res = await axios.get(
      `https://dipndipapi.mass-fluence.com/api/room-messages?room_id=${props.RoomId}&user_id=${JSON.parse(Cookies.get()._auth_state).id
      }`
    );

    setItemsDetails231(res.data.data.messages);
  };

  useEffect(() => {
    fetchAxios2();
  }, [props.RoomId, message]);

  let deleterainURL = "https://dipndipapi.mass-fluence.com/api/delete-message";

  function handelDelete(id) {
    fetchAxios2();
    axios
      .post(deleterainURL, {
        message_id: id,
        room_id: props.RoomId,
        user_id: `${JSON.parse(Cookies.get("_auth_state")).id}`,
      })
      .then(() => {
        // setShow(true)
      })
      .catch(() => {
        alert("An Error");
      });
  }

  const [usersimage, setusersimage] = useState([]);
  useEffect(() => {
    setusersimage(JSON.parse(Cookies.get("_auth_state")).image);
  }, []);


  return (
    <Modal backdropClassName="messenger-backdrop" className="test" {...props} size="lg" centered>
      <div id="MessegeBox32" className="!bg-white">
        <Modal.Header variant="danger" className={`${!messageStatus ? "opacity-30" : "opacity-100"}`}>
          <Modal.Title style={{ color: "var(--color-text)" }} id="contained-modal-title-vcenter">
            {props.personImage ? (
              <div style={{ display: "flex", gap: "10px" }}>
                {props.personImage !== "null" ? (
                  <img
                    src={props.personImage}
                    alt="ERR404"
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <FontAwesomeIcon icon={faCircleUser} style={{ color: "#798396", fontSize: "50px" }} />
                )}

                <div id="nameStateAdmin">
                  <div className="flex items-center justify-between w-full">
                    <div>
                      <h6>{props.personName}</h6>
                      <div className="d-flex">
                        <strong>{props.personstatus == 1 ? <strong>Active</strong> : <strong>OFF</strong>} </strong>
                        <p>
                          {props.personlocation} {props.personoccupation}
                        </p>
                      </div>
                    </div>
                    <IconButton className="!z-50" onClick={() => props.onHide()} color="error">
                      <CloseCircleFilled />
                    </IconButton>
                  </div>
                </div>
              </div>
            ) : null}
          </Modal.Title>
        </Modal.Header>

        <div id="messegeContainerTextbox" ref={messagesEndRef2}>
          {!messageStatus ? (
            <div className="absolute w-full h-full left-0 top-0 z-20 rounded-lg bg-black/40">
              <p className="flex items-center justify-center p-2 h-full text-2xl text-white">Messaging is disabled</p>
            </div>
          ) : null}

          <Modal.Body className={`${!messageStatus ? "opacity-30" : "opacity-100"}`}>
            {ItemsDetails23431.length >= 1 ? (
              ItemsDetails23431.map((item) => {
                return (
                  <div key={item.id}>
                    {item.user_sender.id === JSON.parse(Cookies.get()._auth_state).id ? (
                      <div id="friendMessegebox" className="mb-2">
                        <div id="img_editing22box">
                          {usersimage ? (
                            <img
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                              }}
                              src={usersimage}
                              alt="ERR404"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faCircleUser}
                              className="mb-2 pb-4 ms-3"
                              style={{ color: "#798396", fontSize: "45px" }}
                            />
                          )}
                          <img src={activeIcon} alt="ERR404" />
                        </div>

                        <div id="nameStateAdmin22">
                          <div>
                            <div id="timeDropDown">
                              <p>{item.date}</p>
                              <Dropdown>
                                <Dropdown.Toggle>
                                  <img src={dropdownIcon} alt="ERR404" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => {
                                      fetchAxios2();
                                      handelDelete(item.id);
                                    }}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      fetchAxios2();
                                      setModalShow33(true);
                                    }}
                                  >
                                    Edit
                                  </Dropdown.Item>
                                  <Editetraining
                                    show={modalShow33}
                                    onHide={() => setModalShow33(false)}
                                    message_id={item.id}
                                    room_id={props.RoomId}
                                  />
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            <div id="messegeText22box">
                              <p>{item.message}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div id="myMessegebox" className=" mt-2">
                        <div id="img_editing22box">
                          {props.personImage !== "null" ? (
                            <img
                              src={props.personImage}
                              alt="ERR404"
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                              }}
                            />
                          ) : (
                            <FontAwesomeIcon icon={faCircleUser} style={{ color: "#798396", fontSize: "50px" }} />
                          )}
                          <img src={activeIcon} alt="ERR404" />
                        </div>

                        <div id="nameStateAdmin22">
                          <div>
                            <div id="timeDropDown">
                              <p>{item.date}</p>
                              <Dropdown>
                                <Dropdown.Toggle>
                                  <img src={dropdownIcon} alt="ERR404" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => {
                                      fetchAxios2();
                                      handelDelete(item.id);
                                    }}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            <div id="messegeTextbox">
                              <p>{item.message}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <h1 className="d-flex justify-content-center mt-5 pt-5 text-redish">No Message Yet</h1>
            )}
          </Modal.Body>
        </div>

        {props.personImage ? (
          <div id="sendInputbox">
            <input
              type="text"
              placeholder="Type something..."
              style={{ color: "var(--color-text)" }}
              onChange={(e) => handle(e)}
              ref={any3332}
            />
            <div id="sendingiconsbox">
              <Link
                className={`${!messageStatus ? "opacity-30" : "opacity-100"}`}
                onClick={(e) => {
                  fetchAxios2();
                  submit(e);
                }}
              >
                <img alt="ERR$04" src={sendIcon} />
              </Link>
            </div>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default MessengerBox;
