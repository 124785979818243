import React, { useMemo, useState } from "react";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import itLocale from "i18n-iso-countries/langs/it.json";
import { useUserContext } from "../../../../context/userContext.tsx";
import { GetData } from "../../../../api/index.tsx";
import { useQuery } from "@tanstack/react-query";
import { AddCountry } from "./AddCountry.tsx";
import { AddBranch } from "./AddBranch.tsx";
import { LoadingIndicator } from "../../../shared/LoadingIndicator/index.tsx";

export const Maps = () => {
    const { userId, userToken } = useUserContext();
    const { data: countriesData, isLoading } = useQuery({
        queryKey: ["homepage"],
        queryFn: () => GetData("homepage", userToken as string, userId as string),
    });

    countries.registerLocale(enLocale);
    countries.registerLocale(itLocale);

    const countryObj = countries.getNames("en", { select: "official" });

    const countryArr = Object.entries(countryObj).map(([key, value]) => {
        return {
            label: value,
            value: key,
        };
    });

    const mapCountries = useMemo(() => {
        return countriesData?.data?.map;
    }, [countriesData?.data?.map]);

    return (
        <div>
            {isLoading ? (
                <LoadingIndicator />
            ) : (
                <>
                    <AddCountry countryArr={countryArr} mapCountries={mapCountries} />
                    <AddBranch mapCountries={mapCountries} />
                </>
            )}
        </div>
    );
};
