import React, { useContext, useEffect, useMemo, useState } from "react";
import "./Profile.css";
import RightDash from "../../../utils/Navbar/RightDash/rightDash";
import { Col, Row } from "react-bootstrap";
import Topnav from "../../../utils/Navbar/topnav/topnav";

import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { setOptions, localeAr } from "@mobiscroll/react";

import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { MenuItem, Select } from "@mui/material";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import itLocale from "i18n-iso-countries/langs/it.json";
import axios, { Axios } from "axios";
import Cookies from "js-cookie";
import { useSignIn } from "react-auth-kit";
import { Personscontext } from "../../../context/context";
import { LoadingButton } from "@mui/lab";

setOptions({
  locale: localeAr,
  theme: "ios",
  themeVariant: "light",
});

const Profile = () => {
  const signIn = useSignIn();
  const { ItemsDetails2343 } = useContext(Personscontext);
  const [isLoading, setIsLoading] = useState(false);
  const updatedUserImage = useMemo(() => {
    return ItemsDetails2343?.[0]?.user_one.image;
  }, [ItemsDetails2343]);
  const [image, setImage] = useState({
    preview: updatedUserImage,
  });
  const [fileName, setfileName] = useState("No selected file");

  const handleChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
      });
    }

    e.target.files[0] && setfileName(e.target.files[0].name);

    setSelectedFile(e.target.files[0]);
  };

  const [selectedCountry, setSelectedCountry] = useState("");
  useEffect(() => {
    setSelectedCountry(`${JSON.parse(Cookies.get()._auth_state).locatin}`);
    if (ItemsDetails2343.length) {
      setImage({
        preview: updatedUserImage,
      });
    }
  }, [ItemsDetails2343, updatedUserImage]);
  const selectCountryHandler = (value) => setSelectedCountry(value);

  countries.registerLocale(enLocale);
  countries.registerLocale(itLocale);

  const countryObj = countries.getNames("en", { select: "official" });

  const countryArr = Object.entries(countryObj).map(([key, value]) => {
    return {
      label: value,
      value: key,
    };
  });

  const [profile, setprofile] = useState({
    nameinput434: `${JSON.parse(Cookies.get()._auth_state).name}`,
    Emailinput352: `${JSON.parse(Cookies.get()._auth_state).email}`,
    location: `${JSON.parse(Cookies.get()._auth_state).locatin}`,
  });
  let addtrainURL = "https://dipndipapi.mass-fluence.com/api/update-profile";

  function handle(e) {
    const newData = { ...profile };
    newData[e.target.id] = e.target.value;
    setprofile(newData);
  }

  const [SelectedFile, setSelectedFile] = useState();

  const handleSubmit = (e) => {
    e?.preventDefault();
    const formData = new FormData();
    formData.append("image", SelectedFile);
    formData.append("name", profile["nameinput434"]);
    formData.append("email", profile["Emailinput352"]);
    formData.append("location", selectedCountry);
    formData.append("user_id", `${JSON.parse(Cookies.get("_auth_state")).id}`);
    formData.append("token", Cookies.get()._auth);

    axios
      .post(addtrainURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        onSubmit22();
        setInterval(() => {
          window.location.reload();
        }, 500);
      })
      .catch((ERR) => {
        setIsLoading(false);
        window.alert("TYPE ALL INPUTS");
      });
  };

  const onSubmit22 = async (values) => {
    try {
      const response = await axios.post("https://dipndipapi.mass-fluence.com/api/login", {
        email: profile["Emailinput352"],
        password: `${JSON.parse(Cookies.get()._auth_state).password}`,
      });
      signIn({
        token: response.data.authorization.token,
        expiresIn: 3222222600,
        tokenType: "Bearer",
        authState: {
          email: profile["Emailinput352"],
          route: response.data.user.occupation.replace(/\s/g, ""),
          id: response.data.user.id,
          name: profile["nameinput434"],
          occupation: response.data.user.occupation,
          image: `${image.preview}`,
          locatin: selectedCountry,
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <Col id="manuals423453" className="d-flex justify-content-between align-items-start mb-5">
      <div style={{ width: "85px" }}></div>

      <div id="ContainerForprofile2323">
        <h1 id="ProfileTit354">Profile</h1>

        <br></br>
        <br></br>

        <form onSubmit={(e) => handleSubmit(e)}>
          <Row>
            <Col className="mt-2 col-lg-6 col-5">
              <div>
                <label className="d-flex justify-content-center">
                  {image.preview ? (
                    <img id="uploadingImg" src={image.preview} alt="ERR404" />
                  ) : (
                    <div id="uploadingImg" alt="ERR404"></div>
                  )}
                </label>

                <input type="file" id="upload-button" style={{ display: "none" }} onChange={handleChange} />
                <br />
                <div id="customizeButtons432">
                  <label id="uploadBtnImg345" htmlFor="upload-button">
                    Upload New Picture
                  </label>

                  <button
                    id="DeletImgButton"
                    onClick={() => {
                      setImage(<div id="uploadingImg" alt="ERR404"></div>);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </Col>

            <Col className="mt-2 col-lg-6 col-7">
              <div id="formEditing434">
                <h4>User Details</h4>
                <br />
                <label htmlFor="nameinput434">Name</label> <br></br>
                <input
                  id="nameinput434"
                  className="mt-1"
                  type="text"
                  onChange={(e) => handle(e)}
                  value={profile.nameinput434}
                  placeholder="Placeholder"
                />
                <br></br>
                <label htmlFor="Emailinput352">Email</label> <br></br>
                <input
                  id="Emailinput352"
                  className="mt-1"
                  type="email"
                  onChange={(e) => handle(e)}
                  value={profile.Emailinput352}
                  placeholder="Placeholder"
                />
                <br></br>
                <label className="mb-2" htmlFor="Location6346">
                  Location
                </label>
                <Select
                  style={{
                    marginTop: " 6px",
                    borderRadius: "10px",
                    background: "var(--color-frame)",
                    display: "flex",
                    height: "48px",
                    padding: "12px 16px",
                    alignItems: " center",
                    gap: "8px",
                    alignSelf: "stretch",
                    border: "none",
                    borderBottom: "1px solid  #C1C7CD",
                    width: "100%",
                    color: "var(--color-text)",
                  }}
                  id="rewt436htrh5gdff"
                  value={selectedCountry}
                  onChange={(e) => {
                    selectCountryHandler(e.target.value);
                    handle(e);
                  }}
                  placeholder="Region"
                >
                  {!!countryArr?.length &&
                    countryArr.map(({ label, value }) => (
                      <MenuItem
                        style={{
                          background: "var(--color-background)",
                          color: "var(--color-text)",
                        }}
                        key={label}
                        value={label}
                      >
                        {label}
                      </MenuItem>
                    ))}
                </Select>
                <LoadingButton
                  onClick={() => {
                    setIsLoading(true);
                    handleSubmit();
                  }}
                  loading={isLoading}
                  color="error"
                  variant="contained"
                  className="!rounded-lg !bg-redish !capitalize py-2 px-4 my-4"
                  type="submit"
                >
                  Update Profile
                </LoadingButton>
              </div>
            </Col>
          </Row>
        </form>
      </div>

      <div style={{ width: "85px" }}></div>
      <div style={{ position: "fixed", right: "0" }}></div>
    </Col>
  );
};

export default Profile;
