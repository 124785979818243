import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";

const WelcomeEmail = () => {
  const [ItemsDetails2343, setItemsDetails23] = useState([]);
  const { enqueueSnackbar } = useSnackbar()

  const fetchAxios = async () => {
    const res = await axios.get(
      `https://dipndipapi.mass-fluence.com/api/welcome-message?user_id=${JSON.parse(Cookies.get()._auth_state).id
      }`,
    );

    setItemsDetails23(res.data.data);
  };
  useEffect(() => {
    fetchAxios();
  }, []);


  const [index, setindex] = useState();

  const [inputsvalues, setinputsvalues] = useState({
    tempnamevalue: "",
    subjectvalue: "",
    messvalue: "",
  });

  function handle(e) {
    const newData = { ...inputsvalues };
    newData[e.target.id] = e.target.value;
    setinputsvalues(newData);
  }

  let addtrainURL = "https://dipndipapi.mass-fluence.com/api/welcome-message";
  function submit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("message_id", index);
    formData.append("template_name", inputsvalues.tempnamevalue);
    formData.append("subject", inputsvalues.subjectvalue);
    formData.append("message", inputsvalues.messvalue);
    formData.append("user_id", `${JSON.parse(Cookies.get("_auth_state")).id}`);

    axios
      .post(addtrainURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 201) {
          enqueueSnackbar('Updated Successfully', { variant: 'success' })
        }
      })
      .catch((err) => {
        enqueueSnackbar('Something went wrong, please try again', { variant: 'error' })

      });
  }

  return (
    <div>
      <form id="formContainer4243" className="flex flex-col gap-4 mt-0" onSubmit={(e) => submit(e)}>
        <h4 className="font-bold">Template</h4>
        <div id="threeBtnWel" className="flex flex-row items-center gap-2 ju">
          <button
            type="button"
            onClick={() => {
              setinputsvalues({
                tempnamevalue: ItemsDetails2343[0].template_name,
                subjectvalue: ItemsDetails2343[0].subject,
                messvalue: ItemsDetails2343[0].message,
              });
              setindex(1);
            }}
          >
            Super Admin
          </button>

          <button
            type="button"
            onClick={() => {
              setinputsvalues({
                tempnamevalue: ItemsDetails2343[1].template_name,
                subjectvalue: ItemsDetails2343[1].subject,
                messvalue: ItemsDetails2343[1].message,
              });
              setindex(2);
            }}
          >
            Admin
          </button>
          <button
            type="button"
            onClick={() => {
              setinputsvalues({
                tempnamevalue: ItemsDetails2343[2].template_name,
                subjectvalue: ItemsDetails2343[2].subject,
                messvalue: ItemsDetails2343[2].message,
              });
              setindex(3);
            }}
          >
            Subuser
          </button>
        </div>
        <div id="inputContainer43" className="flex flex-col gap-2">
          <label className='form-label' htmlFor="tempnamevalue">Template Name</label>
          <input
            required
            onChange={(e) => handle(e)}
            value={inputsvalues.tempnamevalue}
            type="text"
            id="tempnamevalue"
            style={{ color: "var(--color-text)" }}
            placeholder="Template Name...."
          />
          <label className='form-label' htmlFor="subjectvalue">Email Subject</label>
          <input
            required
            onChange={(e) => handle(e)}
            value={inputsvalues.subjectvalue}
            id="subjectvalue"
            type="text"
            style={{ color: "var(--color-text)" }}
            placeholder="Email Subject"
          />
          <label className='form-label' htmlFor="messvalue">Message</label>
          <textarea
            required
            onChange={(e) => handle(e)}
            value={inputsvalues.messvalue}
            id="messvalue"
            type="textArea"
            style={{
              color: "var(--color-text)",
              width: "100%",
              backgroundColor: "#F4F4F4",
              borderRadius: "8px",
            }}
            placeholder="Message...."
          />
        </div>
        <div className="flex items-center gap-4">
          <button id="saveBtn353">Save and Close</button>
        </div>
      </form>
    </div>
  );
};

export default WelcomeEmail;
