import React from "react";
import Files from "../../../components/AdminPages/trianing/files";

const AdminMainFiles = () => {
  return (
    <div id="mainintailTrainContainer343" style={{ display: "flex" }}>

      <Files />
    </div>
  );
};

export default AdminMainFiles;
