import { FC, PropsWithChildren, useMemo } from "react";
import { useUserContext } from "../../context/userContext.tsx";

export type Role = "SuperAdmin" | "Subuser" | "Admin";
export type Mode = "all" | "some" | "exclude" | undefined;

type Condition = {
    allowedRoles?: Role[];
};

export const ProtectedByRole: FC<PropsWithChildren & { condition?: Condition; mode?: Mode }> = ({
    children,
    condition,
    mode = "some",
}) => {
    const { userRole } = useUserContext();
    const hasAccess = useMemo(() => {
        if (mode === "all") {
            return condition?.allowedRoles?.includes(userRole);
        } else if (mode === "some") {
            return condition?.allowedRoles?.includes(userRole);
        } else if (mode === "exclude") {
            return !condition?.allowedRoles?.includes(userRole);
        }
        return false;
    }, [condition?.allowedRoles, mode, userRole]);

    if (!condition) return children;

    return hasAccess ? children : null;
};
