import { Navigate, Outlet } from "react-router-dom";
import React from "react";
import Cookies from "js-cookie";
import { Sidebar } from "./utils/Navbar/SuperLeftDash/Sidebar.tsx";
import Topnav from "./utils/Navbar/topnav/topnav.js";
import RightDash from "./utils/Navbar/RightDash/rightDash.js";

const PrivateRoutes1 = () => {
  return JSON.parse(Cookies.get()._auth_state).route === "Subuser" ? (
    <div className="relative">
      <Topnav />
      <div className="fixed top-0 z-50">
        <Sidebar />
      </div>
      <div className="fixed top-0 z-50 right-0 z-50">
        <RightDash />
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoutes1;
