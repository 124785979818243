import React from "react";
import locationImg from "../../../../Assests/images/locationImg.png";

export const DeviceLocations = ({ item }) => {
  return (
    <div id="deviceItem132" key={item.id} >
      <img src={locationImg} alt="ERR404" />
      <div id="deviceDetails234">
        <div id="deviceLocation234">
          <h6>{item.name}</h6>
          <div className="flex items-center !text-gray-500">
            <p className="!text-xs"> {item.location}</p>,<p className="!text-xs"> {item.last_login}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
