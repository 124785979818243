import React, { useEffect, useState } from "react";

import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { Link } from "react-router-dom";
import Axios from "axios";
import { MenuItem, Select } from "@mui/material";

import countries from "i18n-iso-countries";

import enLocale from "i18n-iso-countries/langs/en.json";
import itLocale from "i18n-iso-countries/langs/it.json";

import Alert from "react-bootstrap/Alert";

import Cookies from "js-cookie";
import {
  UppercaseLetters,
  LowercaseLetters,
  Symbols,
  Numbers,
} from "./Characters";

const AccountDetail = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState("");

  const selectCountryHandler = (value) => setSelectedCountry(value);

  countries.registerLocale(enLocale);
  countries.registerLocale(itLocale);

  const countryObj = countries.getNames("en", { select: "official" });

  const countryArr = Object.entries(countryObj).map(([key, value]) => {
    return {
      label: value,
      value: key,
    };
  });

  // const [pass, setpass] = useState()
  // useEffect(() => {
  // setpass(password11)
  // })
  // console.log(pass);
  const [register, setregister] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    location: "",
    Occupation: "",
  });

  let registerURL = `https://dipndipapi.mass-fluence.com/api/register?token=${
    Cookies.get()._auth
  }&user_id=${JSON.parse(Cookies.get()._auth_state).id}`;

  function handle(e) {
    const newData = { ...register };
    newData[e.target.id] = e.target.value;
    setregister(newData);
    // console.log(newData);
  }
  function handlelocation(e) {
    const newData = { ...register };
    newData["location"] = e.target.value;
    setregister(newData);
    // console.log(newData);
  }

  const [response, setresponse] = useState();
  function submit(e) {
    e.preventDefault();

    Axios.post(registerURL, {
      firstName: register.firstName,
      lastName: register.lastName,
      email: register.email,
      password: register.password,
      location: register.location,
      Occupation: register.Occupation,
    })
      .then((res) => {
        setShow(true);
        setTimeout(() => {
          setShow(false);
        }, 4000);

        // setInterval(()=>{
        //   window.location.reload()
        // },600)
      })
      .catch((ERR) => {
        setShow2(true);
        setTimeout(() => {
          setShow2(false);
        }, 4000);
      });
  }

  // gen pass
  const [password11, setPassword11] = useState();
  const [strength, setStrength] = useState(20);

  const handlePassword = (e) => {
    let characterList = "";

    characterList += UppercaseLetters;

    characterList += LowercaseLetters;

    characterList += Numbers;

    characterList += Symbols;

    setPassword11(createPassword(characterList));
    setregister({
      firstName: "",
      lastName: "",
      email: "",
      password: password11,
      location: "",
      Occupation: "",
    });
  };

  const createPassword = (characterList) => {
    let password = "";
    const CharacterListLength = characterList.length;

    for (let i = 0; i < strength; i++) {
      const characterIndex = Math.floor(Math.random() * CharacterListLength);
      password += characterList.charAt(characterIndex);
    }

    return password;
  };
  //

  return (
    <div>
      <form
        onSubmit={(e) => submit(e)}
        id="formContainer4243"
        className="fwrer4235twee"
      >
        <h4 id="titrer343">User Details</h4>

        <div id="inputContainer43">
          <div id="flexingInputs">
            <div>
              <label htmlFor="firstName">First Name</label> <br></br>
              <input
                onChange={(e) => handle(e)}
                value={register.firstName}
                type="text"
                id="firstName"
                style={{ color: "var(--color-text)" }}
                placeholder="First Name"
              />
            </div>
            <div>
              <label htmlFor="lastName">Last Name</label> <br></br>
              <input
                onChange={(e) => handle(e)}
                value={register.lastName}
                id="lastName"
                style={{ color: "var(--color-text)" }}
                placeholder="Last Name"
              />
            </div>
          </div>
          <br></br>
          <label htmlFor="email">Email</label> <br></br>
          <input
            onChange={(e) => handle(e)}
            value={register.email}
            type="email"
            style={{ color: "var(--color-text)" }}
            id="email"
            placeholder="Email"
          />
          <br></br>
          <br></br>
          <label htmlFor="password">Password</label> <br></br>
          <input
            onChange={(e) => {
              handle(e);
            }}
            value={register.password}
            type="text"
            id="password"
            style={{ color: "var(--color-text)" }}
            placeholder="Password"
          />
          {/* <br></br> */}
          {/* <label htmlFor='Confirm'>Confirm Password</label> <br></br>
          <input 
            type='password' id='Confirm'
            style={{color:"var(--color-text)"}}
             placeholder='Confirm Password'/> */}
          {/* 
             <button onClick={handlePassword} type='button' id='GeneratePassword' className="generator_btn">
             Generate password
            </button> */}
        </div>
        <br></br>

        <div id="RegionContainer">
          <label className="mb-2" htmlFor="location">
            Region
          </label>

          <Select
            style={{
              width: "100%",
              borderRadius: "10px",
              borderBottom: "1px solid #C1C7CD",
              background: "var(--color-background)",
              border: "none",
              height: "48px",
              padding: "12px 16px",
              marginTop: " 6px",
              color: "var(--color-text)",
            }}
            value={selectedCountry}
            onChange={(e) => {
              selectCountryHandler(e.target.value);
              handlelocation(e);
            }}
            placeholder="Region"
          >
            {!!countryArr?.length &&
              countryArr.map(({ label, value }) => (
                <MenuItem
                  style={{
                    background: "var(--color-background)",
                    color: "var(--color-text)",
                  }}
                  key={value}
                  value={value}
                >
                  {label}
                </MenuItem>
              ))}
          </Select>
        </div>
        <br></br>

        <div id="lastInput434">
          <select
            onChange={(e) => handle(e)}
            value={register.Occupation}
            id="Occupation"
            className="form-select"
            aria-label="Default select example"
          >
            <option defaultValue>Occupation</option>
            <option value="Super Admin">Super Admin</option>
            <option value="Admin">Admin</option>
            <option value="Subuser">Subuser</option>
          </select>
        </div>
        <br></br>
        <div id="AddBtnContainer34">
          <button type="submit">Add</button>
        </div>
      </form>

      <Alert id="mainContainerForalert" show={show} variant="success">
        <div className="alertEditing">
          <div>Successfully Saved.</div>
          <button
            onClick={() => {
              setShow(false);
            }}
            className="btn-close"
          ></button>
        </div>
      </Alert>

      <Alert id="mainContainerForalert" show={show2} variant="danger">
        <div className="alertEditing">
          <div>Failed Saved.</div>
          <button
            onClick={() => {
              setShow2(false);
            }}
            className="btn-close"
          ></button>
        </div>
      </Alert>
    </div>
  );
};

export default AccountDetail;
