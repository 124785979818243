import { Navigate, Outlet } from "react-router-dom";
import React from "react";
import Cookies from "js-cookie";

const PrivateRoutes = () => {
  return !Cookies.get("_auth_state") ? (
    <Outlet />
  ) : (
    <Navigate
      to={`${JSON.parse(Cookies.get()._auth_state).route}/${
        JSON.parse(Cookies.get()._auth_state).id
      }`}
    />
  );
};

export default PrivateRoutes;
