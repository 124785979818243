import logoDipndip from "../../Assests/images/dipndip-logdo-r-onblack-rgb-1-fsdgfsdgsd-32-r.png";

import React, { useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import OtpInput from "react-otp-input";
import axios from "axios";

const SecurityCode = () => {
  const { email } = useParams();

  const [otp, setOtp] = useState("");

  const navigate = useNavigate();

  let addtrainURL = "https://dipndipapi.mass-fluence.com/api/send-code";

  function submit(e) {
    e.preventDefault();

    const formData = new FormData();

    formData.append("email", email);
    formData.append("code", otp);

    axios
      .post(addtrainURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        window.alert(res.data.msg);
        navigate(`/NewPassword/${email}/${otp}`);
      })
      .catch((ERR) => {
        window.alert("The code is wrong");
      });
  }

  return (
    <div id="login_page">
      <img alt="err404" id="mainLogoLogin" src={logoDipndip} />
      <div className="secondHalp">
        <p>
          Security code to reset <br /> password
        </p>
        <h6>
          Do not be angry, my friend, we will help you to recover the password
        </h6>
        <br></br>
        <br></br>
        <form id="form_for_OTP_code">
          <OtpInput
            id="boxeseditein34"
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
          />

          <br />
          <br />
          <button
            onClick={(e) => submit(e)}
            // to="/NewPassword"
            id="fet4356546"
          >
            Next
          </button>
        </form>
      </div>
    </div>
  );
};

export default SecurityCode;
