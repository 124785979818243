import Cookies from "js-cookie";
import React, { createContext, useContext, ReactNode } from "react";

type UserRole = "Admin" | "SuperAdmin" | "Subuser";

interface UserContextType {
    userRole: UserRole;
    userId?: string;
    userToken?: string | null;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export function useUserContext() {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error("useUserContext must be used within a UserProvider");
    }
    return context;
}

interface UserProviderProps {
    children: ReactNode;
}

export function UserProvider({ children }: UserProviderProps) {
    const userRole = !Cookies.get("_auth_state") ? null : JSON.parse(Cookies.get()?._auth_state)?.route;
    const userId = !Cookies.get("_auth_state") ? null : JSON.parse(Cookies.get()._auth_state).id;
    const userToken = !Cookies.get("_auth_state") ? null : Cookies.get()._auth;

    return <UserContext.Provider value={{ userRole, userId, userToken }}>{children}</UserContext.Provider>;
}
