import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "leaflet/dist/leaflet.css";

import { useUserContext } from "../../../context/userContext.tsx";

import img1 from "../../../Assests/images/marker.png";
import flag from "../../../Assests/images/Flag_of_Palestine.svg";
import { worldMap } from "../../Map/map-data/worldMap.ts";
import { MapContainer, TileLayer, Marker, Popup, GeoJSON } from "react-leaflet";
import { Icon, LatLngExpression } from "leaflet";

export const Countries = () => {
    const [mapCountries, setMapCountries] = useState([]);
    const [branches, setBranches] = useState<any>([]);
    const { userId, userToken } = useUserContext();
    const fetchHomePageData = async () => {
        const res = await axios.get(
            `https://dipndipapi.mass-fluence.com/api/homepage?token=${userToken}&user_id=${userId}`
        );
        setMapCountries(res.data.data.map);
    };

    const fetchBranches = async () => {
        const res = await axios.get(
            `https://dipndipapi.mass-fluence.com/api/branches?token=${userToken}&user_id=${userId}&country_id=${id}`
        );
        setBranches(res.data.data.branches);
    };

    const { id } = useParams();
    useEffect(() => {
        fetchHomePageData();
        fetchBranches();
    }, [id]);
    const cityName = mapCountries.filter((city: any) => city.id == id).map((c: any) => c.city)[0];
    const filteredMap = useMemo(() => {
        return worldMap.features.filter((map) => map.properties.name.includes(cityName));
    }, [cityName]);
    const individualCountry = useMemo(() => {
        return {
            type: "FeatureCollection",
            name: "USLabels",

            crs: {
                type: "name",
                properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
            },
            features: filteredMap,
        };
    }, [filteredMap]);

    const position = [51.505, -0.09] as LatLngExpression | undefined;

    const dipndipIcon = new Icon({
        iconUrl: img1,
        iconSize: [18, 21], // size of the icon
    });

    const palstineFlag = new Icon({
        iconUrl: flag,
        iconSize: [60, 40], // size of the icon
    });
    return (
        <div className="p-4">
            <div className="flex flex-wrap w-full h-full items-start gap-4 container px-20 my-16">
                <div className="w-1/3">
                    <h3 className="text-5xl font-bold ">{cityName}</h3>
                    <div className="my-8 flex flex-col  items-start gap-4 font-bold">
                        {branches.map((branch) => {
                            return (
                                <div className="flex flex-col gap-2">
                                    <p key={branch.id} className="text-xl text-redish">
                                        {branch.city}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </div>
                {branches.length ? (
                    <MapContainer
                        key={cityName}
                        zoomControl={false}
                        attributionControl={false}
                        minZoom={3}
                        maxZoom={14}
                        maxBounds={[
                            [-90, -180],
                            [90, 180],
                        ]}
                        className="h-[590px]  w-[650px] !rounded-xl "
                        center={[branches[0]?.latitude, branches[0]?.longitude]}
                        zoom={7}
                        scrollWheelZoom
                    >
                        <TileLayer noWrap={true} url="https://www.google.us/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}" />
                        <Marker icon={palstineFlag} position={[31.0103021, 34.8601736]}>
                            <Popup maxHeight={120} maxWidth={290} >
                                <div className="flex flex-col gap-2 items-center justify-center w-full cursor-pointer m-0">
                                    <p className="text-ءم m-0">فـــلــســطــين#</p>
                                </div>
                            </Popup>
                        </Marker>
                        {branches?.map((country) => (
                            <Marker icon={dipndipIcon} position={[country.latitude, country.longitude]}>
                                <Popup maxHeight={120} maxWidth={290}>
                                    <div className="flex flex-col gap-2 items-center justify-center w-full cursor-pointer m-0">
                                        <p className="text-xs m-0">{country.city}</p>
                                    </div>
                                </Popup>
                            </Marker>
                        ))}
                    </MapContainer>
                ) : (
                    <p className="text-redish w-full h-full flex items-center justify-center">No branches available</p>
                )}
            </div>
        </div>
    );
};
