import { useParams } from "react-router-dom";
import React, { useMemo, useState } from "react";
import { GetData } from "../../../../../api/index.tsx";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useUserContext } from "../../../../../context/userContext.tsx";
import { LoadingIndicator } from "../../../../shared/LoadingIndicator/index.tsx";
import AddTrainingModal from "../../../trianing/addFilemodel.js";
import { FoldersCard } from "../FoldersCard/index.tsx";
import EditTit from "../../editTit.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumbs, Link, Typography } from "@mui/material";

export const FolderPage = () => {
    const { userId, userToken, userRole } = useUserContext();
    const { sectionId, name } = useParams();
    const [isAddTrainingOpen, setIsAddTrainingOpen] = useState(false);
    const [isEditTrainingTitleOpen, setIsEditTrainingTitleOpen] = useState(false);
    const { data: trainings, isLoading } = useQuery({
        queryKey: ["trainings", sectionId],
        queryFn: () => GetData("trainings", userToken as string, userId as string, `section=${sectionId}`),
    });

    return (
        <div className="my-8 mx-32">
            <AddTrainingModal show={isAddTrainingOpen} section={sectionId} onHide={() => setIsAddTrainingOpen(false)} />
            <EditTit
                show={isEditTrainingTitleOpen}
                section={name}
                id={sectionId}
                onHide={() => setIsEditTrainingTitleOpen(false)}
            />
            <div role="presentation" className="flex items-center justify-between">
                <Breadcrumbs maxItems={2} aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Home
                    </Link>
                    <Link underline="hover" color="inherit" href={`/${userRole}/Library/${userId}`}>
                        Library
                    </Link>
                    <div className="flex items-center">
                        <h1 className="initial_title_Sec" style={{ color: "var(--color-text)" }}>
                            {name}
                        </h1>
                        <FontAwesomeIcon
                            icon={faPenToSquare}
                            style={{
                                color: "var(--color-text)",
                                marginLeft: "15px",
                                fontSize: "27px",
                                cursor: "pointer",
                            }}
                            onClick={() => setIsEditTrainingTitleOpen(true)}
                        />
                    </div>
                </Breadcrumbs>
                {userRole === "SuperAdmin" ? (
                    <div className="d-flex gap-3">
                        <button id="add_file_initial" onClick={() => setIsAddTrainingOpen(true)}>
                            <FontAwesomeIcon icon={faAdd} className="w-4 h-4 font-bold" />
                        </button>
                    </div>
                ) : null}
            </div>
            <div className="initial_title_Sec my-8">
                <div className="d-flex align-items-center"></div>
            </div>
            <div className="flex  gap-14 flex-wrap ">
                {isLoading ? (
                    <div className="w-screen h-screen">
                        <LoadingIndicator />
                    </div>
                ) : trainings.data.length ? (
                    trainings?.data?.map((folder, i) => {
                        return (
                            <div key={folder.id} className=" sm:w-[100%] md:w-[490px] 2xl:w-[590px]">
                                <FoldersCard
                                    key={folder.id}
                                    sectionId={folder.id}
                                    title={folder.name}
                                    image={folder.image}
                                    isFolder
                                    folderId={folder.id}
                                />
                            </div>
                        );
                    })
                ) : (
                    <p className="text-redish text-4xl w-full h-full flex items-center justify-center">No files found</p>
                )}
            </div>
        </div>
    );
};
