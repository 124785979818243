import React from "react";

const MAinAround = () => {
  return (
    <div style={{ display: "flex" }} id="mainAnnounmentsContainer34">

      {/* <Around/> */}
    </div>
  );
};

export default MAinAround;
