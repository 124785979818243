import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import uploadIcon from "../../../Assests/images/setting-super-hfjadmin-icons.svg";
import Cookies from "js-cookie";
import axios from "axios";
import { ModalTitleWithClose } from "../../shared/ModalTitle.tsx";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";

const EditFile = (props) => {
  const { initialData } = props;
  const [image, setImage] = useState(null);
  const [fileCover, setFileCover] = useState("No selected file");
  const [fileValue, setFileValue] = useState();
  const [trainingInfo, setTrainingInfo] = useState({
    name: "",
    file: "",
    image: "",
    file_id: "",
  });

  let addtrainURL = "https://dipndipapi.mass-fluence.com/api/update-file";
  function handle(e) {
    const newData = { ...trainingInfo };
    newData[e.target.id] = e.target.value;
    setTrainingInfo(newData);
  }

  const [selectedfileValue, setSelectedFileValue] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [isUpdating, setIsUpdating] = useState(false);
  const queryClient = useQueryClient();

  function submit(e) {
    e.preventDefault();
    setIsUpdating(true);
    const formData = new FormData();

    formData.append("image", selectedfileValue ?? initialData.image);
    formData.append("file", selectedImage ?? initialData.file);
    formData.append("name", trainingInfo.name);
    formData.append("training_id", props.training_id);
    formData.append("file_id", props.idname);
    formData.append("user_id", `${JSON.parse(Cookies.get("_auth_state")).id}`);
    formData.append("token", Cookies.get()._auth);

    axios
      .post(addtrainURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 201) {
          enqueueSnackbar("Updated Successfully", { variant: "success" });
          queryClient.invalidateQueries({ queryKey: ["training-files"] });
        }
        props.onHide();
        setIsUpdating(false);
      })
      .catch(() => {
        enqueueSnackbar("Something went wrong please try again", { variant: "error" });
        setIsUpdating(false);
      });
  }

  useEffect(() => {
    setTrainingInfo({
      name: initialData?.name,
      file: initialData?.file,
      image: initialData?.image,
      file_id: initialData?.id,
    }),
      setImage(initialData.image);
  }, [initialData]);

  return (
    <div>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header variant="danger">
          <Modal.Title style={{ color: "var(--color-text)" }} id="contained-modal-title-vcenter">
            <ModalTitleWithClose onClose={props.onHide} titleText="Update file" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <br></br>
          <form id="" onSubmit={(e) => submit(e)}>
            <label style={{ color: "var(--color-text)" }}>Name</label>
            <br></br>
            <input
              style={{ color: "var(--color-text)" }}
              onChange={(e) => handle(e)}
              value={trainingInfo.name}
              type="text"
              id="name"
              placeholder="Name..."
            />

            <br></br>
            <br></br>

            <div onClick={() => document.querySelector(".ann-image-field").click()} id="image-ann-uploader">
              <input
                type="file"
                accept="image/*"
                className="ann-image-field"
                hidden
                onChange={({ target: { files, value } }) => {
                  files[0] && setFileCover(files[0].name);
                  if (files) {
                    setImage(URL.createObjectURL(files[0]));
                  }
                  setSelectedFileValue(files[0]);
                }}
                id="image"
              />

              {image ? (
                <div className="flex flex-col items-center gap-4">
                  <img src={image} alt={fileCover} width={160} height={160} />
                  <p className="text-redish text-xs">
                    *Please note that, the recommended image size is width: 390px, height: 230px
                  </p>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center gap-4">
                  <img src={uploadIcon} alt={fileCover} width={60} height={60} />
                  <p style={{ color: "var(--color-text)" }}>
                    Drag and drop photo or <strong>browse computer</strong>
                  </p>
                  <p className="text-redish text-xs">
                    *Please note that, the recommended image size is width: 390px, height: 230px
                  </p>
                </div>
              )}
            </div>

            <br></br>
            <div onClick={() => document.querySelector(".ann-field").click()} id="image-ann-uploader">
              <input
                type="file"
                className="ann-field"
                hidden
                onChange={({ target: { files } }) => {
                  files[0] && setFileCover(files[0].name);
                  setSelectedImage(files[0]);
                  setFileValue(files[0].name);
                }}
                id="file"
              />

              {fileValue ? (
                <p style={{ color: "var(--color-text)" }}>{fileValue}</p>
              ) : initialData?.file ? (
                <p> {`${initialData.name}.${initialData.file_type}`}</p>
              ) : (
                <div className="flex flex-col items-center justify-center gap-4">
                  <img src={uploadIcon} alt={fileCover} width={60} height={60} />
                  <p style={{ color: "var(--color-text)" }}>
                    Drag and drop photo or <strong>browse computer</strong>
                  </p>
                  <p className="text-redish text-xs">
                    *Please note that, the recommended image size is width: 390px, height: 230px
                  </p>
                </div>
              )}
            </div>

            <br></br>

            <LoadingButton
              loading={isUpdating}
              color="error"
              variant="contained"
              className="!rounded-xl !bg-redish !capitalize py-3 px-4 my-2"
              type="submit"
            >
              Save Changes
            </LoadingButton>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditFile;
