import React from "react";
import { Text, Image, View } from "@react-pdf/renderer";
import moment from "moment";
import { avatar } from "../../avatar.ts";

export const Activity = ({ activity, styles }) => {
    return (
        <View
            style={{
                backgroundColor: "white",
                borderRadius: "20px",
                width: "650px",
                padding: "20px",
                marginTop: "20px",
            }}
        >
            <Text style={styles.header}>Activity</Text>
            {activity?.map((user, i) => {
                const duration = moment.duration(user.spent, "minutes").humanize();
                return (
                    <View
                        key={i}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <View style={styles.row}>
                            <Image style={styles.image} src={avatar} />
                            <View style={styles.item}>
                                <Text style={styles.child}> {user.name}</Text>
                                <Text style={styles.subtitle}> {user.last_login}</Text>
                            </View>
                        </View>
                        <View style={styles.ActivityRowEnd}>
                            <View
                                style={{
                                    backgroundColor: "#F3F3F3F2",
                                    padding: "10px",
                                    borderRadius: "10px",
                                    width: "100%",
                                    textAlign: "center",
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                    }}
                                >
                                    All time spent {duration !== "a few seconds" ? duration : "0 minutes"}
                                </Text>
                            </View>
                        </View>
                    </View>
                );
            })}
        </View>
    );
};
