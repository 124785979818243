import React from "react";
import AdminAnnouncemnts from "./../../../components/AdminPages/Announcemnts/Announcemnts";

const MainAdminAnnouncemnts = () => {
  return (
    <div style={{ display: "flex" }} id="mainAnnounmentsContainer34">

      <AdminAnnouncemnts />
    </div>
  );
};

export default MainAdminAnnouncemnts;
