import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import uploadIcon from "../../../Assests/images/setting-super-hfjadmin-icons.svg";
import Cookies from "js-cookie";
import axios from "axios";
import { useSnackbar } from "notistack";
import { ModalTitleWithClose } from "../../shared/ModalTitle.tsx";
import { ImageSize } from "../../ImageSize/index.tsx";

const ChangeCover = (props) => {
  const [image, setimage] = useState(null);
  const [fileName, setfileName] = useState("No selected file");
  const { enqueueSnackbar } = useSnackbar();

  let addtrainURL = "https://dipndipapi.mass-fluence.com/api/change-cover-image";

  const [SelectedFile, setSelectedFile] = useState();

  function submit(e) {
    e.preventDefault();

    const formData = new FormData();

    formData.append("image", SelectedFile);
    formData.append("country_id", props.countryIDD);
    formData.append("user_id", `${JSON.parse(Cookies.get("_auth_state")).id}`);
    formData.append("token", Cookies.get()._auth);

    axios
      .post(addtrainURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        enqueueSnackbar("Updated Successfully", { variant: "success" });
        props.refetch();
        props.onHide();
      })
      .catch(() => {
        enqueueSnackbar("Something went wrong, please try again", { variant: "error" });
      });
  }

  return (
    <div>
      <Modal {...props} size="lg" centered>
        <Modal.Header variant="danger">
          <Modal.Title style={{ color: "var(--color-text)" }} id="contained-modal-title-vcenter">
            <ModalTitleWithClose onClose={props.onHide} titleText="Change cover image" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <br></br>
          <form id="" onSubmit={(e) => submit(e)}>
            <br></br>
            <br></br>

            <div onClick={() => document.querySelector(".ann-image-field").click()} id="image-ann-uploader">
              <input
                type="file"
                accept="image/*"
                className="ann-image-field"
                hidden
                onChange={({ target: { files, value } }) => {
                  files[0] && setfileName(files[0].name);
                  if (files) {
                    setimage(URL.createObjectURL(files[0]));
                  }
                  setSelectedFile(files[0]);
                }}
                id="image"
              />

              {image ? (
                <div className="flex flex-col items-center gap-4">
                  <img src={image} alt={fileName} width={160} height={160} />
                  <p className="text-redish text-xs">
                    *Please note that, the recommended image size is width: 157px, height: 250px
                  </p>
                </div>
              ) : (
                <ImageSize src={uploadIcon} alt={fileName} sizes="width: 157px, height: 250px" />
              )}
            </div>

            <br></br>
            <br></br>

            <br></br>
            <br></br>

            <br></br>

            <button id="saveBtn353" type="submit">
              Save Changes
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ChangeCover;
