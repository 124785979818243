import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import uploadIcon from "../../../Assests/images/setting-super-hfjadmin-icons.svg";
import axios from "axios";
import { ModalTitleWithClose } from "../../shared/ModalTitle.tsx";
import { ImageSize } from "../../ImageSize/index.tsx";
import { useSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import { useUserContext } from "../../../context/userContext.tsx";
import { LoadingButton } from "@mui/lab";

const AddFile = (props) => {
  const [image, setImage] = useState(null);
  const { userId } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);

  const [fileName, setFileName] = useState("No selected file");
  const [file, setFile] = useState();
  const [trainingInfo, setTrainingInfo] = useState({
    name: "",
    file: "",
    image: "",
    training_id: "",
  });
  let addtrainURL = "https://dipndipapi.mass-fluence.com/api/add-file";
  function handle(e) {
    const newData = { ...trainingInfo };
    newData[e.target.id] = e.target.value;
    setTrainingInfo(newData);
  }
  const [SelectedFile, setSelectedFile] = useState("");
  const [SelectedFile2, setSelectedFile2] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  function submit(e) {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("image", SelectedFile);
    formData.append("file", SelectedFile2);
    formData.append("name", trainingInfo["name"]);
    formData.append("training_id", props.training_id);
    formData.append("user_id", `${userId}`);
    axios
      .post(addtrainURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 201) {
          setIsLoading(false);
          setTrainingInfo("");
          setFile("");
          setImage("");
          setSelectedFile2("");
          enqueueSnackbar(`${trainingInfo["name"]} has been added successfully`, { variant: "success" });
          queryClient.invalidateQueries({ queryKey: ["training-files"] });
          props.onHide();
        }
      })
      .catch((ERR) => {
        setIsLoading(false);
        enqueueSnackbar(`something went wrong, Please try again later`, { variant: "error" });
        props.onHide();
      });
  }

  return (
    <div>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header variant="danger">
          <Modal.Title style={{ color: "var(--color-text)" }} id="contained-modal-title-vcenter">
            <ModalTitleWithClose onClose={props.onHide} titleText="Add file" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <br></br>
          <form
            id=""
            onSubmit={(e) => {
              submit(e);
            }}
          >
            <label style={{ color: "var(--color-text)" }}>
              Name <span className="text-redish">*</span>
            </label>
            <br></br>
            <input
              required
              id="nameinput434"
              style={{ color: "var(--color-text)" }}
              onChange={(e) => handle(e)}
              value={trainingInfo.name}
              type="text"
              placeholder="Name..."
            />

            <br></br>
            <br></br>

            <div
              onClick={() => document.querySelector(".ann-image-field").click()}
              id="image-ann-uploader"
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <input
                type="file"
                accept="image/*"
                style={{
                  color: "var(--color-text)",
                  position: "absolute",
                  bottom: "2px",
                  marginLeft: "170px",
                }}
                className="ann-image-field hidden"
                onChange={({ target: { files, value } }) => {
                  files[0] && setFileName(files[0].name);
                  if (files) {
                    setImage(URL.createObjectURL(files[0]));
                  }
                  setSelectedFile(files[0]);
                }}
                value={trainingInfo.image}
                id="image"
              />

              {image ? (
                <div className="flex flex-col items-center gap-4">
                  <img src={image} alt={fileName} width={160} height={160} />
                  <p className="text-redish text-xs">
                    *Please note that, the recommended image size is width: 340px, height: 200px
                  </p>
                </div>
              ) : (
                <ImageSize src={uploadIcon} alt={fileName} sizes="width: 340px, height: 200px" />
              )}
            </div>

            <br></br>
            <div onClick={() => document.querySelector(".ann-field").click()} id="image-ann-uploader">
              <input
                type="file"
                className="ann-field hidden"
                hidden
                onChange={({ target: { files } }) => {
                  files[0] && setFileName(files[0].name);
                  setSelectedFile2(files[0]);
                  setFile(files[0].name);
                }}
                value={trainingInfo.file}
                id="file"
              />

              {file ? (
                <p style={{ color: "var(--color-text)" }}> {file} </p>
              ) : (
                <div className="flex items-center justify-center flex-col">
                  <img src={uploadIcon} alt={fileName} width={60} height={60} />
                  <p style={{ color: "var(--color-text)" }}>
                    Drag and drop file or <strong>browse computer</strong>
                  </p>
                </div>
              )}
            </div>

            <br></br>
            <LoadingButton
              loading={isLoading}
              color="error"
              variant="contained"
              className={`  !rounded-xl ${isLoading ? "!bg-gray-400" : "!bg-redish"} !capitalize py-3 px-4 my-2 `}
              type="submit"
            >
              Save Changes
            </LoadingButton>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddFile;
