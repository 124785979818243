import React from "react";
import Message from "../../../components/superPages/Messege/messege";

const MainMessenger = () => {
  return (
    <div style={{ overflow: "hidden", height: "100vh" }}>
      <div id="messefewfew9845245">

        <Message />
      </div>
    </div>
  );
};

export default MainMessenger;
