import Login from "./pages/login/login";
import { Route, Routes } from "react-router-dom";
import LibraryPage from "./pages/super admin/Library/LibraryPage";
import MainAnnouncemnts from "./pages/super admin/Announcemnts/MainAnnouncemnts";
import MainMessenger from "./pages/super admin/messinger/MainMessenger";
import MainAnalytics from "./pages/super admin/analytics/MainAnalytics";
import MainProfile from "./pages/super admin/Profile/MainProfile";
import Reset from "./pages/login/Reset";
import SecurityCode from "./pages/login/SecCode";
import NewPassword from "./pages/login/newPass";
import MainSetting from "./pages/super admin/Setting/MainSetting";
import SubMainAnnouncemnts from "./pages/sub admin/Announcemnts/MainAnnouncemnts";
import SubMainMessanger from "./pages/sub admin/messinger/MainMessinger";
import MainAdminAnnouncemnts from "./pages/admin/Announcemnts/MainAnnouncemnts";
import AdminMainMessanger from "./pages/admin/messinger/MainMessinger";
import PrivateRoutes1 from "./protectetroute1";
import PrivateRoutes2 from "./protectroute2";
import PrivateRoutes3 from "./protectroute3";
import MainNotification from "./pages/super admin/notification/mainNotification";
import AdminMainNotification from "./pages/admin/notification/mainNotification";
import SubMainNotification from "./pages/sub admin/notification/mainNotification";
import ShareAnn from "./pages/ShareAnnouncement/share";
import MainFiles from "./pages/super admin/Training_Pages/mainFiles";
import MAinAround from "./pages/super admin/around/mainAround";
import Viewer from "./components/superPages/trianing/viewer";
import AdminMAinAround from "./pages/admin/around/mainAround";
import ShareAnn22 from "./pages/ShareAnnouncement/shareview";
import AdminMainFiles from "./pages/admin/Training_Pages/mainFiles";
import AdminViewer from "./components/AdminPages/trianing/viewer";
import SubMAinAround from "./pages/sub admin/around/mainAround";
import SubViewer from "./components/SubPages/trianing/viewer";
import SubMainFiles from "./pages/sub admin/Training_Pages/mainFiles";
import PrivateRoutes from "./protectroute";
import { Countries } from "./components/superPages/Countries/index.tsx";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import Home from "./components/superPages/HomePage/Home.tsx";
import { FolderPage } from "./components/superPages/Library/Sections/FolderPage/index.tsx";
import FilesPage from "./components/superPages/Library/Sections/FilesPage/index.tsx";

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: 0, refetchOnMount: "always" } },
});

const MainApp = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <div>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Login />} />
            <Route path="ResetPass" element={<Reset />} />
            <Route path="/SecurityCode/:email" element={<SecurityCode />} />
            <Route path="/NewPassword/:email/:code" element={<NewPassword />} />
          </Route>
          <Route element={<PrivateRoutes1 />}>
            <Route path="/SuperAdmin/:id" element={<Home />} />
            <Route path="/SuperAdmin/MainAnnouncemnts/:id" element={<MainAnnouncemnts />} />

            <Route path="/ShareAnn1/:IDD/:name" element={<ShareAnn />} />
            <Route path="/Viewer/:file/:type/:name/*" element={<Viewer />} />
            <Route path="/SuperAdmin/countries/:id" element={<Countries />} />
            <Route path="/SuperAdmin/Library/:id" element={<LibraryPage />} />
            <Route path="/SuperAdmin/Library/:name/folder/:sectionId" element={<FolderPage />} />
            <Route path="/SuperAdmin/Library/:name/folder/:sectionId/files/:trainingId" element={<FilesPage />} />
            <Route path="/SuperAdmin/Files/:name/:fileID/:id" element={<MainFiles />} />
            <Route path="/SuperAdmin/MainMessanger/:id" element={<MainMessenger />} />
            <Route path="/SuperAdmin/MainAnalytics/:id" element={<MainAnalytics />} />
            <Route path="/SuperAdmin/MainProfile/:id" element={<MainProfile />} />
            <Route path="/SuperAdmin/MainSetting/:id" element={<MainSetting />} />
            <Route path="/SuperAdmin/MainNotification/:id" element={<MainNotification />} />
            <Route path="/SuperAdmin/MAinAround/:id" element={<MAinAround />} />
          </Route>
          <Route element={<PrivateRoutes2 />}>
            <Route path="/Subuser/:id" element={<Home />} />
            <Route path="/ShareAnn3/:IDD/:name" element={<ShareAnn22 />} />
            <Route path="/Subuser/MainAnnouncemnts/:id" element={<SubMainAnnouncemnts />} />

            <Route path="/SubViewer/:file/:type/:name" element={<SubViewer />} />
            <Route path="/Subuser/countries/:id" element={<Countries />} />
            <Route path="/Subuser/Library/:id" element={<LibraryPage />} />
            <Route path="/Subuser/Library/:name/folder/:sectionId" element={<FolderPage />} />
            <Route path="/Subuser/Library/:name/folder/:sectionId/files/:trainingId" element={<FilesPage />} />
            <Route path="/Subuser/Files/:name/:fileID/:id" element={<SubMainFiles />} />
            <Route path="/Subuser/MainMessanger/:id" element={<SubMainMessanger />} />
            <Route path="/Subuser/MainNotification/:id" element={<SubMainNotification />} />
            <Route path="/Subuser/MAinAround/:id" element={<SubMAinAround />} />
          </Route>
          <Route element={<PrivateRoutes3 />}>
            <Route path="/Admin/:id" element={<Home />} />
            <Route path="/ShareAnn2/:IDD/:name" element={<ShareAnn22 />} />
            <Route path="/Admin/MainAnnouncemnts/:id" element={<MainAdminAnnouncemnts />} />
            <Route path="/AdminViewer/:file/:type/:name" element={<AdminViewer />} />
            <Route path="/Admin/countries/:id" element={<Countries />} />
            <Route path="/Admin/Library/:id" element={<LibraryPage />} />
            <Route path="/Admin/Library/:name/folder/:sectionId" element={<FolderPage />} />
            <Route path="/Admin/Library/:name/folder/:sectionId/files/:trainingId" element={<FilesPage />} />
            <Route path="/Admin/Files/:name/:fileID/:id" element={<AdminMainFiles />} />
            <Route path="/Admin/MainMessanger/:id" element={<AdminMainMessanger />} />
            <Route path="/Admin/MainNotification/:id" element={<AdminMainNotification />} />
            <Route path="/Admin/MAinAround/:id" element={<AdminMAinAround />} />
          </Route>
        </Routes>
      </div>
    </QueryClientProvider>
  );
};

export default MainApp;
